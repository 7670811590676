@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  height: rem(480);
  color: #fff;
  background-color: $blu-75;
  overflow: hidden;
  @include mq(sp) {
    height: sprem(200);
  }
  @include mq(med, min, ps) {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: rem(100);
      background: linear-gradient(to top, transparent, var(--clr-body) 100%);
      opacity: .4;
    }

  }
  & > .inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .breadcrumbs {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .breadcrumbs span span:not(:last-child)::after {
    background-color: var(--bg-wht);
  }
}
.page_ttl--bg {
  position: absolute;
  inset: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
.page_ttl-jp {
  font-size: rem(48);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: rem(16);
  @include mq(sp) {
    font-size: sprem(14);
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04 {
  position: relative;
  letter-spacing: .1em;
  &:not([class*="mgn-btm"]):not(:last-child) {
    margin-bottom: 1em;
  }
}
.ttl-01 {
  font-size: rem(48);
  @include mq(sp) {
    font-size: sprem(30);
  }
  &::after {
    content: '';
    display: block;
    width: rem(120);
    height: 2px;
    margin-top: rem(40);
    background: linear-gradient(90deg, var(--clr-main) 0%, var(--clr-main) 50%, $gry-c9 50%, $gry-c9 100%);
    @include mq(sp) {
      width: sprem(60);
      margin-top: sprem(20);
    }
  }
  &.txt-ctr::after {
    @include auto-margin;
  }
  &.txt-wh + .ttl-01-sub {
    color: var(--clr-wht);
    &::after {
      background-color: var(--bg-wht);
    }
  }
}
.ttl-01-sub {
  margin-bottom: rem(28);
  font-size: rem(14);
  font-family: var(--font-en);
  color: var(--clr-main);
}
.ttl-02 {
  font-size: rem(40);
  color: var(--clr-main);
  @include mq(sp) {
    font-size: sprem(28);
  }
}
.ttl-03 {
  font-size: rem(24);
  @include mq(sp) {
    font-size: sprem(24);
  }
}
.ttl-04 {
  font-size: rem(24);
}