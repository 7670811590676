@use '../abstracts' as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

/* main_column
**************************************** */
.main_column {
  width: calc(100% - (rem(264) + rem(60)));
  margin-right: rem(60);
}

/* --- post --- */
.post {
  position: relative;
  transition: var(--transit-default, all .4s ease-out);
}
.post--img .img-contain {
  max-width: 80%;
}
.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.post--date {
  font-size: rem(12);
  font-family: var(--font-en);
  transition: var(--transit-default);
}
.post--ttl {
  transition: var(--transit-default);
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
} 
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: rem(13);
  background-color: var(--clr-main);
  color: var(--clr-wht);
  padding: .2em 1em;
  float: left;
  white-space: nowrap;
  margin: rem(2);
  @include mq(sp) {
    padding: 0 1em;
  }
}
.cat_list a:hover {
  opacity: .7;
}

@include mq(1100) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: rem(40);
    margin-right: 0;
  }
}

/* side_column
**************************************** */
.side_column {
  width: rem(264);
  @include mq(1100) {
    width: 100%;
  }
}
.side_section:not(:last-child) {
  margin-bottom: rem(64);
}
.side--ttl {
  letter-spacing: .2em;
  line-height: 1;
  margin-bottom: rem(16);
}
.side--ttl small {
  opacity: .5;
  letter-spacing: .15em;
  display: block;
  line-height: 1;
  margin-top: rem(8);
}

/* --- list --- */
.side--list a {
  display: block;
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: rem(16);
}
.posts-side .post--img {
  margin-right: rem(16);
  width: rem(64);
}
.posts-side .txtarea {
  flex: 1;
}
.posts-side .post--date {
  margin-bottom: 0;
}
.posts-side .post--ttl {
  line-height: 1.4;
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  &::after {
    content: "";
    display: block;
    @include rect(8);
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(135deg);
    transition: var(--transit-default);
  }
  &.active::after {
    transform: rotate(315deg);
  }
}
.archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}
.archive-pulldown .archive_list {
  position: relative;
  &:not(:last-child) {
    margin-right: rem(24);
  }
}
.archive-pulldown .archive_list a {
  display: block;
  padding: .25em 1em;
  text-align: left;
  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }
  &:hover {
    background-color: #eee;
  }
}
.archive-pulldown .archive_list--label {
  margin-right: rem(8);
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: .25em 4em .25em 2em;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  color: inherit;
  font-family: var(--font-primary);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: .08em;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0.4em;
    right: 0.8em;
    margin: auto;
    width: 0.6em;
    height: 0.6em;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
    transition: all 0.2s ease-out;
  }
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}


/* blog
**************************************** */
.posts-blog .post {
  padding-bottom: rem(48);
  padding-top: rem(48);
  border-bottom: 1px solid #e5e5e5;
  &:first-child {
    border-top: 1px solid #e5e5e5;
  }
}
.posts-blog .post--txtarea {
  flex: 1;
  word-break: break-word;
}
.posts-blog .post--img {
  width: rem(280);
  margin-right: rem(40);
}

@include mq(sp) {
  .posts-blog .post {
    display: block;
    padding-top: sprem(24);
    padding-bottom: sprem(24);
  }
  .posts-blog .post--img {
    padding-left: 0;
    @include auto-margin;
    margin-bottom: sprem(16);
    text-align: center;
  }
  .posts-blog .post--img img {
    width: auto;
    max-width: 100%;
  }
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: rem(20);
  padding-bottom: rem(20);
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: var(--clr-wht);
}

/* --- news_list --- */
.news_list .post {
  padding: rem(16);
  margin-bottom: 0;
  border-radius: rem(8);
  box-shadow: 0 0 20px rgba(41, 52, 64, .1);
  &:not(:last-child) {
    margin-bottom: rem(8);
  }
  &:hover {
    box-shadow: inherit;
  }
  @include mq(sp) {
    display: block;
  }
}
.news_list .post--info {
  @include mq(sp, min, ps) {
    margin-right: rem(24);
  }
  @include mq(sp) {
    margin-bottom: sprem(8);
  }
}
.news_box .post--info {
  @include mq(sp, min, ps) {
    margin-right: rem(24);
  }
  @include mq(sp) {
    margin-bottom: sprem(2);
  }
}
.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main);
}
.news_list .post--link:hover svg {
  fill: var(--clr-main);
}
.news_list .post--date {
  min-width: rem(120);
}
.news_list .post--info.flex .post--date + .cat_list {
  margin-left: rem(16);
}

/* works
**************************************** */
.map-ttl {
  position: relative;
  padding-left: rem(24);
  font-size: rem(32);
  line-height: 1.6;
  &::before {
    content: "";
    position: absolute;
    top: .1em;
    bottom: .1em;
    left: 0;
    display: block;
    width: rem(6);
    background-color: var(--clr-main);
  }
}
#map {
  position: relative;
  width: 100%;
  aspect-ratio: 3/2;
  background-color: #eee;
  @include mq(sp) {
    aspect-ratio: 1/1;
  }
}
#map iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.works_list {
  width: 100%;
}
.works_list table {
  width: 100%;
}
.works_list table th {
  text-align: left;
}
.works_list thead th {
  padding: .3em .7em;
  font-family: var(--font-jp);
  color: #fff;
  background-color: var(--bg-main);
}
.works_list tbody tr {
  border-bottom:1px solid #d3d3d3;
}
.works_list tbody th,
.works_list tbody td {
  padding: .7em;
  line-height: 1.5;
}
.works_list--btn {
  width: rem(100);
}
.works_list--btn a {
  display: block;
  padding: .5em;
  color: var(--clr-body);
  text-align: center;
  font-weight: 500;
  line-height: 1;
  border: 1px solid;
}
.works_list--name a:hover {
  opacity: .5;
}
.works_list--btn a:hover {
  color: #fff;
  background-color: var(--clr-main);
  border-color: var(--clr-main);
}

/* sidebar */
.search_item {
  background-color: #eee;
  padding: .7em 1em;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
}
.search_item--ttl {
  padding-bottom: .5em;
  border-bottom: 1px solid;
}
.search_item:not(.search_item--category) .check_list {
  display: none;
}
.check_list li input[type="checkbox"] {
  display: none !important;
}
.search_item .check_list li label {
  position: relative;
  display: block;
  padding: .5em rem(8) .5em rem(22);
  font-size: rem(14);
  line-height: 1.4;
  &::before {
    content: '';
    position: absolute;
    width: rem(12);
    height: rem(12);
    left: 0;
    top: rem(10);
    border: 2px solid #788b93;
    @include mq(sp) {
      top: sprem(10);
    }
  }
}
.search_item .check_list li input[type="checkbox"]:checked + label {
  color: #e75f5f;
  font-weight: bold;
  &::before {
    border: 2px solid #e75f5f;
  }
  &::after {
    content: "";
    position: absolute;
    top: rem(7);
    left: rem(4);
    display: block;
    width: rem(6);
    height: rem(12);
    border-right: 2px solid #e75f5f;
    border-bottom: 2px solid #e75f5f;
    z-index: 10;
    transform: rotate(45deg);
    @include mq(sp) {
      top: sprem(7);
    }
  }
}
.side_section button {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: rem(16) rem(48);
  min-width: rem(250);
  font-size: rem(14);
  text-align: center;
  color: var(--clr-body);
  background: none;
  border: 1px solid;
  border-radius: rem(999);
  outline: none;
  cursor: pointer;
  transition: .4s ease-out;
  text-decoration: none;
  &:hover {
    color: var(--clr-main);
    border-color: var(--clr-main);
  }
}
.side_section .txt-link {
  display: inline-block;
  padding-bottom: .5em;
  border-bottom: 1px solid var(--clr-body);
  line-height: 1.4;
  &:hover {
    color: var(--clr-main);
    border-bottom: 1px solid var(--clr-main);
  }
}
.search_item .child {
  padding-left: sprem(16);
}

/* toggle
**************************************** */
.toggle {
  position: relative;
  cursor: pointer;
  transition: .4s ease-out;
  &:hover {
    opacity: .5;
  }
  &::after {
    content: "";
    position: absolute;
    top: 25%;
    right: rem(30);
    display: block;
    width: rem(14);
    height: rem(14);
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg) translateY(-50%);
  }
}
.toggle.open + .mce-content-body {
  display: block;
}
.toggle-sm::after {
  right: rem(15);
  width: rem(8);
  height: rem(8);
}