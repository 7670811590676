@use '../abstracts' as *;

/* lps_parts--column
********************************************** */
.lps_parts--column:not(.column-1) + .lps_parts--column {
  margin-top: 0;
  @include mq(sp) {
    margin-top: sprem(24);
  }
}
.lps_parts--column .column_item,
.lps_parts--column .text {
  display: flex;
  flex-direction: column;
}
.lps_parts--column:not(.column-1) .text {
  height: 100%;
}
.lps_parts--column .img {
  flex-shrink: 0;
}
.lps_parts--column .img + .text {
  padding-top: rem(24);
}
.lps_parts--column .bg-wh .text {
  padding: 6%;
}
.lps_parts--column:not(.column-1) table {
  width: 100%;
}
.lps_parts--column.column-1 .box {
  padding: rem(40);
}

// 角丸設定 ==================================

// /* 白背景 */
// .lps_parts--column {
//   .column_item.bg-wh {
//     border-radius: rem(20);
//     .img img {
//       border-radius: rem(20) rem(20) 0 0;
//     }
//   }
// }

// /* 白背景なし */
// .lps_parts--column {
//   .column_item:not(.bg-wh) .img img {
//     border-radius: rem(20);
//   }
// }

// 角丸設定 ==================================

@include mq(sp){
  .lps_parts--column + .lps_parts--column,
  .lps_parts--column:not(.column-1) + .lps_parts--column {
    margin-top: sprem(24);
  }
  .lps_parts--column {
    width: 100%;
    max-width: 100%;
  }
  .lps_parts--column .flex-sp-block .column_item:not(:last-child) {
    margin-bottom: rem(40);
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
  .lps_parts--column .text.bg-wh,
  .lps_parts--column.column-1 .box {
    padding: sprem(24);
  }
}