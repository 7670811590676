@use './variables' as *;
/* ##############################################################################

    KEYFRAMES

############################################################################## */
@keyframes brush {
    0% {
        clip-path: inset(0 100% 0 0);
    }
    100% {
        clip-path: inset(0);
    }
}
@keyframes btn-line {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform-origin: right top;
        transform: scale(0, 1);
    }
    51% {
        transform-origin: left top;
    }
    100% {
        transform: scale(1, 1);
        transform-origin: left top;
    }
}
@keyframes slider {
    0% {
        transform: translate( -25% ,0);
    }
    100% {
        transform: translate( -75%, 0);
    }
}
@keyframes dot_ani {
    0% {
        height: 0;
    }
    100% {
        height: 100%;
    }
}
@keyframes block_appear {
    0% {
        width: 0;
    }
    35% {
        width: 100%;
        left: 0;
    }
    80% {
        width: 100%;
    }
    100% {
        width: 0;
        right: 0;
        left: auto;
    }
}