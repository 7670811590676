@use '../abstracts' as *;
@use 'sass:math';

/* txt
**************************************** */
.txt-lg {
  font-size: rem(18);
  @include mq(sp) {
    font-size: sprem(15);
  }
}
.txt-sm {
  font-size: rem(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
.txt-wh,
.txt-wh * { color: var(--clr-wht); }
.txt-main,
.txt-main * { color: var(--clr-main); }
.txt-sub,
.txt-sub * { color: var(--clr-sub); }

/* --- font-size --- */
// フォントサイズを8pxから140pxまでREMで生成
@for $i from 8 to 140 {
  .fz-#{$i} { font-size: math.div($i, $fz-pc) + rem}
}

/* --- font-family --- */
.font-primary {
  font-family: var(--font-primary);
}
.font-jp {
  font-family: var(--font-jp);
}
h2 .font-en,
h3 .font-en,
h4 .font-en,
.font-en,
.font-en-400 {
  font-family: var(--font-en);
  font-weight: 400;
}
.font-en-700 {
  font-family: var(--font-en-700);
}
// .font-co {
//   font-family: var(--font-co);
// }

/* --- clip-txt --- */
// 省略行を1から4まで生成
@for $i from 1 through 4 {
  .clip-txt#{$i} {
    @include clip-txt($i);
  }
}

/* txt-ctr
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}
.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}
.txt-lft,
.txt-lft-pc {
  text-align: left;
}

.txt-blu {
  color: var(--clr-main);
}

.block {
  display: block;
}

.lh1 {
  line-height: 1;
}
.lh14 {
  line-height: 1.4;
}

.zindex-minus {
  z-index: -1;
}


@include mq(sp) {
  .txt-ctr-pc,
  .txt-rgt-pc { text-align: left; }
}