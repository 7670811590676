@use '../abstracts' as *;

/* ##############################################################################

    COMMON

############################################################################## */
* {
  letter-spacing: var(--ltr-space-default);
}
html {
  font-size: clamp(calc($fz-pc / $wid-pc * 1600px), vw($fz-pc), rem($fz-pc));
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  @include mq(sp, min, ps) {
    @include mq(med) {
      overflow-y: auto;
      overflow-x: auto;
    }
  }
  @include mq(sp) {
    font-size: min(spvw($fz-sp), rem($fz-sp));
  }
}
body {
  font-family: var(--font-primary);
  line-height: var(--line-height-default);
  font-weight: 500;
  font-feature-settings: "palt";
  font-size: 16px;
  color: var(--clr-body);
  -webkit-font-smoothing: antialiased;
  height: 100%;
  @include mq(sp, min, ps) {
    @include mq(med) {
      &:not(.mce-content-body) {
        min-width: 1080px;
      }
    }
  }
  @include mq(med) {
    padding-top: sprem($header-hgt-sp);
  }
  @include mq(sp) {
    font-size: 14px;
    -webkit-text-size-adjust: none;
  }
}
ul,
ol { list-style: none; }
small { 
  font-size: rem(14);
  @include mq(sp) {
    font-size: sprem(13);
  }
}
a,
a:where([href="javascript:void(0);"]):hover {
  color: var(--clr-link);
  text-decoration: none;
  transition: var(--transit-default);
  outline : none;
}
a:where([href="javascript:void(0);"]),
a:where([href="javascript:void(0);"])::before {
  color: inherit;
  cursor: default;
  text-decoration: none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
a:focus {
  text-decoration: none !important;
}
a[href^="tel:"] {
  @include mq(sp, min, ps) {
    pointer-events: none;
  }
}
img {
  vertical-align: middle;
  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}
address,
em,
i {
  font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr);
}
svg {
  transition: var(--transit-default);
  &[preserveAspectRatio="none"] {
    height: auto;
  }
}
