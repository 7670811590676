@use '../abstracts' as *;

/* --- section_pdg --- */
.section_pdg {
  padding-top: rem(80);
  padding-bottom: rem(80);
  @include mq(sp) {
    padding-top: sprem(40);
    padding-bottom: sprem(40);
  }
}
.section_pdg-sm {
  padding-top: rem(40);
  padding-bottom: rem(40);
  @include mq(sp) {
    padding-top: sprem(24);
    padding-bottom: sprem(24);
  }
}
