@use './variables' as *;
@use './functions' as *;
@use './mixins' as *;
@use "sass:map";

@mixin stylish_base01 {
  transform: translateY(rem(20));
  opacity: 0;
  transition: all .4s cubic-bezier(.215,.61,.355,1);

  &.active {
		transform: translateY(0);
    opacity: 1;
	}
}
// 青いボックスが現れてから、表示
@mixin stylish_img01 {
  .img {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 0;
      height: 100%;
      background-color: var(--clr-main);
    }
    img {
      opacity: 0;
      transition-property: opacity;
      transition-delay: .35s;
    }
  }

  &.active {
    .img {
      &::before {
        animation-name: block_appear;
        animation-duration: 1s;
        animation-timing-function: cubic-bezier(.77, 0, .6, 1);
        animation-direction: normal;
        animation-fill-mode: forwards;
      }
      img {
        opacity: 1;
      }
    }
	}
}
@mixin stylish_img02 {
  .service_top--imgarea {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 0;
      height: 100%;
      background-color: var(--clr-main);
    }
    .img_slider {
      opacity: 0;
      transition-delay: .8s;
    }
  }

  &.active {
    .service_top--imgarea {
      &::before {
        animation-name: block_appear;
        animation-duration: 1s;
        animation-timing-function: cubic-bezier(.77, 0, .6, 1);
        animation-direction: normal;
        animation-fill-mode: forwards;
      }
      .img_slider {
        opacity: 1;
      }
    }
	}
}