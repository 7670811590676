@use '../abstracts' as * ;

/* ##############################################################################

    FOOTER

############################################################################## */

.footer {
  background-color: var(--bg-main);
  & > .inner {
    max-width: 1424px;
    padding-top: rem(120);
    padding-bottom: rem(130);
    @include mq(sp) {
      align-items: inherit;
      padding-top: rem(60);
      padding-bottom: rem(30);
    }
  }
}
.works_fixed {
  position: fixed;
  bottom: rem(56);
  right: rem(40);
  z-index: 99;
  @include center-flex;
  width: rem(168);
  height: rem(168);
  transition: var(--transit-default);
  opacity: 0;
  pointer-events: none;
  @include mq(sp) {
    bottom: sprem(24);
    right: sprem(20);
    width: sprem(100);
    height: sprem(100);
  }
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
  a {
    width: 100%;
    height: 100%;
    background-color: #293440;
    border-radius: 50%;
    transition: var(--transit-default);
    &:hover {
      opacity: .7;
    }
  }
}
.works_fixed .ttl {
  .en {
    margin-bottom: rem(16);
    font-size: rem(14);
    letter-spacing: .14em;
    line-height: 1;
    opacity: .2;
    @include mq(sp) {
      margin-bottom: sprem(12);
      font-size: sprem(10);
    }
  }
  .jp {
    font-size: rem(20);
    line-height: 1;
    letter-spacing: .18em;
    @include mq(sp) {
      font-size: sprem(14);
    }
  }
}
.footer--lft {
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
}
.footer--logo {
  margin-bottom: rem(38);
  @include mq(sp) {
    text-align: center;
  }
  img {
    width: rem(248);
  }
}
.footer--info {
  margin-bottom: rem(32);
  @include mq(sp) {
    text-align: center;
  }
}
.footer--info p {
  font-size: 14px;
  @include lh(14,28);
  letter-spacing: .05em;
  .postcord {
    margin-right: rem(16);
    @include mq(sp) {
      display: block;
    }
  }
  .tel {
    display: inline-block;
    margin-right: rem(16);
  }
}

/* ---fnav --- */
.fnav {
  column-gap: rem(24);
}
.fnav--menu .menu-item:not(:last-child) {
  margin-right: rem(28);
  @include mq(sp) {
    margin-right: 0;
  }
}
.fnav--menu a {
  display: block;
  font-size: 14px;
  @include lh(14,35);
  @include mq(sp) {
    font-size: sprem(14);
  }
  &[href="javascript:void(0);"] {
    pointer-events: none;
  }
  &:hover {
    opacity: .7;
  }
}
.fnav--menu .sub-menu a {
  position: relative;
  padding-left: rem(24);
  color: #ffffff80;
  @include lh(14,28);
  &::before {
    content: '';
    position: absolute;
    top: rem(14);
    left: 0;
    width: rem(16);
    height: 1px;
    border: 1px solid var(--clr-main);
    @include mq(sp) {
      top: sprem(12);
      width: sprem(12);
    }
  }
}

.footer--rgt {
  padding-bottom: rem(6);
}

/* ---sns_area --- */
.sns_area {
  margin-bottom: rem(24);
  @include mq(sp) {
    margin-top: rem(24);
    margin-bottom: rem(24);
  }
}
.sns--list {
  height: 32px;
  @include mq(sp) {
    height: 24px;
  }
  @include mq(sp) {
    justify-content: center;
  }
}
.sns--list li:not(:first-child) {
  margin-left: rem(16);
}
.sns--list a {
  display: block;
  width: 32px;
  height: 32px;
  @include mq(sp) {
    width: 24px;
    height: 24px;
  }
  &:hover {
    opacity: .7;
  }
}
.sns--list svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}

.privacy {
  @include mq(sp) {
    text-align: center;
    margin-bottom: .5em;
  }
}
.privacy a:hover {
  opacity: .7;
}
.copyright p:not(:last-child) {
  font-size: rem(12);
  @include lh(12,22);
  color: rgba(190, 190, 190, .5);
  @include mq(sp, min, ps) {
    margin-right: rem(28);
  }
  @include mq(sp) {
    margin-bottom: .5em;
  }
}
.pbl a,
.pbl img {
  display: block;
}
.pbl a {
  opacity: .3;
  &:hover {
    opacity: 1;
  }
}

.footer_btm {
  padding-block: rem(40);
  @include mq(sp) {
    padding-block: sprem(20);
  }
  .copyright {
    justify-content: center;
  }
}
