@use '../abstracts' as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: rem($header-hgt);
  z-index: z(header);
  transition: var(--transit-default);
  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: vw(56);
  }
  @include mq(med) {
    height: sprem($header-hgt-sp);
    background-color: #fff;
    & > .inner {
      padding-left: rem(16);
    }
  }
}
.page-contact-simple .header {
  position: absolute;
}
.header-sm {
  background-color: #fff;
  border-bottom: inherit;
  box-shadow: 0px 0 rem(10) 0px rgba(39, 47, 61, .2);
  @include mq(med, min, ps) {
    height: rem($header-hgt-fx);
  }
}
.ipad .header > .inner {
  padding-left: rem(8);
}

/* --- logo -- */
.header--logo a,
.footer--logo a {
  display: block;
  &:hover {
    opacity: .7;
  }
}
.header--logo {
  position: relative;
  z-index: 999;
}
.header--logo img {
  width: rem(234);
  @include mq(med) {
    width: auto;
    max-height: rem(40);
  }
}
.ipad .header--logo img {
  width: rem(180);
}

/* gnav
********************************************** */
.gnav,
.gnav ul,
.gnav li,
.gnav a {
  @include mq(med, min, ps) {
    height: 100%;
  }
}
.gnav {
  @include mq(med, min, ps) {
    display: flex;
    color: #fff;
  }
  @include mq(med) {
    position: fixed;
    top: sprem(64);
    right: 0;
    z-index: 300;
    width: 100%;
    height: calc(100% - sprem(64));
    margin: 0;
    padding: rem(100) 7%;
    opacity: 0;
    pointer-events: none;
    overflow: scroll;
    background-color: var(--bg-main);
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);
    &.active {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(0);
    }
  }
}
.gnav a {
  @include center-flex;
}

/* --- menu --- */
.gnav--menu,
.gnav--cta {
  @include mq(med) {
    display: block;
  }
}
.gnav--menu {
  @include mq(med) {
    margin-bottom: rem(64);
  }
}
.gnav--menu > li {
  @include mq(med) {
    position: relative;
    display: block;
    width: 100%;
    border-bottom: 1px solid #fff;
  }
}
.gnav--link {
  position: relative;
  padding: 0 vw(24);
  font-size: rem(15);
  letter-spacing: .1em;
  color: #fff;
  &[href="javascript:void(0);"] {
    @include mq(med) {
      color: #fff;
    }
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: var(--clr-main);
    opacity: 0;
    transition: var(--transit-default);
  }
  @include mq(med) {
    display: block;
    justify-content: inherit !important;
    font-size: rem(16);
    padding: rem(18) rem(16);
    &::before {
      background-color: #fff;
    }
  }
  @include mq(med, min, ps) {
    text-align: center;
  }
}
.ipad .gnav--link {
  padding: rem(4) vw(16);
}
.header-sm .gnav--link {
  color: var(--clr-body);
  @include mq(1200) {
    padding: 0 vw(12);
  }
}
.gnav--menu li:not(.menu-item-has-children) a::before {
  @include mq(med) {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: auto;
    right: sprem(10);
    margin: auto;
    @include sprect(7);
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    background-color: inherit;
    opacity: 1;
    transform: rotate(45deg);
  }
}
.gnav--menu > li:hover .gnav--link[href="javascript:void(0);"],
.gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover,
.gnav--menu > li.current-menu-item > .gnav--link {
  @include mq(med, min, ps) {
    &::before {
      opacity: 1;
    }
  }
}
.gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover {
  @include mq(med, min, ps) {
    color: var(--clr-main);
  }
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
}
.head_sub_nav--wrap {
  position: absolute;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: rem(300);
  transition: var(--transit-default);
  opacity: 0;
  pointer-events: none;
  @include mq(med) {
    position: relative;
    width: 100%;
    border-top: 1px solid #fff;
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
}
.gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
  @include mq(med, min, ps) {
    opacity: 1;
    pointer-events: auto;
  }
}
.menu-item-has-children.active .head_sub_nav--wrap {
  @include mq(med) {
    opacity: 1;
    pointer-events: auto;
  }
}
.head_sub_nav {
  @include mq(med) {
    position: relative;
  }
}
.head_sub_nav li:not(:last-child) {
  border-bottom: 1px solid #fff;
}
.head_sub_nav a {
  display: block;
  padding: rem(12) rem(20);
  color: var(--clr-body);
  border-bottom: 2px solid transparent;
  @include mq(med, min, ps) {
    background-color: var(--bg-wht);
  }
  @include mq(med) {
    padding-left: rem(32) !important;
    color: #fff;
  }
  &:hover {
    @include mq(med, min, ps) {
      border-bottom: 2px solid var(--clr-main);
    } 
  }
}
.gnav_sub_toggle {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: absolute;
    top: 0;
    right: rem(-20);
    @include sprect(56);
    transition: var(--transit-default);
    color: #fff;
    cursor: pointer;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: rem(11);
      height: 1px;
      border-bottom: 1px solid;
    }
    &::after {
      transform: rotate(90deg);
    }
    &.active {
      transform: rotate(180deg);
    }
  }
}

/* ---btn --- */
.gnav_btn,
.tel_btn {
  @include mq(med, min, ps) {
    display: none;
  }
  @include mq(med) {
    position: fixed;
    top: 0;
    @include sprect(64);
    cursor: pointer;
    z-index: 9999;
  }
}
.gnav_btn {
  @include mq(med) {
    right: 0;
    transition: var(--transit-default);
  }
}
.gnav_btn--lines {
  @include mq(med) {
    position: relative;
    @include sprect(24,16);
  }
}
.gnav_btn--lines span {
  @include mq(med) {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--clr-main);
    transition: var(--transit-default);
    &:nth-of-type(1) {
      top: 0;
    }
    &:nth-of-type(2) {
      top: 50%;
      transform: translateY(-50%);
    }
    &:nth-of-type(3) {
      bottom: 0;
    }
  }
}
.active .gnav_btn--lines span {
  @include mq(med) {
    &:nth-of-type(1) {
      transform: translateY(sprem(7)) rotate(-45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(sprem(-7)) rotate(45deg);
    }
  }
}
.tel_btn {
  @include mq(med) {
    right: sprem(64);
  }
  a {
    @include mq(med) {
      display: flex !important;
      width: 100%;
      height: 100%;
    }
  }
  svg {
    @include mq(med) {
      @include sprect(24);
      fill: var(--clr-main);
    }
  }
}

/* ---CTA --- */
.cta_tel a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
}
.cta_tel a > span {
  display: block;
}
.cta_tel .tel {
  font-size: rem(24);
}
.cta_tel .num {
  font-size: rem(30);
}
.gnav--cta li:not(:last-child) {
  @include mq(med) {
    margin-bottom: sprem(16);
  }
}
.gnav--tel {
  @include mq(med, min, ps) {
    margin-left: rem(16);
  }
  @include mq(med) {
    margin-left: 0;
    text-align: center;
  }
}
.gnav--tel a {
  display: flex !important;
  flex-direction: column;
  padding-bottom: rem(10);
}
.header-sm .gnav--tel a {
  padding-bottom: rem(4);
}
.gnav--tel .tel {
  font-size: rem(14);
  line-height: 1;
  letter-spacing: -.02em;
  color: var(--clr-main);
  text-shadow: rem(2) rem(4) rem(30) rgba(217, 231, 255, .6);
  transition: var(--transit-default, all .4s ease-out);
  @include mq(med) {
    font-size: sprem(18);
    color: #fff;
    text-shadow: inherit;
  }
}
.header-sm .gnav--tel .tel {
  font-size: rem(14);
}
.gnav--tel .num {
  font-size: rem(24);
  letter-spacing: -.02em;
  @include mq(1440) {
    font-size: max(vw(24),rem(18));
  }
  @include mq(med) {
    font-size: sprem(28);
  }
}
.header-sm .gnav--tel .num {
  font-size: vw(22);
}
.ipad .gnav--tel {
  .tel {
    font-size: rem(12);
  }
  .num {
    font-size: rem(16);
  }
}
.gnav--contact {
  margin-left: vw(72);
  @include mq(1440) {
    margin-left: rem(24);
  }
  @include mq(med) {
    margin-left: 0;
  }
}
.header-sm .gnav--contact {
  @include mq(med, min, ps) {
    margin-left: rem(28);
  }
}
.gnav--contact a {
  font-size: 15px;
  line-height: 1;
  letter-spacing: .04em;
  color: #fff;
  background-color: var(--clr-main);
  @include mq(med, min, ps) {
    flex-direction: column;
    gap: rem(16);
    width: 120px;
    padding: rem(16) 0;
    border-radius: 0;
    &:hover {
      background-color: var(--bg-main);
    }
  }
  @include mq(med) {
    padding: sprem(20) !important;
    margin-left: auto;
    margin-right: auto;
    font-size: sprem(18);
  }
  svg {
    @include rect(22,16);
    fill: #fff;
    transition: var(--transit-default, all .4s ease-out);
    @include mq(med) {
      @include rect(24,20);
    }
  }
}
.header-sm .gnav--contact a {
  @include mq(med, min, ps) {
    flex-direction: inherit;
    width: rem(198);
  }
}