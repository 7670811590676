@charset "UTF-8";
@font-face {
  font-family: "presicav R";
  src: url(../fonts/presicav_regular.woff2) format("woff2");
}
@font-face {
  font-family: "presicav B";
  src: url(../fonts/presicav_bold.woff2) format("woff2");
}
:root {
  --ltr-space-default: .1em;
  --line-height-default: 2;
  --line-height-hdr: 1.4;
  --line-height-none: 1;
  --font-primary: "A1 Gothic M", "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
  --font-secondary: "Outfit", "ヒラギノ角ゴ オールド W6 JIS2004", "Hiragino Sans Old W6 JIS2004";
  --font-jp: "A1 Gothic M", "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
  --font-en: "presicav R", sans-serif;
  --font-en-700: "presicav B", sans-serif;
  --fz-primary: 1.125rem;
  --fz-half: .5rem;
  --clr-main: #0553dd;
  --clr-sub: #171C60;
  --clr-body: #141414;
  --clr-link: #000;
  --clr-wht: #fff;
  --clr-blk: #000;
  --clr-cta: #E17A40;
  --color-primary-default: #0553dd;
  --color-primary-dark: #0F6593;
  --color-primary-light: #2E9AD4;
  --bg-main: #272f3d;
  --bg-main-hvr: #293440;
  --bg-sub: #0553dd;
  --bg-wht: #fff;
  --bg-off_wht: #eff5f5;
  --bg-blk: #000;
  --bg-cta: #E17A40;
  --bg-cta-hvr: #DD6B2A;
  --transit-default: all .4s ease-out;
  --color-wht: #fff;
  --color-blk: #000;
}

/* ##############################################################################

    KEYFRAMES

############################################################################## */
@keyframes brush {
  0% {
    clip-path: inset(0 100% 0 0);
  }
  100% {
    clip-path: inset(0);
  }
}
@keyframes btn-line {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform-origin: right top;
    transform: scale(0, 1);
  }
  51% {
    transform-origin: left top;
  }
  100% {
    transform: scale(1, 1);
    transform-origin: left top;
  }
}
@keyframes slider {
  0% {
    transform: translate(-25%, 0);
  }
  100% {
    transform: translate(-75%, 0);
  }
}
@keyframes dot_ani {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}
@keyframes block_appear {
  0% {
    width: 0;
  }
  35% {
    width: 100%;
    left: 0;
  }
  80% {
    width: 100%;
  }
  100% {
    width: 0;
    right: 0;
    left: auto;
  }
}
/* ##############################################################################

    style info : リセット及びフォントサイズ

############################################################################## */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

*,
*:after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1;
  background: #fff;
  color: #333;
}

article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary, main {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ins {
  background-color: #ff9;
  color: #333;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #333;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #333;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/*-- ClearFix --*/
/* For modern browsers */
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* For IE 6/7 (trigger hasLayout) */
.clearfix {
  zoom: 1;
}

/*隙間消し*/
li img {
  vertical-align: bottom;
}

/* Reset input[type="search"] */
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

input[type=search]:focus {
  outline-offset: -2px;
}

input[type=search]::-webkit-search-decoration {
  display: none;
}

/* iOS reset */
input[type=submit] {
  -webkit-appearance: none;
}

@media print, screen and (min-width: 768px) {
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  @-moz-document url-prefix() {
    * {
      font-feature-settings: "palt";
    }
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 0) {
  * {
    font-feature-settings: "palt";
  }
}
/* ##############################################################################

    COMMON

############################################################################## */
* {
  letter-spacing: var(--ltr-space-default);
}

html {
  font-size: clamp(13.3333333333px, 0.8333333333vw, 1rem);
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  html {
    overflow-y: auto;
    overflow-x: auto;
  }
}
@media screen and (max-width: 47.9375em) {
  html {
    font-size: min(3.3816425121vw, 0.875rem);
  }
}

body {
  font-family: var(--font-primary);
  line-height: var(--line-height-default);
  font-weight: 500;
  font-feature-settings: "palt";
  font-size: 16px;
  color: var(--clr-body);
  -webkit-font-smoothing: antialiased;
  height: 100%;
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  body:not(.mce-content-body) {
    min-width: 1080px;
  }
}
@media screen and (max-width: 67.5em) {
  body {
    padding-top: 4.5714285714rem;
  }
}
@media screen and (max-width: 47.9375em) {
  body {
    font-size: 14px;
    -webkit-text-size-adjust: none;
  }
}

ul,
ol {
  list-style: none;
}

small {
  font-size: 0.875rem;
}
@media screen and (max-width: 47.9375em) {
  small {
    font-size: 0.9285714286rem;
  }
}

a,
a:where([href="javascript:void(0);"]):hover {
  color: var(--clr-link);
  text-decoration: none;
  transition: var(--transit-default);
  outline: none;
}

a:where([href="javascript:void(0);"]),
a:where([href="javascript:void(0);"])::before {
  color: inherit;
  cursor: default;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:focus {
  text-decoration: none !important;
}

@media print, screen and (min-width: 48em) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

img {
  vertical-align: middle;
}
@media screen and (max-width: 47.9375em) {
  img {
    max-width: 100%;
    height: auto;
  }
}

address,
em,
i {
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr);
}

svg {
  transition: var(--transit-default);
}
svg[preserveAspectRatio=none] {
  height: auto;
}

/* txt
**************************************** */
.txt-lg {
  font-size: 1.125rem;
}
@media screen and (max-width: 47.9375em) {
  .txt-lg {
    font-size: 1.0714285714rem;
  }
}

.txt-sm {
  font-size: 0.875rem;
}
@media screen and (max-width: 47.9375em) {
  .txt-sm {
    font-size: 0.9285714286rem;
  }
}

.txt-wh,
.txt-wh * {
  color: var(--clr-wht);
}

.txt-main,
.txt-main * {
  color: var(--clr-main);
}

.txt-sub,
.txt-sub * {
  color: var(--clr-sub);
}

/* --- font-size --- */
.fz-8 {
  font-size: 0.5rem;
}

.fz-9 {
  font-size: 0.5625rem;
}

.fz-10 {
  font-size: 0.625rem;
}

.fz-11 {
  font-size: 0.6875rem;
}

.fz-12 {
  font-size: 0.75rem;
}

.fz-13 {
  font-size: 0.8125rem;
}

.fz-14 {
  font-size: 0.875rem;
}

.fz-15 {
  font-size: 0.9375rem;
}

.fz-16 {
  font-size: 1rem;
}

.fz-17 {
  font-size: 1.0625rem;
}

.fz-18 {
  font-size: 1.125rem;
}

.fz-19 {
  font-size: 1.1875rem;
}

.fz-20 {
  font-size: 1.25rem;
}

.fz-21 {
  font-size: 1.3125rem;
}

.fz-22 {
  font-size: 1.375rem;
}

.fz-23 {
  font-size: 1.4375rem;
}

.fz-24 {
  font-size: 1.5rem;
}

.fz-25 {
  font-size: 1.5625rem;
}

.fz-26 {
  font-size: 1.625rem;
}

.fz-27 {
  font-size: 1.6875rem;
}

.fz-28 {
  font-size: 1.75rem;
}

.fz-29 {
  font-size: 1.8125rem;
}

.fz-30 {
  font-size: 1.875rem;
}

.fz-31 {
  font-size: 1.9375rem;
}

.fz-32 {
  font-size: 2rem;
}

.fz-33 {
  font-size: 2.0625rem;
}

.fz-34 {
  font-size: 2.125rem;
}

.fz-35 {
  font-size: 2.1875rem;
}

.fz-36 {
  font-size: 2.25rem;
}

.fz-37 {
  font-size: 2.3125rem;
}

.fz-38 {
  font-size: 2.375rem;
}

.fz-39 {
  font-size: 2.4375rem;
}

.fz-40 {
  font-size: 2.5rem;
}

.fz-41 {
  font-size: 2.5625rem;
}

.fz-42 {
  font-size: 2.625rem;
}

.fz-43 {
  font-size: 2.6875rem;
}

.fz-44 {
  font-size: 2.75rem;
}

.fz-45 {
  font-size: 2.8125rem;
}

.fz-46 {
  font-size: 2.875rem;
}

.fz-47 {
  font-size: 2.9375rem;
}

.fz-48 {
  font-size: 3rem;
}

.fz-49 {
  font-size: 3.0625rem;
}

.fz-50 {
  font-size: 3.125rem;
}

.fz-51 {
  font-size: 3.1875rem;
}

.fz-52 {
  font-size: 3.25rem;
}

.fz-53 {
  font-size: 3.3125rem;
}

.fz-54 {
  font-size: 3.375rem;
}

.fz-55 {
  font-size: 3.4375rem;
}

.fz-56 {
  font-size: 3.5rem;
}

.fz-57 {
  font-size: 3.5625rem;
}

.fz-58 {
  font-size: 3.625rem;
}

.fz-59 {
  font-size: 3.6875rem;
}

.fz-60 {
  font-size: 3.75rem;
}

.fz-61 {
  font-size: 3.8125rem;
}

.fz-62 {
  font-size: 3.875rem;
}

.fz-63 {
  font-size: 3.9375rem;
}

.fz-64 {
  font-size: 4rem;
}

.fz-65 {
  font-size: 4.0625rem;
}

.fz-66 {
  font-size: 4.125rem;
}

.fz-67 {
  font-size: 4.1875rem;
}

.fz-68 {
  font-size: 4.25rem;
}

.fz-69 {
  font-size: 4.3125rem;
}

.fz-70 {
  font-size: 4.375rem;
}

.fz-71 {
  font-size: 4.4375rem;
}

.fz-72 {
  font-size: 4.5rem;
}

.fz-73 {
  font-size: 4.5625rem;
}

.fz-74 {
  font-size: 4.625rem;
}

.fz-75 {
  font-size: 4.6875rem;
}

.fz-76 {
  font-size: 4.75rem;
}

.fz-77 {
  font-size: 4.8125rem;
}

.fz-78 {
  font-size: 4.875rem;
}

.fz-79 {
  font-size: 4.9375rem;
}

.fz-80 {
  font-size: 5rem;
}

.fz-81 {
  font-size: 5.0625rem;
}

.fz-82 {
  font-size: 5.125rem;
}

.fz-83 {
  font-size: 5.1875rem;
}

.fz-84 {
  font-size: 5.25rem;
}

.fz-85 {
  font-size: 5.3125rem;
}

.fz-86 {
  font-size: 5.375rem;
}

.fz-87 {
  font-size: 5.4375rem;
}

.fz-88 {
  font-size: 5.5rem;
}

.fz-89 {
  font-size: 5.5625rem;
}

.fz-90 {
  font-size: 5.625rem;
}

.fz-91 {
  font-size: 5.6875rem;
}

.fz-92 {
  font-size: 5.75rem;
}

.fz-93 {
  font-size: 5.8125rem;
}

.fz-94 {
  font-size: 5.875rem;
}

.fz-95 {
  font-size: 5.9375rem;
}

.fz-96 {
  font-size: 6rem;
}

.fz-97 {
  font-size: 6.0625rem;
}

.fz-98 {
  font-size: 6.125rem;
}

.fz-99 {
  font-size: 6.1875rem;
}

.fz-100 {
  font-size: 6.25rem;
}

.fz-101 {
  font-size: 6.3125rem;
}

.fz-102 {
  font-size: 6.375rem;
}

.fz-103 {
  font-size: 6.4375rem;
}

.fz-104 {
  font-size: 6.5rem;
}

.fz-105 {
  font-size: 6.5625rem;
}

.fz-106 {
  font-size: 6.625rem;
}

.fz-107 {
  font-size: 6.6875rem;
}

.fz-108 {
  font-size: 6.75rem;
}

.fz-109 {
  font-size: 6.8125rem;
}

.fz-110 {
  font-size: 6.875rem;
}

.fz-111 {
  font-size: 6.9375rem;
}

.fz-112 {
  font-size: 7rem;
}

.fz-113 {
  font-size: 7.0625rem;
}

.fz-114 {
  font-size: 7.125rem;
}

.fz-115 {
  font-size: 7.1875rem;
}

.fz-116 {
  font-size: 7.25rem;
}

.fz-117 {
  font-size: 7.3125rem;
}

.fz-118 {
  font-size: 7.375rem;
}

.fz-119 {
  font-size: 7.4375rem;
}

.fz-120 {
  font-size: 7.5rem;
}

.fz-121 {
  font-size: 7.5625rem;
}

.fz-122 {
  font-size: 7.625rem;
}

.fz-123 {
  font-size: 7.6875rem;
}

.fz-124 {
  font-size: 7.75rem;
}

.fz-125 {
  font-size: 7.8125rem;
}

.fz-126 {
  font-size: 7.875rem;
}

.fz-127 {
  font-size: 7.9375rem;
}

.fz-128 {
  font-size: 8rem;
}

.fz-129 {
  font-size: 8.0625rem;
}

.fz-130 {
  font-size: 8.125rem;
}

.fz-131 {
  font-size: 8.1875rem;
}

.fz-132 {
  font-size: 8.25rem;
}

.fz-133 {
  font-size: 8.3125rem;
}

.fz-134 {
  font-size: 8.375rem;
}

.fz-135 {
  font-size: 8.4375rem;
}

.fz-136 {
  font-size: 8.5rem;
}

.fz-137 {
  font-size: 8.5625rem;
}

.fz-138 {
  font-size: 8.625rem;
}

.fz-139 {
  font-size: 8.6875rem;
}

/* --- font-family --- */
.font-primary {
  font-family: var(--font-primary);
}

.font-jp {
  font-family: var(--font-jp);
}

h2 .font-en,
h3 .font-en,
h4 .font-en,
.font-en,
.font-en-400 {
  font-family: var(--font-en);
  font-weight: 400;
}

.font-en-700 {
  font-family: var(--font-en-700);
}

/* --- clip-txt --- */
.clip-txt1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clip-txt2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.clip-txt3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.clip-txt4 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

/* txt-ctr
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}

.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

.txt-lft,
.txt-lft-pc {
  text-align: left;
}

.txt-blu {
  color: var(--clr-main);
}

.block {
  display: block;
}

.lh1 {
  line-height: 1;
}

.lh14 {
  line-height: 1.4;
}

.zindex-minus {
  z-index: -1;
}

@media screen and (max-width: 47.9375em) {
  .txt-ctr-pc,
  .txt-rgt-pc {
    text-align: left;
  }
}
/* Wysiwyg editor
 **************************************** */
.mce-content-body ol {
  list-style: decimal;
}

.mce-content-body ul:not(:first-child),
.mce-content-body ol:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body ul:not(:last-child),
.mce-content-body ol:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  margin-left: 2.5em;
  padding-top: 0.5em;
}
@media screen and (max-width: 47.9375em) {
  .mce-content-body ul li,
  .mce-content-body ol li {
    margin-left: 2.1428571429em;
  }
}
.mce-content-body ul li::marker,
.mce-content-body ol li::marker {
  color: var(--clr-main);
  font-weight: 600;
  letter-spacing: 0.2em;
}

.mce-content-body ul li {
  list-style: none;
}
.mce-content-body ul li::before {
  content: "";
  position: absolute;
  top: 1.125em;
  left: -2.125em;
  width: 0.625em;
  aspect-ratio: 1/1;
  border: 1px solid var(--clr-main);
  background-color: var(--clr-main);
}
@media screen and (max-width: 47.9375em) {
  .mce-content-body ul li::before {
    left: -1.7142857143em;
  }
}

.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: var(--bg-wht);
}

.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}

.mce-content-body ol {
  list-style-type: decimal-leading-zero;
}

.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}

.mce-content-body hr {
  border-width: 2px;
  border-color: #c9c9c9;
}

.mce-content-body a img {
  transition: var(--transit-default);
}

.mce-content-body a:hover img {
  opacity: 0.8;
}

.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}

.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}

.mce-content-body .aligncenter {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}

.mce-content-body img.aligncenter {
  display: block;
}

.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}

.mce-content-body p:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body strong {
  font-weight: bold;
}

.mce-content-body blockquote {
  margin: 1em 0;
  padding: 1rem 2.5rem;
  border-radius: 0.1875rem;
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: #f8f8f8;
}
.mce-content-body blockquote::before, .mce-content-body blockquote::after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  opacity: 0.25;
}
.mce-content-body blockquote::before {
  background-image: url("../images/common/icon-quote_left.svg");
  top: 0.875rem;
  left: 0.75rem;
}
.mce-content-body blockquote::after {
  background-image: url("../images/common/icon-quote_right.svg");
  bottom: 0.875rem;
  right: 0.75rem;
}

.mce-content-body blockquote p {
  margin-bottom: 0;
}

.mce-content-body a {
  text-decoration: underline;
}
.mce-content-body a:hover {
  text-decoration: none;
}

.mce-content-body table {
  border: none;
  height: auto !important;
}
.mce-content-body table:not(:first-child) {
  margin-top: 1em;
}
.mce-content-body table:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}

.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 1px solid #c9c9c9;
  vertical-align: top;
}

.mce-content-body th {
  min-width: 7.5rem;
  text-align: left;
}

.mce-content-body sup {
  font-size: 0.75rem;
  color: #555;
}

.bold {
  font-family: var(--font-primary-600);
  color: var(--clr-main);
}

/* iframe */
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
}
.mce-content-body iframe:not(:first-child) {
  margin-top: 2em;
}
.mce-content-body iframe:not(:last-child) {
  margin-bottom: 2em;
}

/* table scroll */
.table-sp-scroll {
  white-space: nowrap;
}

.scroll {
  overflow-x: scroll;
  margin-bottom: 1em;
  -webkit-overflow-scrolling: touch;
}

.scroll table {
  margin: 0;
}

.scroll--cap {
  text-align: right;
  margin: 1em 0 0 !important;
  opacity: 0.25;
  font-size: 0.875rem;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body .alignright,
  .mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: 1rem;
  }
  .mce-content-body blockquote::before {
    top: 0;
    left: 0;
  }
  .mce-content-body blockquote::after {
    bottom: 0;
    right: 0;
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
  .mce-content-body td {
    padding: 0.5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
  .table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
  .table-sp-block th,
  .table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
  .table-sp-block td {
    display: block;
  }
  .mce-content-body iframe[src*="google.com/maps"] {
    aspect-ratio: 4/3;
  }
}
/* AddQuicktag
*************************************************** */
/* 見出し */
.editor-ttl,
.editor-ttl2,
.editor-ttl3 {
  font-family: var(--font-jp);
}
.editor-ttl:not(:first-child),
.editor-ttl2:not(:first-child),
.editor-ttl3:not(:first-child) {
  margin-top: 1em;
}
.editor-ttl:not(:last-child),
.editor-ttl2:not(:last-child),
.editor-ttl3:not(:last-child) {
  margin-bottom: 1em;
}

.editor-ttl {
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl {
    font-size: 1.7142857143rem;
  }
}

.editor-ttl2 {
  color: var(--clr-main);
  font-size: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl2 {
    font-size: 1.4285714286rem;
  }
}

.editor-ttl3 {
  font-size: max(1.125rem, 16px);
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl3 {
    font-size: 1.1428571429rem;
  }
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: 0.875rem;
  opacity: 0.6;
}
@media screen and (max-width: 47.9375em) {
  .txt-attention {
    font-size: 0.9285714286rem;
  }
}
.txt-attention::before {
  content: "※";
}

/* テーブル */
.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
}
.table1 th::before,
.table1 td::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: calc(100% - 0.1875rem);
}

.table1 th {
  color: var(--clr-main);
}
.table1 th::before {
  left: 0;
  height: 2px;
  background-color: var(--clr-main);
}

.table1 td::before {
  right: 0;
  height: 1px;
  background-color: #c9c9c9;
}

.table2 {
  background-color: var(--bg-wht);
}
@media print, screen and (min-width: 48em) {
  .table2 th {
    border-right: 0;
  }
  .table2 td {
    border-left: 0;
  }
}

.table2 th {
  background-color: rgba(168, 199, 223, 0.18);
}

/* ボックス */
.box {
  padding: 1.5rem 2rem;
  background-color: rgba(168, 199, 223, 0.18);
  border-radius: 0.5rem;
}
@media screen and (max-width: 47.9375em) {
  .box {
    padding: 1.5rem;
  }
}
.box:not(:first-child) {
  margin-top: 2em;
}
.box:not(:last-child) {
  margin-bottom: 2em;
}

.box * {
  color: var(--clr-body);
}

.lps_sec[style*=background-color] .box {
  background-color: var(--bg-wht);
}
.lps_sec[style*=background-color] .bg-wh .box {
  background-color: rgba(168, 199, 223, 0.18);
}

/* リスト */
ul.check--list li::before, ul.check--list li::after {
  content: "";
  position: absolute;
}
ul.check--list li::before {
  top: 0.6875em;
  left: -2.5em;
  width: 1.5em;
  aspect-ratio: 1/1;
  background-color: var(--clr-main);
  border-radius: 50%;
}
ul.check--list li::after {
  top: 1.125em;
  left: -2.0625em;
  width: 0.625em;
  height: 0.4375em;
  border-left: 2px solid var(--clr-wht);
  border-bottom: 2px solid var(--clr-wht);
  transform: rotate(-45deg);
}

@media screen and (max-width: 47.9375em) {
  /* テーブル */
  .table1.table-sp-block td::before {
    display: none;
  }
  .table2.table-sp-block th,
  .table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid #D3D3D3;
  }
}
/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor,
.mce-content-body.wp-editor * {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif;
}

.mce-content-body.wp-editor {
  font-size: 16px;
  padding: 1.5rem !important;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}
/* bg
********************************************** */
.bg-main {
  background-color: var(--bg-main);
}

.bg-sub {
  background-color: var(--bg-sub);
}

.bg-wh {
  background-color: var(--bg-off_wht);
}

.bg-off_wh {
  background-color: var(--bg-off_wht);
}

/* --- pc or sp --- */
.pc-none,
.pc-none-inline,
.pc-none-table,
.pc-none-flex {
  display: none;
}

.sp-none {
  display: block;
}

.sp-none-inline {
  display: inline;
}

.sp-none-table {
  display: table;
}

.sp-none-flex {
  display: flex;
}

@media screen and (max-width: 47.9375em) {
  /* --- pc or sp --- */
  .sp-none,
  .sp-none-inline,
  .sp-none-table,
  .sp-none-flex {
    display: none;
  }
  .pc-none {
    display: block;
  }
  .pc-none-inline {
    display: inline;
  }
  .pc-none-table {
    display: table;
  }
  .pc-none-flex {
    display: flex;
  }
}
/* img
********************************************** */
.img-ctr {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-trim {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trim-widescreen {
  aspect-ratio: 3/2;
}

.trim-square {
  aspect-ratio: 1/1;
}

.trim-portrait {
  aspect-ratio: 2/3;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.mgn-0 {
  margin: 0rem;
}

.mgn-top0 {
  margin-top: 0rem;
}

.mgn-rgt0 {
  margin-right: 0rem;
}

.mgn-btm0 {
  margin-bottom: 0rem;
}

.mgn-lft0 {
  margin-left: 0rem;
}

.mgn-1 {
  margin: 0.0625rem;
}

.mgn-top1 {
  margin-top: 0.0625rem;
}

.mgn-rgt1 {
  margin-right: 0.0625rem;
}

.mgn-btm1 {
  margin-bottom: 0.0625rem;
}

.mgn-lft1 {
  margin-left: 0.0625rem;
}

.mgn-2 {
  margin: 0.125rem;
}

.mgn-top2 {
  margin-top: 0.125rem;
}

.mgn-rgt2 {
  margin-right: 0.125rem;
}

.mgn-btm2 {
  margin-bottom: 0.125rem;
}

.mgn-lft2 {
  margin-left: 0.125rem;
}

.mgn-3 {
  margin: 0.1875rem;
}

.mgn-top3 {
  margin-top: 0.1875rem;
}

.mgn-rgt3 {
  margin-right: 0.1875rem;
}

.mgn-btm3 {
  margin-bottom: 0.1875rem;
}

.mgn-lft3 {
  margin-left: 0.1875rem;
}

.mgn-4 {
  margin: 0.25rem;
}

.mgn-top4 {
  margin-top: 0.25rem;
}

.mgn-rgt4 {
  margin-right: 0.25rem;
}

.mgn-btm4 {
  margin-bottom: 0.25rem;
}

.mgn-lft4 {
  margin-left: 0.25rem;
}

.mgn-5 {
  margin: 0.3125rem;
}

.mgn-top5 {
  margin-top: 0.3125rem;
}

.mgn-rgt5 {
  margin-right: 0.3125rem;
}

.mgn-btm5 {
  margin-bottom: 0.3125rem;
}

.mgn-lft5 {
  margin-left: 0.3125rem;
}

.mgn-6 {
  margin: 0.375rem;
}

.mgn-top6 {
  margin-top: 0.375rem;
}

.mgn-rgt6 {
  margin-right: 0.375rem;
}

.mgn-btm6 {
  margin-bottom: 0.375rem;
}

.mgn-lft6 {
  margin-left: 0.375rem;
}

.mgn-7 {
  margin: 0.4375rem;
}

.mgn-top7 {
  margin-top: 0.4375rem;
}

.mgn-rgt7 {
  margin-right: 0.4375rem;
}

.mgn-btm7 {
  margin-bottom: 0.4375rem;
}

.mgn-lft7 {
  margin-left: 0.4375rem;
}

.mgn-8 {
  margin: 0.5rem;
}

.mgn-top8 {
  margin-top: 0.5rem;
}

.mgn-rgt8 {
  margin-right: 0.5rem;
}

.mgn-btm8 {
  margin-bottom: 0.5rem;
}

.mgn-lft8 {
  margin-left: 0.5rem;
}

.mgn-9 {
  margin: 0.5625rem;
}

.mgn-top9 {
  margin-top: 0.5625rem;
}

.mgn-rgt9 {
  margin-right: 0.5625rem;
}

.mgn-btm9 {
  margin-bottom: 0.5625rem;
}

.mgn-lft9 {
  margin-left: 0.5625rem;
}

.mgn-10 {
  margin: 0.625rem;
}

.mgn-top10 {
  margin-top: 0.625rem;
}

.mgn-rgt10 {
  margin-right: 0.625rem;
}

.mgn-btm10 {
  margin-bottom: 0.625rem;
}

.mgn-lft10 {
  margin-left: 0.625rem;
}

.mgn-11 {
  margin: 0.6875rem;
}

.mgn-top11 {
  margin-top: 0.6875rem;
}

.mgn-rgt11 {
  margin-right: 0.6875rem;
}

.mgn-btm11 {
  margin-bottom: 0.6875rem;
}

.mgn-lft11 {
  margin-left: 0.6875rem;
}

.mgn-12 {
  margin: 0.75rem;
}

.mgn-top12 {
  margin-top: 0.75rem;
}

.mgn-rgt12 {
  margin-right: 0.75rem;
}

.mgn-btm12 {
  margin-bottom: 0.75rem;
}

.mgn-lft12 {
  margin-left: 0.75rem;
}

.mgn-13 {
  margin: 0.8125rem;
}

.mgn-top13 {
  margin-top: 0.8125rem;
}

.mgn-rgt13 {
  margin-right: 0.8125rem;
}

.mgn-btm13 {
  margin-bottom: 0.8125rem;
}

.mgn-lft13 {
  margin-left: 0.8125rem;
}

.mgn-14 {
  margin: 0.875rem;
}

.mgn-top14 {
  margin-top: 0.875rem;
}

.mgn-rgt14 {
  margin-right: 0.875rem;
}

.mgn-btm14 {
  margin-bottom: 0.875rem;
}

.mgn-lft14 {
  margin-left: 0.875rem;
}

.mgn-15 {
  margin: 0.9375rem;
}

.mgn-top15 {
  margin-top: 0.9375rem;
}

.mgn-rgt15 {
  margin-right: 0.9375rem;
}

.mgn-btm15 {
  margin-bottom: 0.9375rem;
}

.mgn-lft15 {
  margin-left: 0.9375rem;
}

.mgn-16 {
  margin: 1rem;
}

.mgn-top16 {
  margin-top: 1rem;
}

.mgn-rgt16 {
  margin-right: 1rem;
}

.mgn-btm16 {
  margin-bottom: 1rem;
}

.mgn-lft16 {
  margin-left: 1rem;
}

.mgn-17 {
  margin: 1.0625rem;
}

.mgn-top17 {
  margin-top: 1.0625rem;
}

.mgn-rgt17 {
  margin-right: 1.0625rem;
}

.mgn-btm17 {
  margin-bottom: 1.0625rem;
}

.mgn-lft17 {
  margin-left: 1.0625rem;
}

.mgn-18 {
  margin: 1.125rem;
}

.mgn-top18 {
  margin-top: 1.125rem;
}

.mgn-rgt18 {
  margin-right: 1.125rem;
}

.mgn-btm18 {
  margin-bottom: 1.125rem;
}

.mgn-lft18 {
  margin-left: 1.125rem;
}

.mgn-19 {
  margin: 1.1875rem;
}

.mgn-top19 {
  margin-top: 1.1875rem;
}

.mgn-rgt19 {
  margin-right: 1.1875rem;
}

.mgn-btm19 {
  margin-bottom: 1.1875rem;
}

.mgn-lft19 {
  margin-left: 1.1875rem;
}

.mgn-20 {
  margin: 1.25rem;
}

.mgn-top20 {
  margin-top: 1.25rem;
}

.mgn-rgt20 {
  margin-right: 1.25rem;
}

.mgn-btm20 {
  margin-bottom: 1.25rem;
}

.mgn-lft20 {
  margin-left: 1.25rem;
}

.mgn-21 {
  margin: 1.3125rem;
}

.mgn-top21 {
  margin-top: 1.3125rem;
}

.mgn-rgt21 {
  margin-right: 1.3125rem;
}

.mgn-btm21 {
  margin-bottom: 1.3125rem;
}

.mgn-lft21 {
  margin-left: 1.3125rem;
}

.mgn-22 {
  margin: 1.375rem;
}

.mgn-top22 {
  margin-top: 1.375rem;
}

.mgn-rgt22 {
  margin-right: 1.375rem;
}

.mgn-btm22 {
  margin-bottom: 1.375rem;
}

.mgn-lft22 {
  margin-left: 1.375rem;
}

.mgn-23 {
  margin: 1.4375rem;
}

.mgn-top23 {
  margin-top: 1.4375rem;
}

.mgn-rgt23 {
  margin-right: 1.4375rem;
}

.mgn-btm23 {
  margin-bottom: 1.4375rem;
}

.mgn-lft23 {
  margin-left: 1.4375rem;
}

.mgn-24 {
  margin: 1.5rem;
}

.mgn-top24 {
  margin-top: 1.5rem;
}

.mgn-rgt24 {
  margin-right: 1.5rem;
}

.mgn-btm24 {
  margin-bottom: 1.5rem;
}

.mgn-lft24 {
  margin-left: 1.5rem;
}

.mgn-25 {
  margin: 1.5625rem;
}

.mgn-top25 {
  margin-top: 1.5625rem;
}

.mgn-rgt25 {
  margin-right: 1.5625rem;
}

.mgn-btm25 {
  margin-bottom: 1.5625rem;
}

.mgn-lft25 {
  margin-left: 1.5625rem;
}

.mgn-26 {
  margin: 1.625rem;
}

.mgn-top26 {
  margin-top: 1.625rem;
}

.mgn-rgt26 {
  margin-right: 1.625rem;
}

.mgn-btm26 {
  margin-bottom: 1.625rem;
}

.mgn-lft26 {
  margin-left: 1.625rem;
}

.mgn-27 {
  margin: 1.6875rem;
}

.mgn-top27 {
  margin-top: 1.6875rem;
}

.mgn-rgt27 {
  margin-right: 1.6875rem;
}

.mgn-btm27 {
  margin-bottom: 1.6875rem;
}

.mgn-lft27 {
  margin-left: 1.6875rem;
}

.mgn-28 {
  margin: 1.75rem;
}

.mgn-top28 {
  margin-top: 1.75rem;
}

.mgn-rgt28 {
  margin-right: 1.75rem;
}

.mgn-btm28 {
  margin-bottom: 1.75rem;
}

.mgn-lft28 {
  margin-left: 1.75rem;
}

.mgn-29 {
  margin: 1.8125rem;
}

.mgn-top29 {
  margin-top: 1.8125rem;
}

.mgn-rgt29 {
  margin-right: 1.8125rem;
}

.mgn-btm29 {
  margin-bottom: 1.8125rem;
}

.mgn-lft29 {
  margin-left: 1.8125rem;
}

.mgn-30 {
  margin: 1.875rem;
}

.mgn-top30 {
  margin-top: 1.875rem;
}

.mgn-rgt30 {
  margin-right: 1.875rem;
}

.mgn-btm30 {
  margin-bottom: 1.875rem;
}

.mgn-lft30 {
  margin-left: 1.875rem;
}

.mgn-31 {
  margin: 1.9375rem;
}

.mgn-top31 {
  margin-top: 1.9375rem;
}

.mgn-rgt31 {
  margin-right: 1.9375rem;
}

.mgn-btm31 {
  margin-bottom: 1.9375rem;
}

.mgn-lft31 {
  margin-left: 1.9375rem;
}

.mgn-32 {
  margin: 2rem;
}

.mgn-top32 {
  margin-top: 2rem;
}

.mgn-rgt32 {
  margin-right: 2rem;
}

.mgn-btm32 {
  margin-bottom: 2rem;
}

.mgn-lft32 {
  margin-left: 2rem;
}

.mgn-33 {
  margin: 2.0625rem;
}

.mgn-top33 {
  margin-top: 2.0625rem;
}

.mgn-rgt33 {
  margin-right: 2.0625rem;
}

.mgn-btm33 {
  margin-bottom: 2.0625rem;
}

.mgn-lft33 {
  margin-left: 2.0625rem;
}

.mgn-34 {
  margin: 2.125rem;
}

.mgn-top34 {
  margin-top: 2.125rem;
}

.mgn-rgt34 {
  margin-right: 2.125rem;
}

.mgn-btm34 {
  margin-bottom: 2.125rem;
}

.mgn-lft34 {
  margin-left: 2.125rem;
}

.mgn-35 {
  margin: 2.1875rem;
}

.mgn-top35 {
  margin-top: 2.1875rem;
}

.mgn-rgt35 {
  margin-right: 2.1875rem;
}

.mgn-btm35 {
  margin-bottom: 2.1875rem;
}

.mgn-lft35 {
  margin-left: 2.1875rem;
}

.mgn-36 {
  margin: 2.25rem;
}

.mgn-top36 {
  margin-top: 2.25rem;
}

.mgn-rgt36 {
  margin-right: 2.25rem;
}

.mgn-btm36 {
  margin-bottom: 2.25rem;
}

.mgn-lft36 {
  margin-left: 2.25rem;
}

.mgn-37 {
  margin: 2.3125rem;
}

.mgn-top37 {
  margin-top: 2.3125rem;
}

.mgn-rgt37 {
  margin-right: 2.3125rem;
}

.mgn-btm37 {
  margin-bottom: 2.3125rem;
}

.mgn-lft37 {
  margin-left: 2.3125rem;
}

.mgn-38 {
  margin: 2.375rem;
}

.mgn-top38 {
  margin-top: 2.375rem;
}

.mgn-rgt38 {
  margin-right: 2.375rem;
}

.mgn-btm38 {
  margin-bottom: 2.375rem;
}

.mgn-lft38 {
  margin-left: 2.375rem;
}

.mgn-39 {
  margin: 2.4375rem;
}

.mgn-top39 {
  margin-top: 2.4375rem;
}

.mgn-rgt39 {
  margin-right: 2.4375rem;
}

.mgn-btm39 {
  margin-bottom: 2.4375rem;
}

.mgn-lft39 {
  margin-left: 2.4375rem;
}

.mgn-40 {
  margin: 2.5rem;
}

.mgn-top40 {
  margin-top: 2.5rem;
}

.mgn-rgt40 {
  margin-right: 2.5rem;
}

.mgn-btm40 {
  margin-bottom: 2.5rem;
}

.mgn-lft40 {
  margin-left: 2.5rem;
}

.mgn-41 {
  margin: 2.5625rem;
}

.mgn-top41 {
  margin-top: 2.5625rem;
}

.mgn-rgt41 {
  margin-right: 2.5625rem;
}

.mgn-btm41 {
  margin-bottom: 2.5625rem;
}

.mgn-lft41 {
  margin-left: 2.5625rem;
}

.mgn-42 {
  margin: 2.625rem;
}

.mgn-top42 {
  margin-top: 2.625rem;
}

.mgn-rgt42 {
  margin-right: 2.625rem;
}

.mgn-btm42 {
  margin-bottom: 2.625rem;
}

.mgn-lft42 {
  margin-left: 2.625rem;
}

.mgn-43 {
  margin: 2.6875rem;
}

.mgn-top43 {
  margin-top: 2.6875rem;
}

.mgn-rgt43 {
  margin-right: 2.6875rem;
}

.mgn-btm43 {
  margin-bottom: 2.6875rem;
}

.mgn-lft43 {
  margin-left: 2.6875rem;
}

.mgn-44 {
  margin: 2.75rem;
}

.mgn-top44 {
  margin-top: 2.75rem;
}

.mgn-rgt44 {
  margin-right: 2.75rem;
}

.mgn-btm44 {
  margin-bottom: 2.75rem;
}

.mgn-lft44 {
  margin-left: 2.75rem;
}

.mgn-45 {
  margin: 2.8125rem;
}

.mgn-top45 {
  margin-top: 2.8125rem;
}

.mgn-rgt45 {
  margin-right: 2.8125rem;
}

.mgn-btm45 {
  margin-bottom: 2.8125rem;
}

.mgn-lft45 {
  margin-left: 2.8125rem;
}

.mgn-46 {
  margin: 2.875rem;
}

.mgn-top46 {
  margin-top: 2.875rem;
}

.mgn-rgt46 {
  margin-right: 2.875rem;
}

.mgn-btm46 {
  margin-bottom: 2.875rem;
}

.mgn-lft46 {
  margin-left: 2.875rem;
}

.mgn-47 {
  margin: 2.9375rem;
}

.mgn-top47 {
  margin-top: 2.9375rem;
}

.mgn-rgt47 {
  margin-right: 2.9375rem;
}

.mgn-btm47 {
  margin-bottom: 2.9375rem;
}

.mgn-lft47 {
  margin-left: 2.9375rem;
}

.mgn-48 {
  margin: 3rem;
}

.mgn-top48 {
  margin-top: 3rem;
}

.mgn-rgt48 {
  margin-right: 3rem;
}

.mgn-btm48 {
  margin-bottom: 3rem;
}

.mgn-lft48 {
  margin-left: 3rem;
}

.mgn-49 {
  margin: 3.0625rem;
}

.mgn-top49 {
  margin-top: 3.0625rem;
}

.mgn-rgt49 {
  margin-right: 3.0625rem;
}

.mgn-btm49 {
  margin-bottom: 3.0625rem;
}

.mgn-lft49 {
  margin-left: 3.0625rem;
}

.mgn-50 {
  margin: 3.125rem;
}

.mgn-top50 {
  margin-top: 3.125rem;
}

.mgn-rgt50 {
  margin-right: 3.125rem;
}

.mgn-btm50 {
  margin-bottom: 3.125rem;
}

.mgn-lft50 {
  margin-left: 3.125rem;
}

.mgn-51 {
  margin: 3.1875rem;
}

.mgn-top51 {
  margin-top: 3.1875rem;
}

.mgn-rgt51 {
  margin-right: 3.1875rem;
}

.mgn-btm51 {
  margin-bottom: 3.1875rem;
}

.mgn-lft51 {
  margin-left: 3.1875rem;
}

.mgn-52 {
  margin: 3.25rem;
}

.mgn-top52 {
  margin-top: 3.25rem;
}

.mgn-rgt52 {
  margin-right: 3.25rem;
}

.mgn-btm52 {
  margin-bottom: 3.25rem;
}

.mgn-lft52 {
  margin-left: 3.25rem;
}

.mgn-53 {
  margin: 3.3125rem;
}

.mgn-top53 {
  margin-top: 3.3125rem;
}

.mgn-rgt53 {
  margin-right: 3.3125rem;
}

.mgn-btm53 {
  margin-bottom: 3.3125rem;
}

.mgn-lft53 {
  margin-left: 3.3125rem;
}

.mgn-54 {
  margin: 3.375rem;
}

.mgn-top54 {
  margin-top: 3.375rem;
}

.mgn-rgt54 {
  margin-right: 3.375rem;
}

.mgn-btm54 {
  margin-bottom: 3.375rem;
}

.mgn-lft54 {
  margin-left: 3.375rem;
}

.mgn-55 {
  margin: 3.4375rem;
}

.mgn-top55 {
  margin-top: 3.4375rem;
}

.mgn-rgt55 {
  margin-right: 3.4375rem;
}

.mgn-btm55 {
  margin-bottom: 3.4375rem;
}

.mgn-lft55 {
  margin-left: 3.4375rem;
}

.mgn-56 {
  margin: 3.5rem;
}

.mgn-top56 {
  margin-top: 3.5rem;
}

.mgn-rgt56 {
  margin-right: 3.5rem;
}

.mgn-btm56 {
  margin-bottom: 3.5rem;
}

.mgn-lft56 {
  margin-left: 3.5rem;
}

.mgn-57 {
  margin: 3.5625rem;
}

.mgn-top57 {
  margin-top: 3.5625rem;
}

.mgn-rgt57 {
  margin-right: 3.5625rem;
}

.mgn-btm57 {
  margin-bottom: 3.5625rem;
}

.mgn-lft57 {
  margin-left: 3.5625rem;
}

.mgn-58 {
  margin: 3.625rem;
}

.mgn-top58 {
  margin-top: 3.625rem;
}

.mgn-rgt58 {
  margin-right: 3.625rem;
}

.mgn-btm58 {
  margin-bottom: 3.625rem;
}

.mgn-lft58 {
  margin-left: 3.625rem;
}

.mgn-59 {
  margin: 3.6875rem;
}

.mgn-top59 {
  margin-top: 3.6875rem;
}

.mgn-rgt59 {
  margin-right: 3.6875rem;
}

.mgn-btm59 {
  margin-bottom: 3.6875rem;
}

.mgn-lft59 {
  margin-left: 3.6875rem;
}

.mgn-60 {
  margin: 3.75rem;
}

.mgn-top60 {
  margin-top: 3.75rem;
}

.mgn-rgt60 {
  margin-right: 3.75rem;
}

.mgn-btm60 {
  margin-bottom: 3.75rem;
}

.mgn-lft60 {
  margin-left: 3.75rem;
}

.mgn-61 {
  margin: 3.8125rem;
}

.mgn-top61 {
  margin-top: 3.8125rem;
}

.mgn-rgt61 {
  margin-right: 3.8125rem;
}

.mgn-btm61 {
  margin-bottom: 3.8125rem;
}

.mgn-lft61 {
  margin-left: 3.8125rem;
}

.mgn-62 {
  margin: 3.875rem;
}

.mgn-top62 {
  margin-top: 3.875rem;
}

.mgn-rgt62 {
  margin-right: 3.875rem;
}

.mgn-btm62 {
  margin-bottom: 3.875rem;
}

.mgn-lft62 {
  margin-left: 3.875rem;
}

.mgn-63 {
  margin: 3.9375rem;
}

.mgn-top63 {
  margin-top: 3.9375rem;
}

.mgn-rgt63 {
  margin-right: 3.9375rem;
}

.mgn-btm63 {
  margin-bottom: 3.9375rem;
}

.mgn-lft63 {
  margin-left: 3.9375rem;
}

.mgn-64 {
  margin: 4rem;
}

.mgn-top64 {
  margin-top: 4rem;
}

.mgn-rgt64 {
  margin-right: 4rem;
}

.mgn-btm64 {
  margin-bottom: 4rem;
}

.mgn-lft64 {
  margin-left: 4rem;
}

.mgn-65 {
  margin: 4.0625rem;
}

.mgn-top65 {
  margin-top: 4.0625rem;
}

.mgn-rgt65 {
  margin-right: 4.0625rem;
}

.mgn-btm65 {
  margin-bottom: 4.0625rem;
}

.mgn-lft65 {
  margin-left: 4.0625rem;
}

.mgn-66 {
  margin: 4.125rem;
}

.mgn-top66 {
  margin-top: 4.125rem;
}

.mgn-rgt66 {
  margin-right: 4.125rem;
}

.mgn-btm66 {
  margin-bottom: 4.125rem;
}

.mgn-lft66 {
  margin-left: 4.125rem;
}

.mgn-67 {
  margin: 4.1875rem;
}

.mgn-top67 {
  margin-top: 4.1875rem;
}

.mgn-rgt67 {
  margin-right: 4.1875rem;
}

.mgn-btm67 {
  margin-bottom: 4.1875rem;
}

.mgn-lft67 {
  margin-left: 4.1875rem;
}

.mgn-68 {
  margin: 4.25rem;
}

.mgn-top68 {
  margin-top: 4.25rem;
}

.mgn-rgt68 {
  margin-right: 4.25rem;
}

.mgn-btm68 {
  margin-bottom: 4.25rem;
}

.mgn-lft68 {
  margin-left: 4.25rem;
}

.mgn-69 {
  margin: 4.3125rem;
}

.mgn-top69 {
  margin-top: 4.3125rem;
}

.mgn-rgt69 {
  margin-right: 4.3125rem;
}

.mgn-btm69 {
  margin-bottom: 4.3125rem;
}

.mgn-lft69 {
  margin-left: 4.3125rem;
}

.mgn-70 {
  margin: 4.375rem;
}

.mgn-top70 {
  margin-top: 4.375rem;
}

.mgn-rgt70 {
  margin-right: 4.375rem;
}

.mgn-btm70 {
  margin-bottom: 4.375rem;
}

.mgn-lft70 {
  margin-left: 4.375rem;
}

.mgn-71 {
  margin: 4.4375rem;
}

.mgn-top71 {
  margin-top: 4.4375rem;
}

.mgn-rgt71 {
  margin-right: 4.4375rem;
}

.mgn-btm71 {
  margin-bottom: 4.4375rem;
}

.mgn-lft71 {
  margin-left: 4.4375rem;
}

.mgn-72 {
  margin: 4.5rem;
}

.mgn-top72 {
  margin-top: 4.5rem;
}

.mgn-rgt72 {
  margin-right: 4.5rem;
}

.mgn-btm72 {
  margin-bottom: 4.5rem;
}

.mgn-lft72 {
  margin-left: 4.5rem;
}

.mgn-73 {
  margin: 4.5625rem;
}

.mgn-top73 {
  margin-top: 4.5625rem;
}

.mgn-rgt73 {
  margin-right: 4.5625rem;
}

.mgn-btm73 {
  margin-bottom: 4.5625rem;
}

.mgn-lft73 {
  margin-left: 4.5625rem;
}

.mgn-74 {
  margin: 4.625rem;
}

.mgn-top74 {
  margin-top: 4.625rem;
}

.mgn-rgt74 {
  margin-right: 4.625rem;
}

.mgn-btm74 {
  margin-bottom: 4.625rem;
}

.mgn-lft74 {
  margin-left: 4.625rem;
}

.mgn-75 {
  margin: 4.6875rem;
}

.mgn-top75 {
  margin-top: 4.6875rem;
}

.mgn-rgt75 {
  margin-right: 4.6875rem;
}

.mgn-btm75 {
  margin-bottom: 4.6875rem;
}

.mgn-lft75 {
  margin-left: 4.6875rem;
}

.mgn-76 {
  margin: 4.75rem;
}

.mgn-top76 {
  margin-top: 4.75rem;
}

.mgn-rgt76 {
  margin-right: 4.75rem;
}

.mgn-btm76 {
  margin-bottom: 4.75rem;
}

.mgn-lft76 {
  margin-left: 4.75rem;
}

.mgn-77 {
  margin: 4.8125rem;
}

.mgn-top77 {
  margin-top: 4.8125rem;
}

.mgn-rgt77 {
  margin-right: 4.8125rem;
}

.mgn-btm77 {
  margin-bottom: 4.8125rem;
}

.mgn-lft77 {
  margin-left: 4.8125rem;
}

.mgn-78 {
  margin: 4.875rem;
}

.mgn-top78 {
  margin-top: 4.875rem;
}

.mgn-rgt78 {
  margin-right: 4.875rem;
}

.mgn-btm78 {
  margin-bottom: 4.875rem;
}

.mgn-lft78 {
  margin-left: 4.875rem;
}

.mgn-79 {
  margin: 4.9375rem;
}

.mgn-top79 {
  margin-top: 4.9375rem;
}

.mgn-rgt79 {
  margin-right: 4.9375rem;
}

.mgn-btm79 {
  margin-bottom: 4.9375rem;
}

.mgn-lft79 {
  margin-left: 4.9375rem;
}

.mgn-80 {
  margin: 5rem;
}

.mgn-top80 {
  margin-top: 5rem;
}

.mgn-rgt80 {
  margin-right: 5rem;
}

.mgn-btm80 {
  margin-bottom: 5rem;
}

.mgn-lft80 {
  margin-left: 5rem;
}

.mgn-81 {
  margin: 5.0625rem;
}

.mgn-top81 {
  margin-top: 5.0625rem;
}

.mgn-rgt81 {
  margin-right: 5.0625rem;
}

.mgn-btm81 {
  margin-bottom: 5.0625rem;
}

.mgn-lft81 {
  margin-left: 5.0625rem;
}

.mgn-82 {
  margin: 5.125rem;
}

.mgn-top82 {
  margin-top: 5.125rem;
}

.mgn-rgt82 {
  margin-right: 5.125rem;
}

.mgn-btm82 {
  margin-bottom: 5.125rem;
}

.mgn-lft82 {
  margin-left: 5.125rem;
}

.mgn-83 {
  margin: 5.1875rem;
}

.mgn-top83 {
  margin-top: 5.1875rem;
}

.mgn-rgt83 {
  margin-right: 5.1875rem;
}

.mgn-btm83 {
  margin-bottom: 5.1875rem;
}

.mgn-lft83 {
  margin-left: 5.1875rem;
}

.mgn-84 {
  margin: 5.25rem;
}

.mgn-top84 {
  margin-top: 5.25rem;
}

.mgn-rgt84 {
  margin-right: 5.25rem;
}

.mgn-btm84 {
  margin-bottom: 5.25rem;
}

.mgn-lft84 {
  margin-left: 5.25rem;
}

.mgn-85 {
  margin: 5.3125rem;
}

.mgn-top85 {
  margin-top: 5.3125rem;
}

.mgn-rgt85 {
  margin-right: 5.3125rem;
}

.mgn-btm85 {
  margin-bottom: 5.3125rem;
}

.mgn-lft85 {
  margin-left: 5.3125rem;
}

.mgn-86 {
  margin: 5.375rem;
}

.mgn-top86 {
  margin-top: 5.375rem;
}

.mgn-rgt86 {
  margin-right: 5.375rem;
}

.mgn-btm86 {
  margin-bottom: 5.375rem;
}

.mgn-lft86 {
  margin-left: 5.375rem;
}

.mgn-87 {
  margin: 5.4375rem;
}

.mgn-top87 {
  margin-top: 5.4375rem;
}

.mgn-rgt87 {
  margin-right: 5.4375rem;
}

.mgn-btm87 {
  margin-bottom: 5.4375rem;
}

.mgn-lft87 {
  margin-left: 5.4375rem;
}

.mgn-88 {
  margin: 5.5rem;
}

.mgn-top88 {
  margin-top: 5.5rem;
}

.mgn-rgt88 {
  margin-right: 5.5rem;
}

.mgn-btm88 {
  margin-bottom: 5.5rem;
}

.mgn-lft88 {
  margin-left: 5.5rem;
}

.mgn-89 {
  margin: 5.5625rem;
}

.mgn-top89 {
  margin-top: 5.5625rem;
}

.mgn-rgt89 {
  margin-right: 5.5625rem;
}

.mgn-btm89 {
  margin-bottom: 5.5625rem;
}

.mgn-lft89 {
  margin-left: 5.5625rem;
}

.mgn-90 {
  margin: 5.625rem;
}

.mgn-top90 {
  margin-top: 5.625rem;
}

.mgn-rgt90 {
  margin-right: 5.625rem;
}

.mgn-btm90 {
  margin-bottom: 5.625rem;
}

.mgn-lft90 {
  margin-left: 5.625rem;
}

.mgn-91 {
  margin: 5.6875rem;
}

.mgn-top91 {
  margin-top: 5.6875rem;
}

.mgn-rgt91 {
  margin-right: 5.6875rem;
}

.mgn-btm91 {
  margin-bottom: 5.6875rem;
}

.mgn-lft91 {
  margin-left: 5.6875rem;
}

.mgn-92 {
  margin: 5.75rem;
}

.mgn-top92 {
  margin-top: 5.75rem;
}

.mgn-rgt92 {
  margin-right: 5.75rem;
}

.mgn-btm92 {
  margin-bottom: 5.75rem;
}

.mgn-lft92 {
  margin-left: 5.75rem;
}

.mgn-93 {
  margin: 5.8125rem;
}

.mgn-top93 {
  margin-top: 5.8125rem;
}

.mgn-rgt93 {
  margin-right: 5.8125rem;
}

.mgn-btm93 {
  margin-bottom: 5.8125rem;
}

.mgn-lft93 {
  margin-left: 5.8125rem;
}

.mgn-94 {
  margin: 5.875rem;
}

.mgn-top94 {
  margin-top: 5.875rem;
}

.mgn-rgt94 {
  margin-right: 5.875rem;
}

.mgn-btm94 {
  margin-bottom: 5.875rem;
}

.mgn-lft94 {
  margin-left: 5.875rem;
}

.mgn-95 {
  margin: 5.9375rem;
}

.mgn-top95 {
  margin-top: 5.9375rem;
}

.mgn-rgt95 {
  margin-right: 5.9375rem;
}

.mgn-btm95 {
  margin-bottom: 5.9375rem;
}

.mgn-lft95 {
  margin-left: 5.9375rem;
}

.mgn-96 {
  margin: 6rem;
}

.mgn-top96 {
  margin-top: 6rem;
}

.mgn-rgt96 {
  margin-right: 6rem;
}

.mgn-btm96 {
  margin-bottom: 6rem;
}

.mgn-lft96 {
  margin-left: 6rem;
}

.mgn-97 {
  margin: 6.0625rem;
}

.mgn-top97 {
  margin-top: 6.0625rem;
}

.mgn-rgt97 {
  margin-right: 6.0625rem;
}

.mgn-btm97 {
  margin-bottom: 6.0625rem;
}

.mgn-lft97 {
  margin-left: 6.0625rem;
}

.mgn-98 {
  margin: 6.125rem;
}

.mgn-top98 {
  margin-top: 6.125rem;
}

.mgn-rgt98 {
  margin-right: 6.125rem;
}

.mgn-btm98 {
  margin-bottom: 6.125rem;
}

.mgn-lft98 {
  margin-left: 6.125rem;
}

.mgn-99 {
  margin: 6.1875rem;
}

.mgn-top99 {
  margin-top: 6.1875rem;
}

.mgn-rgt99 {
  margin-right: 6.1875rem;
}

.mgn-btm99 {
  margin-bottom: 6.1875rem;
}

.mgn-lft99 {
  margin-left: 6.1875rem;
}

.mgn-100 {
  margin: 6.25rem;
}

.mgn-top100 {
  margin-top: 6.25rem;
}

.mgn-rgt100 {
  margin-right: 6.25rem;
}

.mgn-btm100 {
  margin-bottom: 6.25rem;
}

.mgn-lft100 {
  margin-left: 6.25rem;
}

.mgn-101 {
  margin: 6.3125rem;
}

.mgn-top101 {
  margin-top: 6.3125rem;
}

.mgn-rgt101 {
  margin-right: 6.3125rem;
}

.mgn-btm101 {
  margin-bottom: 6.3125rem;
}

.mgn-lft101 {
  margin-left: 6.3125rem;
}

.mgn-102 {
  margin: 6.375rem;
}

.mgn-top102 {
  margin-top: 6.375rem;
}

.mgn-rgt102 {
  margin-right: 6.375rem;
}

.mgn-btm102 {
  margin-bottom: 6.375rem;
}

.mgn-lft102 {
  margin-left: 6.375rem;
}

.mgn-103 {
  margin: 6.4375rem;
}

.mgn-top103 {
  margin-top: 6.4375rem;
}

.mgn-rgt103 {
  margin-right: 6.4375rem;
}

.mgn-btm103 {
  margin-bottom: 6.4375rem;
}

.mgn-lft103 {
  margin-left: 6.4375rem;
}

.mgn-104 {
  margin: 6.5rem;
}

.mgn-top104 {
  margin-top: 6.5rem;
}

.mgn-rgt104 {
  margin-right: 6.5rem;
}

.mgn-btm104 {
  margin-bottom: 6.5rem;
}

.mgn-lft104 {
  margin-left: 6.5rem;
}

.mgn-105 {
  margin: 6.5625rem;
}

.mgn-top105 {
  margin-top: 6.5625rem;
}

.mgn-rgt105 {
  margin-right: 6.5625rem;
}

.mgn-btm105 {
  margin-bottom: 6.5625rem;
}

.mgn-lft105 {
  margin-left: 6.5625rem;
}

.mgn-106 {
  margin: 6.625rem;
}

.mgn-top106 {
  margin-top: 6.625rem;
}

.mgn-rgt106 {
  margin-right: 6.625rem;
}

.mgn-btm106 {
  margin-bottom: 6.625rem;
}

.mgn-lft106 {
  margin-left: 6.625rem;
}

.mgn-107 {
  margin: 6.6875rem;
}

.mgn-top107 {
  margin-top: 6.6875rem;
}

.mgn-rgt107 {
  margin-right: 6.6875rem;
}

.mgn-btm107 {
  margin-bottom: 6.6875rem;
}

.mgn-lft107 {
  margin-left: 6.6875rem;
}

.mgn-108 {
  margin: 6.75rem;
}

.mgn-top108 {
  margin-top: 6.75rem;
}

.mgn-rgt108 {
  margin-right: 6.75rem;
}

.mgn-btm108 {
  margin-bottom: 6.75rem;
}

.mgn-lft108 {
  margin-left: 6.75rem;
}

.mgn-109 {
  margin: 6.8125rem;
}

.mgn-top109 {
  margin-top: 6.8125rem;
}

.mgn-rgt109 {
  margin-right: 6.8125rem;
}

.mgn-btm109 {
  margin-bottom: 6.8125rem;
}

.mgn-lft109 {
  margin-left: 6.8125rem;
}

.mgn-110 {
  margin: 6.875rem;
}

.mgn-top110 {
  margin-top: 6.875rem;
}

.mgn-rgt110 {
  margin-right: 6.875rem;
}

.mgn-btm110 {
  margin-bottom: 6.875rem;
}

.mgn-lft110 {
  margin-left: 6.875rem;
}

.mgn-111 {
  margin: 6.9375rem;
}

.mgn-top111 {
  margin-top: 6.9375rem;
}

.mgn-rgt111 {
  margin-right: 6.9375rem;
}

.mgn-btm111 {
  margin-bottom: 6.9375rem;
}

.mgn-lft111 {
  margin-left: 6.9375rem;
}

.mgn-112 {
  margin: 7rem;
}

.mgn-top112 {
  margin-top: 7rem;
}

.mgn-rgt112 {
  margin-right: 7rem;
}

.mgn-btm112 {
  margin-bottom: 7rem;
}

.mgn-lft112 {
  margin-left: 7rem;
}

.mgn-113 {
  margin: 7.0625rem;
}

.mgn-top113 {
  margin-top: 7.0625rem;
}

.mgn-rgt113 {
  margin-right: 7.0625rem;
}

.mgn-btm113 {
  margin-bottom: 7.0625rem;
}

.mgn-lft113 {
  margin-left: 7.0625rem;
}

.mgn-114 {
  margin: 7.125rem;
}

.mgn-top114 {
  margin-top: 7.125rem;
}

.mgn-rgt114 {
  margin-right: 7.125rem;
}

.mgn-btm114 {
  margin-bottom: 7.125rem;
}

.mgn-lft114 {
  margin-left: 7.125rem;
}

.mgn-115 {
  margin: 7.1875rem;
}

.mgn-top115 {
  margin-top: 7.1875rem;
}

.mgn-rgt115 {
  margin-right: 7.1875rem;
}

.mgn-btm115 {
  margin-bottom: 7.1875rem;
}

.mgn-lft115 {
  margin-left: 7.1875rem;
}

.mgn-116 {
  margin: 7.25rem;
}

.mgn-top116 {
  margin-top: 7.25rem;
}

.mgn-rgt116 {
  margin-right: 7.25rem;
}

.mgn-btm116 {
  margin-bottom: 7.25rem;
}

.mgn-lft116 {
  margin-left: 7.25rem;
}

.mgn-117 {
  margin: 7.3125rem;
}

.mgn-top117 {
  margin-top: 7.3125rem;
}

.mgn-rgt117 {
  margin-right: 7.3125rem;
}

.mgn-btm117 {
  margin-bottom: 7.3125rem;
}

.mgn-lft117 {
  margin-left: 7.3125rem;
}

.mgn-118 {
  margin: 7.375rem;
}

.mgn-top118 {
  margin-top: 7.375rem;
}

.mgn-rgt118 {
  margin-right: 7.375rem;
}

.mgn-btm118 {
  margin-bottom: 7.375rem;
}

.mgn-lft118 {
  margin-left: 7.375rem;
}

.mgn-119 {
  margin: 7.4375rem;
}

.mgn-top119 {
  margin-top: 7.4375rem;
}

.mgn-rgt119 {
  margin-right: 7.4375rem;
}

.mgn-btm119 {
  margin-bottom: 7.4375rem;
}

.mgn-lft119 {
  margin-left: 7.4375rem;
}

.mgn-120 {
  margin: 7.5rem;
}

.mgn-top120 {
  margin-top: 7.5rem;
}

.mgn-rgt120 {
  margin-right: 7.5rem;
}

.mgn-btm120 {
  margin-bottom: 7.5rem;
}

.mgn-lft120 {
  margin-left: 7.5rem;
}

.mgn-121 {
  margin: 7.5625rem;
}

.mgn-top121 {
  margin-top: 7.5625rem;
}

.mgn-rgt121 {
  margin-right: 7.5625rem;
}

.mgn-btm121 {
  margin-bottom: 7.5625rem;
}

.mgn-lft121 {
  margin-left: 7.5625rem;
}

.mgn-122 {
  margin: 7.625rem;
}

.mgn-top122 {
  margin-top: 7.625rem;
}

.mgn-rgt122 {
  margin-right: 7.625rem;
}

.mgn-btm122 {
  margin-bottom: 7.625rem;
}

.mgn-lft122 {
  margin-left: 7.625rem;
}

.mgn-123 {
  margin: 7.6875rem;
}

.mgn-top123 {
  margin-top: 7.6875rem;
}

.mgn-rgt123 {
  margin-right: 7.6875rem;
}

.mgn-btm123 {
  margin-bottom: 7.6875rem;
}

.mgn-lft123 {
  margin-left: 7.6875rem;
}

.mgn-124 {
  margin: 7.75rem;
}

.mgn-top124 {
  margin-top: 7.75rem;
}

.mgn-rgt124 {
  margin-right: 7.75rem;
}

.mgn-btm124 {
  margin-bottom: 7.75rem;
}

.mgn-lft124 {
  margin-left: 7.75rem;
}

.mgn-125 {
  margin: 7.8125rem;
}

.mgn-top125 {
  margin-top: 7.8125rem;
}

.mgn-rgt125 {
  margin-right: 7.8125rem;
}

.mgn-btm125 {
  margin-bottom: 7.8125rem;
}

.mgn-lft125 {
  margin-left: 7.8125rem;
}

.mgn-126 {
  margin: 7.875rem;
}

.mgn-top126 {
  margin-top: 7.875rem;
}

.mgn-rgt126 {
  margin-right: 7.875rem;
}

.mgn-btm126 {
  margin-bottom: 7.875rem;
}

.mgn-lft126 {
  margin-left: 7.875rem;
}

.mgn-127 {
  margin: 7.9375rem;
}

.mgn-top127 {
  margin-top: 7.9375rem;
}

.mgn-rgt127 {
  margin-right: 7.9375rem;
}

.mgn-btm127 {
  margin-bottom: 7.9375rem;
}

.mgn-lft127 {
  margin-left: 7.9375rem;
}

.mgn-128 {
  margin: 8rem;
}

.mgn-top128 {
  margin-top: 8rem;
}

.mgn-rgt128 {
  margin-right: 8rem;
}

.mgn-btm128 {
  margin-bottom: 8rem;
}

.mgn-lft128 {
  margin-left: 8rem;
}

.mgn-129 {
  margin: 8.0625rem;
}

.mgn-top129 {
  margin-top: 8.0625rem;
}

.mgn-rgt129 {
  margin-right: 8.0625rem;
}

.mgn-btm129 {
  margin-bottom: 8.0625rem;
}

.mgn-lft129 {
  margin-left: 8.0625rem;
}

.mgn-130 {
  margin: 8.125rem;
}

.mgn-top130 {
  margin-top: 8.125rem;
}

.mgn-rgt130 {
  margin-right: 8.125rem;
}

.mgn-btm130 {
  margin-bottom: 8.125rem;
}

.mgn-lft130 {
  margin-left: 8.125rem;
}

.mgn-131 {
  margin: 8.1875rem;
}

.mgn-top131 {
  margin-top: 8.1875rem;
}

.mgn-rgt131 {
  margin-right: 8.1875rem;
}

.mgn-btm131 {
  margin-bottom: 8.1875rem;
}

.mgn-lft131 {
  margin-left: 8.1875rem;
}

.mgn-132 {
  margin: 8.25rem;
}

.mgn-top132 {
  margin-top: 8.25rem;
}

.mgn-rgt132 {
  margin-right: 8.25rem;
}

.mgn-btm132 {
  margin-bottom: 8.25rem;
}

.mgn-lft132 {
  margin-left: 8.25rem;
}

.mgn-133 {
  margin: 8.3125rem;
}

.mgn-top133 {
  margin-top: 8.3125rem;
}

.mgn-rgt133 {
  margin-right: 8.3125rem;
}

.mgn-btm133 {
  margin-bottom: 8.3125rem;
}

.mgn-lft133 {
  margin-left: 8.3125rem;
}

.mgn-134 {
  margin: 8.375rem;
}

.mgn-top134 {
  margin-top: 8.375rem;
}

.mgn-rgt134 {
  margin-right: 8.375rem;
}

.mgn-btm134 {
  margin-bottom: 8.375rem;
}

.mgn-lft134 {
  margin-left: 8.375rem;
}

.mgn-135 {
  margin: 8.4375rem;
}

.mgn-top135 {
  margin-top: 8.4375rem;
}

.mgn-rgt135 {
  margin-right: 8.4375rem;
}

.mgn-btm135 {
  margin-bottom: 8.4375rem;
}

.mgn-lft135 {
  margin-left: 8.4375rem;
}

.mgn-136 {
  margin: 8.5rem;
}

.mgn-top136 {
  margin-top: 8.5rem;
}

.mgn-rgt136 {
  margin-right: 8.5rem;
}

.mgn-btm136 {
  margin-bottom: 8.5rem;
}

.mgn-lft136 {
  margin-left: 8.5rem;
}

.mgn-137 {
  margin: 8.5625rem;
}

.mgn-top137 {
  margin-top: 8.5625rem;
}

.mgn-rgt137 {
  margin-right: 8.5625rem;
}

.mgn-btm137 {
  margin-bottom: 8.5625rem;
}

.mgn-lft137 {
  margin-left: 8.5625rem;
}

.mgn-138 {
  margin: 8.625rem;
}

.mgn-top138 {
  margin-top: 8.625rem;
}

.mgn-rgt138 {
  margin-right: 8.625rem;
}

.mgn-btm138 {
  margin-bottom: 8.625rem;
}

.mgn-lft138 {
  margin-left: 8.625rem;
}

.mgn-139 {
  margin: 8.6875rem;
}

.mgn-top139 {
  margin-top: 8.6875rem;
}

.mgn-rgt139 {
  margin-right: 8.6875rem;
}

.mgn-btm139 {
  margin-bottom: 8.6875rem;
}

.mgn-lft139 {
  margin-left: 8.6875rem;
}

.mgn-140 {
  margin: 8.75rem;
}

.mgn-top140 {
  margin-top: 8.75rem;
}

.mgn-rgt140 {
  margin-right: 8.75rem;
}

.mgn-btm140 {
  margin-bottom: 8.75rem;
}

.mgn-lft140 {
  margin-left: 8.75rem;
}

.mgn-141 {
  margin: 8.8125rem;
}

.mgn-top141 {
  margin-top: 8.8125rem;
}

.mgn-rgt141 {
  margin-right: 8.8125rem;
}

.mgn-btm141 {
  margin-bottom: 8.8125rem;
}

.mgn-lft141 {
  margin-left: 8.8125rem;
}

.mgn-142 {
  margin: 8.875rem;
}

.mgn-top142 {
  margin-top: 8.875rem;
}

.mgn-rgt142 {
  margin-right: 8.875rem;
}

.mgn-btm142 {
  margin-bottom: 8.875rem;
}

.mgn-lft142 {
  margin-left: 8.875rem;
}

.mgn-143 {
  margin: 8.9375rem;
}

.mgn-top143 {
  margin-top: 8.9375rem;
}

.mgn-rgt143 {
  margin-right: 8.9375rem;
}

.mgn-btm143 {
  margin-bottom: 8.9375rem;
}

.mgn-lft143 {
  margin-left: 8.9375rem;
}

.mgn-144 {
  margin: 9rem;
}

.mgn-top144 {
  margin-top: 9rem;
}

.mgn-rgt144 {
  margin-right: 9rem;
}

.mgn-btm144 {
  margin-bottom: 9rem;
}

.mgn-lft144 {
  margin-left: 9rem;
}

.mgn-145 {
  margin: 9.0625rem;
}

.mgn-top145 {
  margin-top: 9.0625rem;
}

.mgn-rgt145 {
  margin-right: 9.0625rem;
}

.mgn-btm145 {
  margin-bottom: 9.0625rem;
}

.mgn-lft145 {
  margin-left: 9.0625rem;
}

.mgn-146 {
  margin: 9.125rem;
}

.mgn-top146 {
  margin-top: 9.125rem;
}

.mgn-rgt146 {
  margin-right: 9.125rem;
}

.mgn-btm146 {
  margin-bottom: 9.125rem;
}

.mgn-lft146 {
  margin-left: 9.125rem;
}

.mgn-147 {
  margin: 9.1875rem;
}

.mgn-top147 {
  margin-top: 9.1875rem;
}

.mgn-rgt147 {
  margin-right: 9.1875rem;
}

.mgn-btm147 {
  margin-bottom: 9.1875rem;
}

.mgn-lft147 {
  margin-left: 9.1875rem;
}

.mgn-148 {
  margin: 9.25rem;
}

.mgn-top148 {
  margin-top: 9.25rem;
}

.mgn-rgt148 {
  margin-right: 9.25rem;
}

.mgn-btm148 {
  margin-bottom: 9.25rem;
}

.mgn-lft148 {
  margin-left: 9.25rem;
}

.mgn-149 {
  margin: 9.3125rem;
}

.mgn-top149 {
  margin-top: 9.3125rem;
}

.mgn-rgt149 {
  margin-right: 9.3125rem;
}

.mgn-btm149 {
  margin-bottom: 9.3125rem;
}

.mgn-lft149 {
  margin-left: 9.3125rem;
}

.mgn-150 {
  margin: 9.375rem;
}

.mgn-top150 {
  margin-top: 9.375rem;
}

.mgn-rgt150 {
  margin-right: 9.375rem;
}

.mgn-btm150 {
  margin-bottom: 9.375rem;
}

.mgn-lft150 {
  margin-left: 9.375rem;
}

@media screen and (max-width: 47.9375em) {
  .mgn-0 {
    margin: 0rem;
  }
  .mgn-top0 {
    margin-top: 0rem;
  }
  .mgn-rgt0 {
    margin-right: 0rem;
  }
  .mgn-btm0 {
    margin-bottom: 0rem;
  }
  .mgn-lft0 {
    margin-left: 0rem;
  }
  .mgn-1 {
    margin: 0.0357142857rem;
  }
  .mgn-top1 {
    margin-top: 0.0357142857rem;
  }
  .mgn-rgt1 {
    margin-right: 0.0357142857rem;
  }
  .mgn-btm1 {
    margin-bottom: 0.0357142857rem;
  }
  .mgn-lft1 {
    margin-left: 0.0357142857rem;
  }
  .mgn-2 {
    margin: 0.0714285714rem;
  }
  .mgn-top2 {
    margin-top: 0.0714285714rem;
  }
  .mgn-rgt2 {
    margin-right: 0.0714285714rem;
  }
  .mgn-btm2 {
    margin-bottom: 0.0714285714rem;
  }
  .mgn-lft2 {
    margin-left: 0.0714285714rem;
  }
  .mgn-3 {
    margin: 0.1071428571rem;
  }
  .mgn-top3 {
    margin-top: 0.1071428571rem;
  }
  .mgn-rgt3 {
    margin-right: 0.1071428571rem;
  }
  .mgn-btm3 {
    margin-bottom: 0.1071428571rem;
  }
  .mgn-lft3 {
    margin-left: 0.1071428571rem;
  }
  .mgn-4 {
    margin: 0.1428571429rem;
  }
  .mgn-top4 {
    margin-top: 0.1428571429rem;
  }
  .mgn-rgt4 {
    margin-right: 0.1428571429rem;
  }
  .mgn-btm4 {
    margin-bottom: 0.1428571429rem;
  }
  .mgn-lft4 {
    margin-left: 0.1428571429rem;
  }
  .mgn-5 {
    margin: 0.1785714286rem;
  }
  .mgn-top5 {
    margin-top: 0.1785714286rem;
  }
  .mgn-rgt5 {
    margin-right: 0.1785714286rem;
  }
  .mgn-btm5 {
    margin-bottom: 0.1785714286rem;
  }
  .mgn-lft5 {
    margin-left: 0.1785714286rem;
  }
  .mgn-6 {
    margin: 0.2142857143rem;
  }
  .mgn-top6 {
    margin-top: 0.2142857143rem;
  }
  .mgn-rgt6 {
    margin-right: 0.2142857143rem;
  }
  .mgn-btm6 {
    margin-bottom: 0.2142857143rem;
  }
  .mgn-lft6 {
    margin-left: 0.2142857143rem;
  }
  .mgn-7 {
    margin: 0.25rem;
  }
  .mgn-top7 {
    margin-top: 0.25rem;
  }
  .mgn-rgt7 {
    margin-right: 0.25rem;
  }
  .mgn-btm7 {
    margin-bottom: 0.25rem;
  }
  .mgn-lft7 {
    margin-left: 0.25rem;
  }
  .mgn-8 {
    margin: 0.2857142857rem;
  }
  .mgn-top8 {
    margin-top: 0.2857142857rem;
  }
  .mgn-rgt8 {
    margin-right: 0.2857142857rem;
  }
  .mgn-btm8 {
    margin-bottom: 0.2857142857rem;
  }
  .mgn-lft8 {
    margin-left: 0.2857142857rem;
  }
  .mgn-9 {
    margin: 0.3214285714rem;
  }
  .mgn-top9 {
    margin-top: 0.3214285714rem;
  }
  .mgn-rgt9 {
    margin-right: 0.3214285714rem;
  }
  .mgn-btm9 {
    margin-bottom: 0.3214285714rem;
  }
  .mgn-lft9 {
    margin-left: 0.3214285714rem;
  }
  .mgn-10 {
    margin: 0.3571428571rem;
  }
  .mgn-top10 {
    margin-top: 0.3571428571rem;
  }
  .mgn-rgt10 {
    margin-right: 0.3571428571rem;
  }
  .mgn-btm10 {
    margin-bottom: 0.3571428571rem;
  }
  .mgn-lft10 {
    margin-left: 0.3571428571rem;
  }
  .mgn-11 {
    margin: 0.3928571429rem;
  }
  .mgn-top11 {
    margin-top: 0.3928571429rem;
  }
  .mgn-rgt11 {
    margin-right: 0.3928571429rem;
  }
  .mgn-btm11 {
    margin-bottom: 0.3928571429rem;
  }
  .mgn-lft11 {
    margin-left: 0.3928571429rem;
  }
  .mgn-12 {
    margin: 0.4285714286rem;
  }
  .mgn-top12 {
    margin-top: 0.4285714286rem;
  }
  .mgn-rgt12 {
    margin-right: 0.4285714286rem;
  }
  .mgn-btm12 {
    margin-bottom: 0.4285714286rem;
  }
  .mgn-lft12 {
    margin-left: 0.4285714286rem;
  }
  .mgn-13 {
    margin: 0.4642857143rem;
  }
  .mgn-top13 {
    margin-top: 0.4642857143rem;
  }
  .mgn-rgt13 {
    margin-right: 0.4642857143rem;
  }
  .mgn-btm13 {
    margin-bottom: 0.4642857143rem;
  }
  .mgn-lft13 {
    margin-left: 0.4642857143rem;
  }
  .mgn-14 {
    margin: 0.5rem;
  }
  .mgn-top14 {
    margin-top: 0.5rem;
  }
  .mgn-rgt14 {
    margin-right: 0.5rem;
  }
  .mgn-btm14 {
    margin-bottom: 0.5rem;
  }
  .mgn-lft14 {
    margin-left: 0.5rem;
  }
  .mgn-15 {
    margin: 0.5357142857rem;
  }
  .mgn-top15 {
    margin-top: 0.5357142857rem;
  }
  .mgn-rgt15 {
    margin-right: 0.5357142857rem;
  }
  .mgn-btm15 {
    margin-bottom: 0.5357142857rem;
  }
  .mgn-lft15 {
    margin-left: 0.5357142857rem;
  }
  .mgn-16 {
    margin: 0.5714285714rem;
  }
  .mgn-top16 {
    margin-top: 0.5714285714rem;
  }
  .mgn-rgt16 {
    margin-right: 0.5714285714rem;
  }
  .mgn-btm16 {
    margin-bottom: 0.5714285714rem;
  }
  .mgn-lft16 {
    margin-left: 0.5714285714rem;
  }
  .mgn-17 {
    margin: 0.6071428571rem;
  }
  .mgn-top17 {
    margin-top: 0.6071428571rem;
  }
  .mgn-rgt17 {
    margin-right: 0.6071428571rem;
  }
  .mgn-btm17 {
    margin-bottom: 0.6071428571rem;
  }
  .mgn-lft17 {
    margin-left: 0.6071428571rem;
  }
  .mgn-18 {
    margin: 0.6428571429rem;
  }
  .mgn-top18 {
    margin-top: 0.6428571429rem;
  }
  .mgn-rgt18 {
    margin-right: 0.6428571429rem;
  }
  .mgn-btm18 {
    margin-bottom: 0.6428571429rem;
  }
  .mgn-lft18 {
    margin-left: 0.6428571429rem;
  }
  .mgn-19 {
    margin: 0.6785714286rem;
  }
  .mgn-top19 {
    margin-top: 0.6785714286rem;
  }
  .mgn-rgt19 {
    margin-right: 0.6785714286rem;
  }
  .mgn-btm19 {
    margin-bottom: 0.6785714286rem;
  }
  .mgn-lft19 {
    margin-left: 0.6785714286rem;
  }
  .mgn-20 {
    margin: 0.7142857143rem;
  }
  .mgn-top20 {
    margin-top: 0.7142857143rem;
  }
  .mgn-rgt20 {
    margin-right: 0.7142857143rem;
  }
  .mgn-btm20 {
    margin-bottom: 0.7142857143rem;
  }
  .mgn-lft20 {
    margin-left: 0.7142857143rem;
  }
  .mgn-21 {
    margin: 0.75rem;
  }
  .mgn-top21 {
    margin-top: 0.75rem;
  }
  .mgn-rgt21 {
    margin-right: 0.75rem;
  }
  .mgn-btm21 {
    margin-bottom: 0.75rem;
  }
  .mgn-lft21 {
    margin-left: 0.75rem;
  }
  .mgn-22 {
    margin: 0.7857142857rem;
  }
  .mgn-top22 {
    margin-top: 0.7857142857rem;
  }
  .mgn-rgt22 {
    margin-right: 0.7857142857rem;
  }
  .mgn-btm22 {
    margin-bottom: 0.7857142857rem;
  }
  .mgn-lft22 {
    margin-left: 0.7857142857rem;
  }
  .mgn-23 {
    margin: 0.8214285714rem;
  }
  .mgn-top23 {
    margin-top: 0.8214285714rem;
  }
  .mgn-rgt23 {
    margin-right: 0.8214285714rem;
  }
  .mgn-btm23 {
    margin-bottom: 0.8214285714rem;
  }
  .mgn-lft23 {
    margin-left: 0.8214285714rem;
  }
  .mgn-24 {
    margin: 0.8571428571rem;
  }
  .mgn-top24 {
    margin-top: 0.8571428571rem;
  }
  .mgn-rgt24 {
    margin-right: 0.8571428571rem;
  }
  .mgn-btm24 {
    margin-bottom: 0.8571428571rem;
  }
  .mgn-lft24 {
    margin-left: 0.8571428571rem;
  }
  .mgn-25 {
    margin: 0.8928571429rem;
  }
  .mgn-top25 {
    margin-top: 0.8928571429rem;
  }
  .mgn-rgt25 {
    margin-right: 0.8928571429rem;
  }
  .mgn-btm25 {
    margin-bottom: 0.8928571429rem;
  }
  .mgn-lft25 {
    margin-left: 0.8928571429rem;
  }
  .mgn-26 {
    margin: 0.9285714286rem;
  }
  .mgn-top26 {
    margin-top: 0.9285714286rem;
  }
  .mgn-rgt26 {
    margin-right: 0.9285714286rem;
  }
  .mgn-btm26 {
    margin-bottom: 0.9285714286rem;
  }
  .mgn-lft26 {
    margin-left: 0.9285714286rem;
  }
  .mgn-27 {
    margin: 0.9642857143rem;
  }
  .mgn-top27 {
    margin-top: 0.9642857143rem;
  }
  .mgn-rgt27 {
    margin-right: 0.9642857143rem;
  }
  .mgn-btm27 {
    margin-bottom: 0.9642857143rem;
  }
  .mgn-lft27 {
    margin-left: 0.9642857143rem;
  }
  .mgn-28 {
    margin: 1rem;
  }
  .mgn-top28 {
    margin-top: 1rem;
  }
  .mgn-rgt28 {
    margin-right: 1rem;
  }
  .mgn-btm28 {
    margin-bottom: 1rem;
  }
  .mgn-lft28 {
    margin-left: 1rem;
  }
  .mgn-29 {
    margin: 1.0357142857rem;
  }
  .mgn-top29 {
    margin-top: 1.0357142857rem;
  }
  .mgn-rgt29 {
    margin-right: 1.0357142857rem;
  }
  .mgn-btm29 {
    margin-bottom: 1.0357142857rem;
  }
  .mgn-lft29 {
    margin-left: 1.0357142857rem;
  }
  .mgn-30 {
    margin: 1.0714285714rem;
  }
  .mgn-top30 {
    margin-top: 1.0714285714rem;
  }
  .mgn-rgt30 {
    margin-right: 1.0714285714rem;
  }
  .mgn-btm30 {
    margin-bottom: 1.0714285714rem;
  }
  .mgn-lft30 {
    margin-left: 1.0714285714rem;
  }
  .mgn-31 {
    margin: 1.1071428571rem;
  }
  .mgn-top31 {
    margin-top: 1.1071428571rem;
  }
  .mgn-rgt31 {
    margin-right: 1.1071428571rem;
  }
  .mgn-btm31 {
    margin-bottom: 1.1071428571rem;
  }
  .mgn-lft31 {
    margin-left: 1.1071428571rem;
  }
  .mgn-32 {
    margin: 1.1428571429rem;
  }
  .mgn-top32 {
    margin-top: 1.1428571429rem;
  }
  .mgn-rgt32 {
    margin-right: 1.1428571429rem;
  }
  .mgn-btm32 {
    margin-bottom: 1.1428571429rem;
  }
  .mgn-lft32 {
    margin-left: 1.1428571429rem;
  }
  .mgn-33 {
    margin: 1.1785714286rem;
  }
  .mgn-top33 {
    margin-top: 1.1785714286rem;
  }
  .mgn-rgt33 {
    margin-right: 1.1785714286rem;
  }
  .mgn-btm33 {
    margin-bottom: 1.1785714286rem;
  }
  .mgn-lft33 {
    margin-left: 1.1785714286rem;
  }
  .mgn-34 {
    margin: 1.2142857143rem;
  }
  .mgn-top34 {
    margin-top: 1.2142857143rem;
  }
  .mgn-rgt34 {
    margin-right: 1.2142857143rem;
  }
  .mgn-btm34 {
    margin-bottom: 1.2142857143rem;
  }
  .mgn-lft34 {
    margin-left: 1.2142857143rem;
  }
  .mgn-35 {
    margin: 1.25rem;
  }
  .mgn-top35 {
    margin-top: 1.25rem;
  }
  .mgn-rgt35 {
    margin-right: 1.25rem;
  }
  .mgn-btm35 {
    margin-bottom: 1.25rem;
  }
  .mgn-lft35 {
    margin-left: 1.25rem;
  }
  .mgn-36 {
    margin: 1.2857142857rem;
  }
  .mgn-top36 {
    margin-top: 1.2857142857rem;
  }
  .mgn-rgt36 {
    margin-right: 1.2857142857rem;
  }
  .mgn-btm36 {
    margin-bottom: 1.2857142857rem;
  }
  .mgn-lft36 {
    margin-left: 1.2857142857rem;
  }
  .mgn-37 {
    margin: 1.3214285714rem;
  }
  .mgn-top37 {
    margin-top: 1.3214285714rem;
  }
  .mgn-rgt37 {
    margin-right: 1.3214285714rem;
  }
  .mgn-btm37 {
    margin-bottom: 1.3214285714rem;
  }
  .mgn-lft37 {
    margin-left: 1.3214285714rem;
  }
  .mgn-38 {
    margin: 1.3571428571rem;
  }
  .mgn-top38 {
    margin-top: 1.3571428571rem;
  }
  .mgn-rgt38 {
    margin-right: 1.3571428571rem;
  }
  .mgn-btm38 {
    margin-bottom: 1.3571428571rem;
  }
  .mgn-lft38 {
    margin-left: 1.3571428571rem;
  }
  .mgn-39 {
    margin: 1.3928571429rem;
  }
  .mgn-top39 {
    margin-top: 1.3928571429rem;
  }
  .mgn-rgt39 {
    margin-right: 1.3928571429rem;
  }
  .mgn-btm39 {
    margin-bottom: 1.3928571429rem;
  }
  .mgn-lft39 {
    margin-left: 1.3928571429rem;
  }
  .mgn-40 {
    margin: 1.4285714286rem;
  }
  .mgn-top40 {
    margin-top: 1.4285714286rem;
  }
  .mgn-rgt40 {
    margin-right: 1.4285714286rem;
  }
  .mgn-btm40 {
    margin-bottom: 1.4285714286rem;
  }
  .mgn-lft40 {
    margin-left: 1.4285714286rem;
  }
  .mgn-41 {
    margin: 1.4642857143rem;
  }
  .mgn-top41 {
    margin-top: 1.4642857143rem;
  }
  .mgn-rgt41 {
    margin-right: 1.4642857143rem;
  }
  .mgn-btm41 {
    margin-bottom: 1.4642857143rem;
  }
  .mgn-lft41 {
    margin-left: 1.4642857143rem;
  }
  .mgn-42 {
    margin: 1.5rem;
  }
  .mgn-top42 {
    margin-top: 1.5rem;
  }
  .mgn-rgt42 {
    margin-right: 1.5rem;
  }
  .mgn-btm42 {
    margin-bottom: 1.5rem;
  }
  .mgn-lft42 {
    margin-left: 1.5rem;
  }
  .mgn-43 {
    margin: 1.5357142857rem;
  }
  .mgn-top43 {
    margin-top: 1.5357142857rem;
  }
  .mgn-rgt43 {
    margin-right: 1.5357142857rem;
  }
  .mgn-btm43 {
    margin-bottom: 1.5357142857rem;
  }
  .mgn-lft43 {
    margin-left: 1.5357142857rem;
  }
  .mgn-44 {
    margin: 1.5714285714rem;
  }
  .mgn-top44 {
    margin-top: 1.5714285714rem;
  }
  .mgn-rgt44 {
    margin-right: 1.5714285714rem;
  }
  .mgn-btm44 {
    margin-bottom: 1.5714285714rem;
  }
  .mgn-lft44 {
    margin-left: 1.5714285714rem;
  }
  .mgn-45 {
    margin: 1.6071428571rem;
  }
  .mgn-top45 {
    margin-top: 1.6071428571rem;
  }
  .mgn-rgt45 {
    margin-right: 1.6071428571rem;
  }
  .mgn-btm45 {
    margin-bottom: 1.6071428571rem;
  }
  .mgn-lft45 {
    margin-left: 1.6071428571rem;
  }
  .mgn-46 {
    margin: 1.6428571429rem;
  }
  .mgn-top46 {
    margin-top: 1.6428571429rem;
  }
  .mgn-rgt46 {
    margin-right: 1.6428571429rem;
  }
  .mgn-btm46 {
    margin-bottom: 1.6428571429rem;
  }
  .mgn-lft46 {
    margin-left: 1.6428571429rem;
  }
  .mgn-47 {
    margin: 1.6785714286rem;
  }
  .mgn-top47 {
    margin-top: 1.6785714286rem;
  }
  .mgn-rgt47 {
    margin-right: 1.6785714286rem;
  }
  .mgn-btm47 {
    margin-bottom: 1.6785714286rem;
  }
  .mgn-lft47 {
    margin-left: 1.6785714286rem;
  }
  .mgn-48 {
    margin: 1.7142857143rem;
  }
  .mgn-top48 {
    margin-top: 1.7142857143rem;
  }
  .mgn-rgt48 {
    margin-right: 1.7142857143rem;
  }
  .mgn-btm48 {
    margin-bottom: 1.7142857143rem;
  }
  .mgn-lft48 {
    margin-left: 1.7142857143rem;
  }
  .mgn-49 {
    margin: 1.75rem;
  }
  .mgn-top49 {
    margin-top: 1.75rem;
  }
  .mgn-rgt49 {
    margin-right: 1.75rem;
  }
  .mgn-btm49 {
    margin-bottom: 1.75rem;
  }
  .mgn-lft49 {
    margin-left: 1.75rem;
  }
  .mgn-50 {
    margin: 1.7857142857rem;
  }
  .mgn-top50 {
    margin-top: 1.7857142857rem;
  }
  .mgn-rgt50 {
    margin-right: 1.7857142857rem;
  }
  .mgn-btm50 {
    margin-bottom: 1.7857142857rem;
  }
  .mgn-lft50 {
    margin-left: 1.7857142857rem;
  }
  .mgn-51 {
    margin: 1.8214285714rem;
  }
  .mgn-top51 {
    margin-top: 1.8214285714rem;
  }
  .mgn-rgt51 {
    margin-right: 1.8214285714rem;
  }
  .mgn-btm51 {
    margin-bottom: 1.8214285714rem;
  }
  .mgn-lft51 {
    margin-left: 1.8214285714rem;
  }
  .mgn-52 {
    margin: 1.8571428571rem;
  }
  .mgn-top52 {
    margin-top: 1.8571428571rem;
  }
  .mgn-rgt52 {
    margin-right: 1.8571428571rem;
  }
  .mgn-btm52 {
    margin-bottom: 1.8571428571rem;
  }
  .mgn-lft52 {
    margin-left: 1.8571428571rem;
  }
  .mgn-53 {
    margin: 1.8928571429rem;
  }
  .mgn-top53 {
    margin-top: 1.8928571429rem;
  }
  .mgn-rgt53 {
    margin-right: 1.8928571429rem;
  }
  .mgn-btm53 {
    margin-bottom: 1.8928571429rem;
  }
  .mgn-lft53 {
    margin-left: 1.8928571429rem;
  }
  .mgn-54 {
    margin: 1.9285714286rem;
  }
  .mgn-top54 {
    margin-top: 1.9285714286rem;
  }
  .mgn-rgt54 {
    margin-right: 1.9285714286rem;
  }
  .mgn-btm54 {
    margin-bottom: 1.9285714286rem;
  }
  .mgn-lft54 {
    margin-left: 1.9285714286rem;
  }
  .mgn-55 {
    margin: 1.9642857143rem;
  }
  .mgn-top55 {
    margin-top: 1.9642857143rem;
  }
  .mgn-rgt55 {
    margin-right: 1.9642857143rem;
  }
  .mgn-btm55 {
    margin-bottom: 1.9642857143rem;
  }
  .mgn-lft55 {
    margin-left: 1.9642857143rem;
  }
  .mgn-56 {
    margin: 2rem;
  }
  .mgn-top56 {
    margin-top: 2rem;
  }
  .mgn-rgt56 {
    margin-right: 2rem;
  }
  .mgn-btm56 {
    margin-bottom: 2rem;
  }
  .mgn-lft56 {
    margin-left: 2rem;
  }
  .mgn-57 {
    margin: 2.0357142857rem;
  }
  .mgn-top57 {
    margin-top: 2.0357142857rem;
  }
  .mgn-rgt57 {
    margin-right: 2.0357142857rem;
  }
  .mgn-btm57 {
    margin-bottom: 2.0357142857rem;
  }
  .mgn-lft57 {
    margin-left: 2.0357142857rem;
  }
  .mgn-58 {
    margin: 2.0714285714rem;
  }
  .mgn-top58 {
    margin-top: 2.0714285714rem;
  }
  .mgn-rgt58 {
    margin-right: 2.0714285714rem;
  }
  .mgn-btm58 {
    margin-bottom: 2.0714285714rem;
  }
  .mgn-lft58 {
    margin-left: 2.0714285714rem;
  }
  .mgn-59 {
    margin: 2.1071428571rem;
  }
  .mgn-top59 {
    margin-top: 2.1071428571rem;
  }
  .mgn-rgt59 {
    margin-right: 2.1071428571rem;
  }
  .mgn-btm59 {
    margin-bottom: 2.1071428571rem;
  }
  .mgn-lft59 {
    margin-left: 2.1071428571rem;
  }
  .mgn-60 {
    margin: 2.1428571429rem;
  }
  .mgn-top60 {
    margin-top: 2.1428571429rem;
  }
  .mgn-rgt60 {
    margin-right: 2.1428571429rem;
  }
  .mgn-btm60 {
    margin-bottom: 2.1428571429rem;
  }
  .mgn-lft60 {
    margin-left: 2.1428571429rem;
  }
  .mgn-61 {
    margin: 2.1785714286rem;
  }
  .mgn-top61 {
    margin-top: 2.1785714286rem;
  }
  .mgn-rgt61 {
    margin-right: 2.1785714286rem;
  }
  .mgn-btm61 {
    margin-bottom: 2.1785714286rem;
  }
  .mgn-lft61 {
    margin-left: 2.1785714286rem;
  }
  .mgn-62 {
    margin: 2.2142857143rem;
  }
  .mgn-top62 {
    margin-top: 2.2142857143rem;
  }
  .mgn-rgt62 {
    margin-right: 2.2142857143rem;
  }
  .mgn-btm62 {
    margin-bottom: 2.2142857143rem;
  }
  .mgn-lft62 {
    margin-left: 2.2142857143rem;
  }
  .mgn-63 {
    margin: 2.25rem;
  }
  .mgn-top63 {
    margin-top: 2.25rem;
  }
  .mgn-rgt63 {
    margin-right: 2.25rem;
  }
  .mgn-btm63 {
    margin-bottom: 2.25rem;
  }
  .mgn-lft63 {
    margin-left: 2.25rem;
  }
  .mgn-64 {
    margin: 2.2857142857rem;
  }
  .mgn-top64 {
    margin-top: 2.2857142857rem;
  }
  .mgn-rgt64 {
    margin-right: 2.2857142857rem;
  }
  .mgn-btm64 {
    margin-bottom: 2.2857142857rem;
  }
  .mgn-lft64 {
    margin-left: 2.2857142857rem;
  }
  .mgn-65 {
    margin: 2.3214285714rem;
  }
  .mgn-top65 {
    margin-top: 2.3214285714rem;
  }
  .mgn-rgt65 {
    margin-right: 2.3214285714rem;
  }
  .mgn-btm65 {
    margin-bottom: 2.3214285714rem;
  }
  .mgn-lft65 {
    margin-left: 2.3214285714rem;
  }
  .mgn-66 {
    margin: 2.3571428571rem;
  }
  .mgn-top66 {
    margin-top: 2.3571428571rem;
  }
  .mgn-rgt66 {
    margin-right: 2.3571428571rem;
  }
  .mgn-btm66 {
    margin-bottom: 2.3571428571rem;
  }
  .mgn-lft66 {
    margin-left: 2.3571428571rem;
  }
  .mgn-67 {
    margin: 2.3928571429rem;
  }
  .mgn-top67 {
    margin-top: 2.3928571429rem;
  }
  .mgn-rgt67 {
    margin-right: 2.3928571429rem;
  }
  .mgn-btm67 {
    margin-bottom: 2.3928571429rem;
  }
  .mgn-lft67 {
    margin-left: 2.3928571429rem;
  }
  .mgn-68 {
    margin: 2.4285714286rem;
  }
  .mgn-top68 {
    margin-top: 2.4285714286rem;
  }
  .mgn-rgt68 {
    margin-right: 2.4285714286rem;
  }
  .mgn-btm68 {
    margin-bottom: 2.4285714286rem;
  }
  .mgn-lft68 {
    margin-left: 2.4285714286rem;
  }
  .mgn-69 {
    margin: 2.4642857143rem;
  }
  .mgn-top69 {
    margin-top: 2.4642857143rem;
  }
  .mgn-rgt69 {
    margin-right: 2.4642857143rem;
  }
  .mgn-btm69 {
    margin-bottom: 2.4642857143rem;
  }
  .mgn-lft69 {
    margin-left: 2.4642857143rem;
  }
  .mgn-70 {
    margin: 2.5rem;
  }
  .mgn-top70 {
    margin-top: 2.5rem;
  }
  .mgn-rgt70 {
    margin-right: 2.5rem;
  }
  .mgn-btm70 {
    margin-bottom: 2.5rem;
  }
  .mgn-lft70 {
    margin-left: 2.5rem;
  }
  .mgn-71 {
    margin: 2.5357142857rem;
  }
  .mgn-top71 {
    margin-top: 2.5357142857rem;
  }
  .mgn-rgt71 {
    margin-right: 2.5357142857rem;
  }
  .mgn-btm71 {
    margin-bottom: 2.5357142857rem;
  }
  .mgn-lft71 {
    margin-left: 2.5357142857rem;
  }
  .mgn-72 {
    margin: 2.5714285714rem;
  }
  .mgn-top72 {
    margin-top: 2.5714285714rem;
  }
  .mgn-rgt72 {
    margin-right: 2.5714285714rem;
  }
  .mgn-btm72 {
    margin-bottom: 2.5714285714rem;
  }
  .mgn-lft72 {
    margin-left: 2.5714285714rem;
  }
  .mgn-73 {
    margin: 2.6071428571rem;
  }
  .mgn-top73 {
    margin-top: 2.6071428571rem;
  }
  .mgn-rgt73 {
    margin-right: 2.6071428571rem;
  }
  .mgn-btm73 {
    margin-bottom: 2.6071428571rem;
  }
  .mgn-lft73 {
    margin-left: 2.6071428571rem;
  }
  .mgn-74 {
    margin: 2.6428571429rem;
  }
  .mgn-top74 {
    margin-top: 2.6428571429rem;
  }
  .mgn-rgt74 {
    margin-right: 2.6428571429rem;
  }
  .mgn-btm74 {
    margin-bottom: 2.6428571429rem;
  }
  .mgn-lft74 {
    margin-left: 2.6428571429rem;
  }
  .mgn-75 {
    margin: 2.6785714286rem;
  }
  .mgn-top75 {
    margin-top: 2.6785714286rem;
  }
  .mgn-rgt75 {
    margin-right: 2.6785714286rem;
  }
  .mgn-btm75 {
    margin-bottom: 2.6785714286rem;
  }
  .mgn-lft75 {
    margin-left: 2.6785714286rem;
  }
  .mgn-76 {
    margin: 2.7142857143rem;
  }
  .mgn-top76 {
    margin-top: 2.7142857143rem;
  }
  .mgn-rgt76 {
    margin-right: 2.7142857143rem;
  }
  .mgn-btm76 {
    margin-bottom: 2.7142857143rem;
  }
  .mgn-lft76 {
    margin-left: 2.7142857143rem;
  }
  .mgn-77 {
    margin: 2.75rem;
  }
  .mgn-top77 {
    margin-top: 2.75rem;
  }
  .mgn-rgt77 {
    margin-right: 2.75rem;
  }
  .mgn-btm77 {
    margin-bottom: 2.75rem;
  }
  .mgn-lft77 {
    margin-left: 2.75rem;
  }
  .mgn-78 {
    margin: 2.7857142857rem;
  }
  .mgn-top78 {
    margin-top: 2.7857142857rem;
  }
  .mgn-rgt78 {
    margin-right: 2.7857142857rem;
  }
  .mgn-btm78 {
    margin-bottom: 2.7857142857rem;
  }
  .mgn-lft78 {
    margin-left: 2.7857142857rem;
  }
  .mgn-79 {
    margin: 2.8214285714rem;
  }
  .mgn-top79 {
    margin-top: 2.8214285714rem;
  }
  .mgn-rgt79 {
    margin-right: 2.8214285714rem;
  }
  .mgn-btm79 {
    margin-bottom: 2.8214285714rem;
  }
  .mgn-lft79 {
    margin-left: 2.8214285714rem;
  }
  .mgn-80 {
    margin: 2.8571428571rem;
  }
  .mgn-top80 {
    margin-top: 2.8571428571rem;
  }
  .mgn-rgt80 {
    margin-right: 2.8571428571rem;
  }
  .mgn-btm80 {
    margin-bottom: 2.8571428571rem;
  }
  .mgn-lft80 {
    margin-left: 2.8571428571rem;
  }
  .mgn-81 {
    margin: 2.8928571429rem;
  }
  .mgn-top81 {
    margin-top: 2.8928571429rem;
  }
  .mgn-rgt81 {
    margin-right: 2.8928571429rem;
  }
  .mgn-btm81 {
    margin-bottom: 2.8928571429rem;
  }
  .mgn-lft81 {
    margin-left: 2.8928571429rem;
  }
  .mgn-82 {
    margin: 2.9285714286rem;
  }
  .mgn-top82 {
    margin-top: 2.9285714286rem;
  }
  .mgn-rgt82 {
    margin-right: 2.9285714286rem;
  }
  .mgn-btm82 {
    margin-bottom: 2.9285714286rem;
  }
  .mgn-lft82 {
    margin-left: 2.9285714286rem;
  }
  .mgn-83 {
    margin: 2.9642857143rem;
  }
  .mgn-top83 {
    margin-top: 2.9642857143rem;
  }
  .mgn-rgt83 {
    margin-right: 2.9642857143rem;
  }
  .mgn-btm83 {
    margin-bottom: 2.9642857143rem;
  }
  .mgn-lft83 {
    margin-left: 2.9642857143rem;
  }
  .mgn-84 {
    margin: 3rem;
  }
  .mgn-top84 {
    margin-top: 3rem;
  }
  .mgn-rgt84 {
    margin-right: 3rem;
  }
  .mgn-btm84 {
    margin-bottom: 3rem;
  }
  .mgn-lft84 {
    margin-left: 3rem;
  }
  .mgn-85 {
    margin: 3.0357142857rem;
  }
  .mgn-top85 {
    margin-top: 3.0357142857rem;
  }
  .mgn-rgt85 {
    margin-right: 3.0357142857rem;
  }
  .mgn-btm85 {
    margin-bottom: 3.0357142857rem;
  }
  .mgn-lft85 {
    margin-left: 3.0357142857rem;
  }
  .mgn-86 {
    margin: 3.0714285714rem;
  }
  .mgn-top86 {
    margin-top: 3.0714285714rem;
  }
  .mgn-rgt86 {
    margin-right: 3.0714285714rem;
  }
  .mgn-btm86 {
    margin-bottom: 3.0714285714rem;
  }
  .mgn-lft86 {
    margin-left: 3.0714285714rem;
  }
  .mgn-87 {
    margin: 3.1071428571rem;
  }
  .mgn-top87 {
    margin-top: 3.1071428571rem;
  }
  .mgn-rgt87 {
    margin-right: 3.1071428571rem;
  }
  .mgn-btm87 {
    margin-bottom: 3.1071428571rem;
  }
  .mgn-lft87 {
    margin-left: 3.1071428571rem;
  }
  .mgn-88 {
    margin: 3.1428571429rem;
  }
  .mgn-top88 {
    margin-top: 3.1428571429rem;
  }
  .mgn-rgt88 {
    margin-right: 3.1428571429rem;
  }
  .mgn-btm88 {
    margin-bottom: 3.1428571429rem;
  }
  .mgn-lft88 {
    margin-left: 3.1428571429rem;
  }
  .mgn-89 {
    margin: 3.1785714286rem;
  }
  .mgn-top89 {
    margin-top: 3.1785714286rem;
  }
  .mgn-rgt89 {
    margin-right: 3.1785714286rem;
  }
  .mgn-btm89 {
    margin-bottom: 3.1785714286rem;
  }
  .mgn-lft89 {
    margin-left: 3.1785714286rem;
  }
  .mgn-90 {
    margin: 3.2142857143rem;
  }
  .mgn-top90 {
    margin-top: 3.2142857143rem;
  }
  .mgn-rgt90 {
    margin-right: 3.2142857143rem;
  }
  .mgn-btm90 {
    margin-bottom: 3.2142857143rem;
  }
  .mgn-lft90 {
    margin-left: 3.2142857143rem;
  }
  .mgn-91 {
    margin: 3.25rem;
  }
  .mgn-top91 {
    margin-top: 3.25rem;
  }
  .mgn-rgt91 {
    margin-right: 3.25rem;
  }
  .mgn-btm91 {
    margin-bottom: 3.25rem;
  }
  .mgn-lft91 {
    margin-left: 3.25rem;
  }
  .mgn-92 {
    margin: 3.2857142857rem;
  }
  .mgn-top92 {
    margin-top: 3.2857142857rem;
  }
  .mgn-rgt92 {
    margin-right: 3.2857142857rem;
  }
  .mgn-btm92 {
    margin-bottom: 3.2857142857rem;
  }
  .mgn-lft92 {
    margin-left: 3.2857142857rem;
  }
  .mgn-93 {
    margin: 3.3214285714rem;
  }
  .mgn-top93 {
    margin-top: 3.3214285714rem;
  }
  .mgn-rgt93 {
    margin-right: 3.3214285714rem;
  }
  .mgn-btm93 {
    margin-bottom: 3.3214285714rem;
  }
  .mgn-lft93 {
    margin-left: 3.3214285714rem;
  }
  .mgn-94 {
    margin: 3.3571428571rem;
  }
  .mgn-top94 {
    margin-top: 3.3571428571rem;
  }
  .mgn-rgt94 {
    margin-right: 3.3571428571rem;
  }
  .mgn-btm94 {
    margin-bottom: 3.3571428571rem;
  }
  .mgn-lft94 {
    margin-left: 3.3571428571rem;
  }
  .mgn-95 {
    margin: 3.3928571429rem;
  }
  .mgn-top95 {
    margin-top: 3.3928571429rem;
  }
  .mgn-rgt95 {
    margin-right: 3.3928571429rem;
  }
  .mgn-btm95 {
    margin-bottom: 3.3928571429rem;
  }
  .mgn-lft95 {
    margin-left: 3.3928571429rem;
  }
  .mgn-96 {
    margin: 3.4285714286rem;
  }
  .mgn-top96 {
    margin-top: 3.4285714286rem;
  }
  .mgn-rgt96 {
    margin-right: 3.4285714286rem;
  }
  .mgn-btm96 {
    margin-bottom: 3.4285714286rem;
  }
  .mgn-lft96 {
    margin-left: 3.4285714286rem;
  }
  .mgn-97 {
    margin: 3.4642857143rem;
  }
  .mgn-top97 {
    margin-top: 3.4642857143rem;
  }
  .mgn-rgt97 {
    margin-right: 3.4642857143rem;
  }
  .mgn-btm97 {
    margin-bottom: 3.4642857143rem;
  }
  .mgn-lft97 {
    margin-left: 3.4642857143rem;
  }
  .mgn-98 {
    margin: 3.5rem;
  }
  .mgn-top98 {
    margin-top: 3.5rem;
  }
  .mgn-rgt98 {
    margin-right: 3.5rem;
  }
  .mgn-btm98 {
    margin-bottom: 3.5rem;
  }
  .mgn-lft98 {
    margin-left: 3.5rem;
  }
  .mgn-99 {
    margin: 3.5357142857rem;
  }
  .mgn-top99 {
    margin-top: 3.5357142857rem;
  }
  .mgn-rgt99 {
    margin-right: 3.5357142857rem;
  }
  .mgn-btm99 {
    margin-bottom: 3.5357142857rem;
  }
  .mgn-lft99 {
    margin-left: 3.5357142857rem;
  }
  .mgn-100 {
    margin: 3.5714285714rem;
  }
  .mgn-top100 {
    margin-top: 3.5714285714rem;
  }
  .mgn-rgt100 {
    margin-right: 3.5714285714rem;
  }
  .mgn-btm100 {
    margin-bottom: 3.5714285714rem;
  }
  .mgn-lft100 {
    margin-left: 3.5714285714rem;
  }
  .mgn-101 {
    margin: 3.6071428571rem;
  }
  .mgn-top101 {
    margin-top: 3.6071428571rem;
  }
  .mgn-rgt101 {
    margin-right: 3.6071428571rem;
  }
  .mgn-btm101 {
    margin-bottom: 3.6071428571rem;
  }
  .mgn-lft101 {
    margin-left: 3.6071428571rem;
  }
  .mgn-102 {
    margin: 3.6428571429rem;
  }
  .mgn-top102 {
    margin-top: 3.6428571429rem;
  }
  .mgn-rgt102 {
    margin-right: 3.6428571429rem;
  }
  .mgn-btm102 {
    margin-bottom: 3.6428571429rem;
  }
  .mgn-lft102 {
    margin-left: 3.6428571429rem;
  }
  .mgn-103 {
    margin: 3.6785714286rem;
  }
  .mgn-top103 {
    margin-top: 3.6785714286rem;
  }
  .mgn-rgt103 {
    margin-right: 3.6785714286rem;
  }
  .mgn-btm103 {
    margin-bottom: 3.6785714286rem;
  }
  .mgn-lft103 {
    margin-left: 3.6785714286rem;
  }
  .mgn-104 {
    margin: 3.7142857143rem;
  }
  .mgn-top104 {
    margin-top: 3.7142857143rem;
  }
  .mgn-rgt104 {
    margin-right: 3.7142857143rem;
  }
  .mgn-btm104 {
    margin-bottom: 3.7142857143rem;
  }
  .mgn-lft104 {
    margin-left: 3.7142857143rem;
  }
  .mgn-105 {
    margin: 3.75rem;
  }
  .mgn-top105 {
    margin-top: 3.75rem;
  }
  .mgn-rgt105 {
    margin-right: 3.75rem;
  }
  .mgn-btm105 {
    margin-bottom: 3.75rem;
  }
  .mgn-lft105 {
    margin-left: 3.75rem;
  }
  .mgn-106 {
    margin: 3.7857142857rem;
  }
  .mgn-top106 {
    margin-top: 3.7857142857rem;
  }
  .mgn-rgt106 {
    margin-right: 3.7857142857rem;
  }
  .mgn-btm106 {
    margin-bottom: 3.7857142857rem;
  }
  .mgn-lft106 {
    margin-left: 3.7857142857rem;
  }
  .mgn-107 {
    margin: 3.8214285714rem;
  }
  .mgn-top107 {
    margin-top: 3.8214285714rem;
  }
  .mgn-rgt107 {
    margin-right: 3.8214285714rem;
  }
  .mgn-btm107 {
    margin-bottom: 3.8214285714rem;
  }
  .mgn-lft107 {
    margin-left: 3.8214285714rem;
  }
  .mgn-108 {
    margin: 3.8571428571rem;
  }
  .mgn-top108 {
    margin-top: 3.8571428571rem;
  }
  .mgn-rgt108 {
    margin-right: 3.8571428571rem;
  }
  .mgn-btm108 {
    margin-bottom: 3.8571428571rem;
  }
  .mgn-lft108 {
    margin-left: 3.8571428571rem;
  }
  .mgn-109 {
    margin: 3.8928571429rem;
  }
  .mgn-top109 {
    margin-top: 3.8928571429rem;
  }
  .mgn-rgt109 {
    margin-right: 3.8928571429rem;
  }
  .mgn-btm109 {
    margin-bottom: 3.8928571429rem;
  }
  .mgn-lft109 {
    margin-left: 3.8928571429rem;
  }
  .mgn-110 {
    margin: 3.9285714286rem;
  }
  .mgn-top110 {
    margin-top: 3.9285714286rem;
  }
  .mgn-rgt110 {
    margin-right: 3.9285714286rem;
  }
  .mgn-btm110 {
    margin-bottom: 3.9285714286rem;
  }
  .mgn-lft110 {
    margin-left: 3.9285714286rem;
  }
  .mgn-111 {
    margin: 3.9642857143rem;
  }
  .mgn-top111 {
    margin-top: 3.9642857143rem;
  }
  .mgn-rgt111 {
    margin-right: 3.9642857143rem;
  }
  .mgn-btm111 {
    margin-bottom: 3.9642857143rem;
  }
  .mgn-lft111 {
    margin-left: 3.9642857143rem;
  }
  .mgn-112 {
    margin: 4rem;
  }
  .mgn-top112 {
    margin-top: 4rem;
  }
  .mgn-rgt112 {
    margin-right: 4rem;
  }
  .mgn-btm112 {
    margin-bottom: 4rem;
  }
  .mgn-lft112 {
    margin-left: 4rem;
  }
  .mgn-113 {
    margin: 4.0357142857rem;
  }
  .mgn-top113 {
    margin-top: 4.0357142857rem;
  }
  .mgn-rgt113 {
    margin-right: 4.0357142857rem;
  }
  .mgn-btm113 {
    margin-bottom: 4.0357142857rem;
  }
  .mgn-lft113 {
    margin-left: 4.0357142857rem;
  }
  .mgn-114 {
    margin: 4.0714285714rem;
  }
  .mgn-top114 {
    margin-top: 4.0714285714rem;
  }
  .mgn-rgt114 {
    margin-right: 4.0714285714rem;
  }
  .mgn-btm114 {
    margin-bottom: 4.0714285714rem;
  }
  .mgn-lft114 {
    margin-left: 4.0714285714rem;
  }
  .mgn-115 {
    margin: 4.1071428571rem;
  }
  .mgn-top115 {
    margin-top: 4.1071428571rem;
  }
  .mgn-rgt115 {
    margin-right: 4.1071428571rem;
  }
  .mgn-btm115 {
    margin-bottom: 4.1071428571rem;
  }
  .mgn-lft115 {
    margin-left: 4.1071428571rem;
  }
  .mgn-116 {
    margin: 4.1428571429rem;
  }
  .mgn-top116 {
    margin-top: 4.1428571429rem;
  }
  .mgn-rgt116 {
    margin-right: 4.1428571429rem;
  }
  .mgn-btm116 {
    margin-bottom: 4.1428571429rem;
  }
  .mgn-lft116 {
    margin-left: 4.1428571429rem;
  }
  .mgn-117 {
    margin: 4.1785714286rem;
  }
  .mgn-top117 {
    margin-top: 4.1785714286rem;
  }
  .mgn-rgt117 {
    margin-right: 4.1785714286rem;
  }
  .mgn-btm117 {
    margin-bottom: 4.1785714286rem;
  }
  .mgn-lft117 {
    margin-left: 4.1785714286rem;
  }
  .mgn-118 {
    margin: 4.2142857143rem;
  }
  .mgn-top118 {
    margin-top: 4.2142857143rem;
  }
  .mgn-rgt118 {
    margin-right: 4.2142857143rem;
  }
  .mgn-btm118 {
    margin-bottom: 4.2142857143rem;
  }
  .mgn-lft118 {
    margin-left: 4.2142857143rem;
  }
  .mgn-119 {
    margin: 4.25rem;
  }
  .mgn-top119 {
    margin-top: 4.25rem;
  }
  .mgn-rgt119 {
    margin-right: 4.25rem;
  }
  .mgn-btm119 {
    margin-bottom: 4.25rem;
  }
  .mgn-lft119 {
    margin-left: 4.25rem;
  }
  .mgn-120 {
    margin: 4.2857142857rem;
  }
  .mgn-top120 {
    margin-top: 4.2857142857rem;
  }
  .mgn-rgt120 {
    margin-right: 4.2857142857rem;
  }
  .mgn-btm120 {
    margin-bottom: 4.2857142857rem;
  }
  .mgn-lft120 {
    margin-left: 4.2857142857rem;
  }
  .mgn-121 {
    margin: 4.3214285714rem;
  }
  .mgn-top121 {
    margin-top: 4.3214285714rem;
  }
  .mgn-rgt121 {
    margin-right: 4.3214285714rem;
  }
  .mgn-btm121 {
    margin-bottom: 4.3214285714rem;
  }
  .mgn-lft121 {
    margin-left: 4.3214285714rem;
  }
  .mgn-122 {
    margin: 4.3571428571rem;
  }
  .mgn-top122 {
    margin-top: 4.3571428571rem;
  }
  .mgn-rgt122 {
    margin-right: 4.3571428571rem;
  }
  .mgn-btm122 {
    margin-bottom: 4.3571428571rem;
  }
  .mgn-lft122 {
    margin-left: 4.3571428571rem;
  }
  .mgn-123 {
    margin: 4.3928571429rem;
  }
  .mgn-top123 {
    margin-top: 4.3928571429rem;
  }
  .mgn-rgt123 {
    margin-right: 4.3928571429rem;
  }
  .mgn-btm123 {
    margin-bottom: 4.3928571429rem;
  }
  .mgn-lft123 {
    margin-left: 4.3928571429rem;
  }
  .mgn-124 {
    margin: 4.4285714286rem;
  }
  .mgn-top124 {
    margin-top: 4.4285714286rem;
  }
  .mgn-rgt124 {
    margin-right: 4.4285714286rem;
  }
  .mgn-btm124 {
    margin-bottom: 4.4285714286rem;
  }
  .mgn-lft124 {
    margin-left: 4.4285714286rem;
  }
  .mgn-125 {
    margin: 4.4642857143rem;
  }
  .mgn-top125 {
    margin-top: 4.4642857143rem;
  }
  .mgn-rgt125 {
    margin-right: 4.4642857143rem;
  }
  .mgn-btm125 {
    margin-bottom: 4.4642857143rem;
  }
  .mgn-lft125 {
    margin-left: 4.4642857143rem;
  }
  .mgn-126 {
    margin: 4.5rem;
  }
  .mgn-top126 {
    margin-top: 4.5rem;
  }
  .mgn-rgt126 {
    margin-right: 4.5rem;
  }
  .mgn-btm126 {
    margin-bottom: 4.5rem;
  }
  .mgn-lft126 {
    margin-left: 4.5rem;
  }
  .mgn-127 {
    margin: 4.5357142857rem;
  }
  .mgn-top127 {
    margin-top: 4.5357142857rem;
  }
  .mgn-rgt127 {
    margin-right: 4.5357142857rem;
  }
  .mgn-btm127 {
    margin-bottom: 4.5357142857rem;
  }
  .mgn-lft127 {
    margin-left: 4.5357142857rem;
  }
  .mgn-128 {
    margin: 4.5714285714rem;
  }
  .mgn-top128 {
    margin-top: 4.5714285714rem;
  }
  .mgn-rgt128 {
    margin-right: 4.5714285714rem;
  }
  .mgn-btm128 {
    margin-bottom: 4.5714285714rem;
  }
  .mgn-lft128 {
    margin-left: 4.5714285714rem;
  }
  .mgn-129 {
    margin: 4.6071428571rem;
  }
  .mgn-top129 {
    margin-top: 4.6071428571rem;
  }
  .mgn-rgt129 {
    margin-right: 4.6071428571rem;
  }
  .mgn-btm129 {
    margin-bottom: 4.6071428571rem;
  }
  .mgn-lft129 {
    margin-left: 4.6071428571rem;
  }
  .mgn-130 {
    margin: 4.6428571429rem;
  }
  .mgn-top130 {
    margin-top: 4.6428571429rem;
  }
  .mgn-rgt130 {
    margin-right: 4.6428571429rem;
  }
  .mgn-btm130 {
    margin-bottom: 4.6428571429rem;
  }
  .mgn-lft130 {
    margin-left: 4.6428571429rem;
  }
  .mgn-131 {
    margin: 4.6785714286rem;
  }
  .mgn-top131 {
    margin-top: 4.6785714286rem;
  }
  .mgn-rgt131 {
    margin-right: 4.6785714286rem;
  }
  .mgn-btm131 {
    margin-bottom: 4.6785714286rem;
  }
  .mgn-lft131 {
    margin-left: 4.6785714286rem;
  }
  .mgn-132 {
    margin: 4.7142857143rem;
  }
  .mgn-top132 {
    margin-top: 4.7142857143rem;
  }
  .mgn-rgt132 {
    margin-right: 4.7142857143rem;
  }
  .mgn-btm132 {
    margin-bottom: 4.7142857143rem;
  }
  .mgn-lft132 {
    margin-left: 4.7142857143rem;
  }
  .mgn-133 {
    margin: 4.75rem;
  }
  .mgn-top133 {
    margin-top: 4.75rem;
  }
  .mgn-rgt133 {
    margin-right: 4.75rem;
  }
  .mgn-btm133 {
    margin-bottom: 4.75rem;
  }
  .mgn-lft133 {
    margin-left: 4.75rem;
  }
  .mgn-134 {
    margin: 4.7857142857rem;
  }
  .mgn-top134 {
    margin-top: 4.7857142857rem;
  }
  .mgn-rgt134 {
    margin-right: 4.7857142857rem;
  }
  .mgn-btm134 {
    margin-bottom: 4.7857142857rem;
  }
  .mgn-lft134 {
    margin-left: 4.7857142857rem;
  }
  .mgn-135 {
    margin: 4.8214285714rem;
  }
  .mgn-top135 {
    margin-top: 4.8214285714rem;
  }
  .mgn-rgt135 {
    margin-right: 4.8214285714rem;
  }
  .mgn-btm135 {
    margin-bottom: 4.8214285714rem;
  }
  .mgn-lft135 {
    margin-left: 4.8214285714rem;
  }
  .mgn-136 {
    margin: 4.8571428571rem;
  }
  .mgn-top136 {
    margin-top: 4.8571428571rem;
  }
  .mgn-rgt136 {
    margin-right: 4.8571428571rem;
  }
  .mgn-btm136 {
    margin-bottom: 4.8571428571rem;
  }
  .mgn-lft136 {
    margin-left: 4.8571428571rem;
  }
  .mgn-137 {
    margin: 4.8928571429rem;
  }
  .mgn-top137 {
    margin-top: 4.8928571429rem;
  }
  .mgn-rgt137 {
    margin-right: 4.8928571429rem;
  }
  .mgn-btm137 {
    margin-bottom: 4.8928571429rem;
  }
  .mgn-lft137 {
    margin-left: 4.8928571429rem;
  }
  .mgn-138 {
    margin: 4.9285714286rem;
  }
  .mgn-top138 {
    margin-top: 4.9285714286rem;
  }
  .mgn-rgt138 {
    margin-right: 4.9285714286rem;
  }
  .mgn-btm138 {
    margin-bottom: 4.9285714286rem;
  }
  .mgn-lft138 {
    margin-left: 4.9285714286rem;
  }
  .mgn-139 {
    margin: 4.9642857143rem;
  }
  .mgn-top139 {
    margin-top: 4.9642857143rem;
  }
  .mgn-rgt139 {
    margin-right: 4.9642857143rem;
  }
  .mgn-btm139 {
    margin-bottom: 4.9642857143rem;
  }
  .mgn-lft139 {
    margin-left: 4.9642857143rem;
  }
  .mgn-140 {
    margin: 5rem;
  }
  .mgn-top140 {
    margin-top: 5rem;
  }
  .mgn-rgt140 {
    margin-right: 5rem;
  }
  .mgn-btm140 {
    margin-bottom: 5rem;
  }
  .mgn-lft140 {
    margin-left: 5rem;
  }
  .mgn-141 {
    margin: 5.0357142857rem;
  }
  .mgn-top141 {
    margin-top: 5.0357142857rem;
  }
  .mgn-rgt141 {
    margin-right: 5.0357142857rem;
  }
  .mgn-btm141 {
    margin-bottom: 5.0357142857rem;
  }
  .mgn-lft141 {
    margin-left: 5.0357142857rem;
  }
  .mgn-142 {
    margin: 5.0714285714rem;
  }
  .mgn-top142 {
    margin-top: 5.0714285714rem;
  }
  .mgn-rgt142 {
    margin-right: 5.0714285714rem;
  }
  .mgn-btm142 {
    margin-bottom: 5.0714285714rem;
  }
  .mgn-lft142 {
    margin-left: 5.0714285714rem;
  }
  .mgn-143 {
    margin: 5.1071428571rem;
  }
  .mgn-top143 {
    margin-top: 5.1071428571rem;
  }
  .mgn-rgt143 {
    margin-right: 5.1071428571rem;
  }
  .mgn-btm143 {
    margin-bottom: 5.1071428571rem;
  }
  .mgn-lft143 {
    margin-left: 5.1071428571rem;
  }
  .mgn-144 {
    margin: 5.1428571429rem;
  }
  .mgn-top144 {
    margin-top: 5.1428571429rem;
  }
  .mgn-rgt144 {
    margin-right: 5.1428571429rem;
  }
  .mgn-btm144 {
    margin-bottom: 5.1428571429rem;
  }
  .mgn-lft144 {
    margin-left: 5.1428571429rem;
  }
  .mgn-145 {
    margin: 5.1785714286rem;
  }
  .mgn-top145 {
    margin-top: 5.1785714286rem;
  }
  .mgn-rgt145 {
    margin-right: 5.1785714286rem;
  }
  .mgn-btm145 {
    margin-bottom: 5.1785714286rem;
  }
  .mgn-lft145 {
    margin-left: 5.1785714286rem;
  }
  .mgn-146 {
    margin: 5.2142857143rem;
  }
  .mgn-top146 {
    margin-top: 5.2142857143rem;
  }
  .mgn-rgt146 {
    margin-right: 5.2142857143rem;
  }
  .mgn-btm146 {
    margin-bottom: 5.2142857143rem;
  }
  .mgn-lft146 {
    margin-left: 5.2142857143rem;
  }
  .mgn-147 {
    margin: 5.25rem;
  }
  .mgn-top147 {
    margin-top: 5.25rem;
  }
  .mgn-rgt147 {
    margin-right: 5.25rem;
  }
  .mgn-btm147 {
    margin-bottom: 5.25rem;
  }
  .mgn-lft147 {
    margin-left: 5.25rem;
  }
  .mgn-148 {
    margin: 5.2857142857rem;
  }
  .mgn-top148 {
    margin-top: 5.2857142857rem;
  }
  .mgn-rgt148 {
    margin-right: 5.2857142857rem;
  }
  .mgn-btm148 {
    margin-bottom: 5.2857142857rem;
  }
  .mgn-lft148 {
    margin-left: 5.2857142857rem;
  }
  .mgn-149 {
    margin: 5.3214285714rem;
  }
  .mgn-top149 {
    margin-top: 5.3214285714rem;
  }
  .mgn-rgt149 {
    margin-right: 5.3214285714rem;
  }
  .mgn-btm149 {
    margin-bottom: 5.3214285714rem;
  }
  .mgn-lft149 {
    margin-left: 5.3214285714rem;
  }
  .mgn-150 {
    margin: 5.3571428571rem;
  }
  .mgn-top150 {
    margin-top: 5.3571428571rem;
  }
  .mgn-rgt150 {
    margin-right: 5.3571428571rem;
  }
  .mgn-btm150 {
    margin-bottom: 5.3571428571rem;
  }
  .mgn-lft150 {
    margin-left: 5.3571428571rem;
  }
}
.mgn-0_em {
  margin: 0em;
}

.mgn-top0_em {
  margin-top: 0em;
}

.mgn-rgt0_em {
  margin-right: 0em;
}

.mgn-btm0_em {
  margin-bottom: 0em;
}

.mgn-lft0_em {
  margin-left: 0em;
}

.mgn-1_em {
  margin: 1em;
}

.mgn-top1_em {
  margin-top: 1em;
}

.mgn-rgt1_em {
  margin-right: 1em;
}

.mgn-btm1_em {
  margin-bottom: 1em;
}

.mgn-lft1_em {
  margin-left: 1em;
}

.mgn-2_em {
  margin: 2em;
}

.mgn-top2_em {
  margin-top: 2em;
}

.mgn-rgt2_em {
  margin-right: 2em;
}

.mgn-btm2_em {
  margin-bottom: 2em;
}

.mgn-lft2_em {
  margin-left: 2em;
}

.mgn-3_em {
  margin: 3em;
}

.mgn-top3_em {
  margin-top: 3em;
}

.mgn-rgt3_em {
  margin-right: 3em;
}

.mgn-btm3_em {
  margin-bottom: 3em;
}

.mgn-lft3_em {
  margin-left: 3em;
}

.mgn-4_em {
  margin: 4em;
}

.mgn-top4_em {
  margin-top: 4em;
}

.mgn-rgt4_em {
  margin-right: 4em;
}

.mgn-btm4_em {
  margin-bottom: 4em;
}

.mgn-lft4_em {
  margin-left: 4em;
}

.mgn-5_em {
  margin: 5em;
}

.mgn-top5_em {
  margin-top: 5em;
}

.mgn-rgt5_em {
  margin-right: 5em;
}

.mgn-btm5_em {
  margin-bottom: 5em;
}

.mgn-lft5_em {
  margin-left: 5em;
}

.mgn-6_em {
  margin: 6em;
}

.mgn-top6_em {
  margin-top: 6em;
}

.mgn-rgt6_em {
  margin-right: 6em;
}

.mgn-btm6_em {
  margin-bottom: 6em;
}

.mgn-lft6_em {
  margin-left: 6em;
}

.mgn-7_em {
  margin: 7em;
}

.mgn-top7_em {
  margin-top: 7em;
}

.mgn-rgt7_em {
  margin-right: 7em;
}

.mgn-btm7_em {
  margin-bottom: 7em;
}

.mgn-lft7_em {
  margin-left: 7em;
}

.mgn-8_em {
  margin: 8em;
}

.mgn-top8_em {
  margin-top: 8em;
}

.mgn-rgt8_em {
  margin-right: 8em;
}

.mgn-btm8_em {
  margin-bottom: 8em;
}

.mgn-lft8_em {
  margin-left: 8em;
}

.mgn-9_em {
  margin: 9em;
}

.mgn-top9_em {
  margin-top: 9em;
}

.mgn-rgt9_em {
  margin-right: 9em;
}

.mgn-btm9_em {
  margin-bottom: 9em;
}

.mgn-lft9_em {
  margin-left: 9em;
}

.mgn-10_em {
  margin: 10em;
}

.mgn-top10_em {
  margin-top: 10em;
}

.mgn-rgt10_em {
  margin-right: 10em;
}

.mgn-btm10_em {
  margin-bottom: 10em;
}

.mgn-lft10_em {
  margin-left: 10em;
}

.mgn-11_em {
  margin: 11em;
}

.mgn-top11_em {
  margin-top: 11em;
}

.mgn-rgt11_em {
  margin-right: 11em;
}

.mgn-btm11_em {
  margin-bottom: 11em;
}

.mgn-lft11_em {
  margin-left: 11em;
}

.mgn-12_em {
  margin: 12em;
}

.mgn-top12_em {
  margin-top: 12em;
}

.mgn-rgt12_em {
  margin-right: 12em;
}

.mgn-btm12_em {
  margin-bottom: 12em;
}

.mgn-lft12_em {
  margin-left: 12em;
}

.mgn-13_em {
  margin: 13em;
}

.mgn-top13_em {
  margin-top: 13em;
}

.mgn-rgt13_em {
  margin-right: 13em;
}

.mgn-btm13_em {
  margin-bottom: 13em;
}

.mgn-lft13_em {
  margin-left: 13em;
}

.mgn-14_em {
  margin: 14em;
}

.mgn-top14_em {
  margin-top: 14em;
}

.mgn-rgt14_em {
  margin-right: 14em;
}

.mgn-btm14_em {
  margin-bottom: 14em;
}

.mgn-lft14_em {
  margin-left: 14em;
}

.mgn-15_em {
  margin: 15em;
}

.mgn-top15_em {
  margin-top: 15em;
}

.mgn-rgt15_em {
  margin-right: 15em;
}

.mgn-btm15_em {
  margin-bottom: 15em;
}

.mgn-lft15_em {
  margin-left: 15em;
}

.pdg-0 {
  padding: 0rem;
}

.pdg-top0 {
  padding-top: 0rem;
}

.pdg-rgt0 {
  padding-right: 0rem;
}

.pdg-btm0 {
  padding-bottom: 0rem;
}

.pdg-lft0 {
  padding-left: 0rem;
}

.pdg-1 {
  padding: 0.0625rem;
}

.pdg-top1 {
  padding-top: 0.0625rem;
}

.pdg-rgt1 {
  padding-right: 0.0625rem;
}

.pdg-btm1 {
  padding-bottom: 0.0625rem;
}

.pdg-lft1 {
  padding-left: 0.0625rem;
}

.pdg-2 {
  padding: 0.125rem;
}

.pdg-top2 {
  padding-top: 0.125rem;
}

.pdg-rgt2 {
  padding-right: 0.125rem;
}

.pdg-btm2 {
  padding-bottom: 0.125rem;
}

.pdg-lft2 {
  padding-left: 0.125rem;
}

.pdg-3 {
  padding: 0.1875rem;
}

.pdg-top3 {
  padding-top: 0.1875rem;
}

.pdg-rgt3 {
  padding-right: 0.1875rem;
}

.pdg-btm3 {
  padding-bottom: 0.1875rem;
}

.pdg-lft3 {
  padding-left: 0.1875rem;
}

.pdg-4 {
  padding: 0.25rem;
}

.pdg-top4 {
  padding-top: 0.25rem;
}

.pdg-rgt4 {
  padding-right: 0.25rem;
}

.pdg-btm4 {
  padding-bottom: 0.25rem;
}

.pdg-lft4 {
  padding-left: 0.25rem;
}

.pdg-5 {
  padding: 0.3125rem;
}

.pdg-top5 {
  padding-top: 0.3125rem;
}

.pdg-rgt5 {
  padding-right: 0.3125rem;
}

.pdg-btm5 {
  padding-bottom: 0.3125rem;
}

.pdg-lft5 {
  padding-left: 0.3125rem;
}

.pdg-6 {
  padding: 0.375rem;
}

.pdg-top6 {
  padding-top: 0.375rem;
}

.pdg-rgt6 {
  padding-right: 0.375rem;
}

.pdg-btm6 {
  padding-bottom: 0.375rem;
}

.pdg-lft6 {
  padding-left: 0.375rem;
}

.pdg-7 {
  padding: 0.4375rem;
}

.pdg-top7 {
  padding-top: 0.4375rem;
}

.pdg-rgt7 {
  padding-right: 0.4375rem;
}

.pdg-btm7 {
  padding-bottom: 0.4375rem;
}

.pdg-lft7 {
  padding-left: 0.4375rem;
}

.pdg-8 {
  padding: 0.5rem;
}

.pdg-top8 {
  padding-top: 0.5rem;
}

.pdg-rgt8 {
  padding-right: 0.5rem;
}

.pdg-btm8 {
  padding-bottom: 0.5rem;
}

.pdg-lft8 {
  padding-left: 0.5rem;
}

.pdg-9 {
  padding: 0.5625rem;
}

.pdg-top9 {
  padding-top: 0.5625rem;
}

.pdg-rgt9 {
  padding-right: 0.5625rem;
}

.pdg-btm9 {
  padding-bottom: 0.5625rem;
}

.pdg-lft9 {
  padding-left: 0.5625rem;
}

.pdg-10 {
  padding: 0.625rem;
}

.pdg-top10 {
  padding-top: 0.625rem;
}

.pdg-rgt10 {
  padding-right: 0.625rem;
}

.pdg-btm10 {
  padding-bottom: 0.625rem;
}

.pdg-lft10 {
  padding-left: 0.625rem;
}

.pdg-11 {
  padding: 0.6875rem;
}

.pdg-top11 {
  padding-top: 0.6875rem;
}

.pdg-rgt11 {
  padding-right: 0.6875rem;
}

.pdg-btm11 {
  padding-bottom: 0.6875rem;
}

.pdg-lft11 {
  padding-left: 0.6875rem;
}

.pdg-12 {
  padding: 0.75rem;
}

.pdg-top12 {
  padding-top: 0.75rem;
}

.pdg-rgt12 {
  padding-right: 0.75rem;
}

.pdg-btm12 {
  padding-bottom: 0.75rem;
}

.pdg-lft12 {
  padding-left: 0.75rem;
}

.pdg-13 {
  padding: 0.8125rem;
}

.pdg-top13 {
  padding-top: 0.8125rem;
}

.pdg-rgt13 {
  padding-right: 0.8125rem;
}

.pdg-btm13 {
  padding-bottom: 0.8125rem;
}

.pdg-lft13 {
  padding-left: 0.8125rem;
}

.pdg-14 {
  padding: 0.875rem;
}

.pdg-top14 {
  padding-top: 0.875rem;
}

.pdg-rgt14 {
  padding-right: 0.875rem;
}

.pdg-btm14 {
  padding-bottom: 0.875rem;
}

.pdg-lft14 {
  padding-left: 0.875rem;
}

.pdg-15 {
  padding: 0.9375rem;
}

.pdg-top15 {
  padding-top: 0.9375rem;
}

.pdg-rgt15 {
  padding-right: 0.9375rem;
}

.pdg-btm15 {
  padding-bottom: 0.9375rem;
}

.pdg-lft15 {
  padding-left: 0.9375rem;
}

.pdg-16 {
  padding: 1rem;
}

.pdg-top16 {
  padding-top: 1rem;
}

.pdg-rgt16 {
  padding-right: 1rem;
}

.pdg-btm16 {
  padding-bottom: 1rem;
}

.pdg-lft16 {
  padding-left: 1rem;
}

.pdg-17 {
  padding: 1.0625rem;
}

.pdg-top17 {
  padding-top: 1.0625rem;
}

.pdg-rgt17 {
  padding-right: 1.0625rem;
}

.pdg-btm17 {
  padding-bottom: 1.0625rem;
}

.pdg-lft17 {
  padding-left: 1.0625rem;
}

.pdg-18 {
  padding: 1.125rem;
}

.pdg-top18 {
  padding-top: 1.125rem;
}

.pdg-rgt18 {
  padding-right: 1.125rem;
}

.pdg-btm18 {
  padding-bottom: 1.125rem;
}

.pdg-lft18 {
  padding-left: 1.125rem;
}

.pdg-19 {
  padding: 1.1875rem;
}

.pdg-top19 {
  padding-top: 1.1875rem;
}

.pdg-rgt19 {
  padding-right: 1.1875rem;
}

.pdg-btm19 {
  padding-bottom: 1.1875rem;
}

.pdg-lft19 {
  padding-left: 1.1875rem;
}

.pdg-20 {
  padding: 1.25rem;
}

.pdg-top20 {
  padding-top: 1.25rem;
}

.pdg-rgt20 {
  padding-right: 1.25rem;
}

.pdg-btm20 {
  padding-bottom: 1.25rem;
}

.pdg-lft20 {
  padding-left: 1.25rem;
}

.pdg-21 {
  padding: 1.3125rem;
}

.pdg-top21 {
  padding-top: 1.3125rem;
}

.pdg-rgt21 {
  padding-right: 1.3125rem;
}

.pdg-btm21 {
  padding-bottom: 1.3125rem;
}

.pdg-lft21 {
  padding-left: 1.3125rem;
}

.pdg-22 {
  padding: 1.375rem;
}

.pdg-top22 {
  padding-top: 1.375rem;
}

.pdg-rgt22 {
  padding-right: 1.375rem;
}

.pdg-btm22 {
  padding-bottom: 1.375rem;
}

.pdg-lft22 {
  padding-left: 1.375rem;
}

.pdg-23 {
  padding: 1.4375rem;
}

.pdg-top23 {
  padding-top: 1.4375rem;
}

.pdg-rgt23 {
  padding-right: 1.4375rem;
}

.pdg-btm23 {
  padding-bottom: 1.4375rem;
}

.pdg-lft23 {
  padding-left: 1.4375rem;
}

.pdg-24 {
  padding: 1.5rem;
}

.pdg-top24 {
  padding-top: 1.5rem;
}

.pdg-rgt24 {
  padding-right: 1.5rem;
}

.pdg-btm24 {
  padding-bottom: 1.5rem;
}

.pdg-lft24 {
  padding-left: 1.5rem;
}

.pdg-25 {
  padding: 1.5625rem;
}

.pdg-top25 {
  padding-top: 1.5625rem;
}

.pdg-rgt25 {
  padding-right: 1.5625rem;
}

.pdg-btm25 {
  padding-bottom: 1.5625rem;
}

.pdg-lft25 {
  padding-left: 1.5625rem;
}

.pdg-26 {
  padding: 1.625rem;
}

.pdg-top26 {
  padding-top: 1.625rem;
}

.pdg-rgt26 {
  padding-right: 1.625rem;
}

.pdg-btm26 {
  padding-bottom: 1.625rem;
}

.pdg-lft26 {
  padding-left: 1.625rem;
}

.pdg-27 {
  padding: 1.6875rem;
}

.pdg-top27 {
  padding-top: 1.6875rem;
}

.pdg-rgt27 {
  padding-right: 1.6875rem;
}

.pdg-btm27 {
  padding-bottom: 1.6875rem;
}

.pdg-lft27 {
  padding-left: 1.6875rem;
}

.pdg-28 {
  padding: 1.75rem;
}

.pdg-top28 {
  padding-top: 1.75rem;
}

.pdg-rgt28 {
  padding-right: 1.75rem;
}

.pdg-btm28 {
  padding-bottom: 1.75rem;
}

.pdg-lft28 {
  padding-left: 1.75rem;
}

.pdg-29 {
  padding: 1.8125rem;
}

.pdg-top29 {
  padding-top: 1.8125rem;
}

.pdg-rgt29 {
  padding-right: 1.8125rem;
}

.pdg-btm29 {
  padding-bottom: 1.8125rem;
}

.pdg-lft29 {
  padding-left: 1.8125rem;
}

.pdg-30 {
  padding: 1.875rem;
}

.pdg-top30 {
  padding-top: 1.875rem;
}

.pdg-rgt30 {
  padding-right: 1.875rem;
}

.pdg-btm30 {
  padding-bottom: 1.875rem;
}

.pdg-lft30 {
  padding-left: 1.875rem;
}

.pdg-31 {
  padding: 1.9375rem;
}

.pdg-top31 {
  padding-top: 1.9375rem;
}

.pdg-rgt31 {
  padding-right: 1.9375rem;
}

.pdg-btm31 {
  padding-bottom: 1.9375rem;
}

.pdg-lft31 {
  padding-left: 1.9375rem;
}

.pdg-32 {
  padding: 2rem;
}

.pdg-top32 {
  padding-top: 2rem;
}

.pdg-rgt32 {
  padding-right: 2rem;
}

.pdg-btm32 {
  padding-bottom: 2rem;
}

.pdg-lft32 {
  padding-left: 2rem;
}

.pdg-33 {
  padding: 2.0625rem;
}

.pdg-top33 {
  padding-top: 2.0625rem;
}

.pdg-rgt33 {
  padding-right: 2.0625rem;
}

.pdg-btm33 {
  padding-bottom: 2.0625rem;
}

.pdg-lft33 {
  padding-left: 2.0625rem;
}

.pdg-34 {
  padding: 2.125rem;
}

.pdg-top34 {
  padding-top: 2.125rem;
}

.pdg-rgt34 {
  padding-right: 2.125rem;
}

.pdg-btm34 {
  padding-bottom: 2.125rem;
}

.pdg-lft34 {
  padding-left: 2.125rem;
}

.pdg-35 {
  padding: 2.1875rem;
}

.pdg-top35 {
  padding-top: 2.1875rem;
}

.pdg-rgt35 {
  padding-right: 2.1875rem;
}

.pdg-btm35 {
  padding-bottom: 2.1875rem;
}

.pdg-lft35 {
  padding-left: 2.1875rem;
}

.pdg-36 {
  padding: 2.25rem;
}

.pdg-top36 {
  padding-top: 2.25rem;
}

.pdg-rgt36 {
  padding-right: 2.25rem;
}

.pdg-btm36 {
  padding-bottom: 2.25rem;
}

.pdg-lft36 {
  padding-left: 2.25rem;
}

.pdg-37 {
  padding: 2.3125rem;
}

.pdg-top37 {
  padding-top: 2.3125rem;
}

.pdg-rgt37 {
  padding-right: 2.3125rem;
}

.pdg-btm37 {
  padding-bottom: 2.3125rem;
}

.pdg-lft37 {
  padding-left: 2.3125rem;
}

.pdg-38 {
  padding: 2.375rem;
}

.pdg-top38 {
  padding-top: 2.375rem;
}

.pdg-rgt38 {
  padding-right: 2.375rem;
}

.pdg-btm38 {
  padding-bottom: 2.375rem;
}

.pdg-lft38 {
  padding-left: 2.375rem;
}

.pdg-39 {
  padding: 2.4375rem;
}

.pdg-top39 {
  padding-top: 2.4375rem;
}

.pdg-rgt39 {
  padding-right: 2.4375rem;
}

.pdg-btm39 {
  padding-bottom: 2.4375rem;
}

.pdg-lft39 {
  padding-left: 2.4375rem;
}

.pdg-40 {
  padding: 2.5rem;
}

.pdg-top40 {
  padding-top: 2.5rem;
}

.pdg-rgt40 {
  padding-right: 2.5rem;
}

.pdg-btm40 {
  padding-bottom: 2.5rem;
}

.pdg-lft40 {
  padding-left: 2.5rem;
}

.pdg-41 {
  padding: 2.5625rem;
}

.pdg-top41 {
  padding-top: 2.5625rem;
}

.pdg-rgt41 {
  padding-right: 2.5625rem;
}

.pdg-btm41 {
  padding-bottom: 2.5625rem;
}

.pdg-lft41 {
  padding-left: 2.5625rem;
}

.pdg-42 {
  padding: 2.625rem;
}

.pdg-top42 {
  padding-top: 2.625rem;
}

.pdg-rgt42 {
  padding-right: 2.625rem;
}

.pdg-btm42 {
  padding-bottom: 2.625rem;
}

.pdg-lft42 {
  padding-left: 2.625rem;
}

.pdg-43 {
  padding: 2.6875rem;
}

.pdg-top43 {
  padding-top: 2.6875rem;
}

.pdg-rgt43 {
  padding-right: 2.6875rem;
}

.pdg-btm43 {
  padding-bottom: 2.6875rem;
}

.pdg-lft43 {
  padding-left: 2.6875rem;
}

.pdg-44 {
  padding: 2.75rem;
}

.pdg-top44 {
  padding-top: 2.75rem;
}

.pdg-rgt44 {
  padding-right: 2.75rem;
}

.pdg-btm44 {
  padding-bottom: 2.75rem;
}

.pdg-lft44 {
  padding-left: 2.75rem;
}

.pdg-45 {
  padding: 2.8125rem;
}

.pdg-top45 {
  padding-top: 2.8125rem;
}

.pdg-rgt45 {
  padding-right: 2.8125rem;
}

.pdg-btm45 {
  padding-bottom: 2.8125rem;
}

.pdg-lft45 {
  padding-left: 2.8125rem;
}

.pdg-46 {
  padding: 2.875rem;
}

.pdg-top46 {
  padding-top: 2.875rem;
}

.pdg-rgt46 {
  padding-right: 2.875rem;
}

.pdg-btm46 {
  padding-bottom: 2.875rem;
}

.pdg-lft46 {
  padding-left: 2.875rem;
}

.pdg-47 {
  padding: 2.9375rem;
}

.pdg-top47 {
  padding-top: 2.9375rem;
}

.pdg-rgt47 {
  padding-right: 2.9375rem;
}

.pdg-btm47 {
  padding-bottom: 2.9375rem;
}

.pdg-lft47 {
  padding-left: 2.9375rem;
}

.pdg-48 {
  padding: 3rem;
}

.pdg-top48 {
  padding-top: 3rem;
}

.pdg-rgt48 {
  padding-right: 3rem;
}

.pdg-btm48 {
  padding-bottom: 3rem;
}

.pdg-lft48 {
  padding-left: 3rem;
}

.pdg-49 {
  padding: 3.0625rem;
}

.pdg-top49 {
  padding-top: 3.0625rem;
}

.pdg-rgt49 {
  padding-right: 3.0625rem;
}

.pdg-btm49 {
  padding-bottom: 3.0625rem;
}

.pdg-lft49 {
  padding-left: 3.0625rem;
}

.pdg-50 {
  padding: 3.125rem;
}

.pdg-top50 {
  padding-top: 3.125rem;
}

.pdg-rgt50 {
  padding-right: 3.125rem;
}

.pdg-btm50 {
  padding-bottom: 3.125rem;
}

.pdg-lft50 {
  padding-left: 3.125rem;
}

.pdg-51 {
  padding: 3.1875rem;
}

.pdg-top51 {
  padding-top: 3.1875rem;
}

.pdg-rgt51 {
  padding-right: 3.1875rem;
}

.pdg-btm51 {
  padding-bottom: 3.1875rem;
}

.pdg-lft51 {
  padding-left: 3.1875rem;
}

.pdg-52 {
  padding: 3.25rem;
}

.pdg-top52 {
  padding-top: 3.25rem;
}

.pdg-rgt52 {
  padding-right: 3.25rem;
}

.pdg-btm52 {
  padding-bottom: 3.25rem;
}

.pdg-lft52 {
  padding-left: 3.25rem;
}

.pdg-53 {
  padding: 3.3125rem;
}

.pdg-top53 {
  padding-top: 3.3125rem;
}

.pdg-rgt53 {
  padding-right: 3.3125rem;
}

.pdg-btm53 {
  padding-bottom: 3.3125rem;
}

.pdg-lft53 {
  padding-left: 3.3125rem;
}

.pdg-54 {
  padding: 3.375rem;
}

.pdg-top54 {
  padding-top: 3.375rem;
}

.pdg-rgt54 {
  padding-right: 3.375rem;
}

.pdg-btm54 {
  padding-bottom: 3.375rem;
}

.pdg-lft54 {
  padding-left: 3.375rem;
}

.pdg-55 {
  padding: 3.4375rem;
}

.pdg-top55 {
  padding-top: 3.4375rem;
}

.pdg-rgt55 {
  padding-right: 3.4375rem;
}

.pdg-btm55 {
  padding-bottom: 3.4375rem;
}

.pdg-lft55 {
  padding-left: 3.4375rem;
}

.pdg-56 {
  padding: 3.5rem;
}

.pdg-top56 {
  padding-top: 3.5rem;
}

.pdg-rgt56 {
  padding-right: 3.5rem;
}

.pdg-btm56 {
  padding-bottom: 3.5rem;
}

.pdg-lft56 {
  padding-left: 3.5rem;
}

.pdg-57 {
  padding: 3.5625rem;
}

.pdg-top57 {
  padding-top: 3.5625rem;
}

.pdg-rgt57 {
  padding-right: 3.5625rem;
}

.pdg-btm57 {
  padding-bottom: 3.5625rem;
}

.pdg-lft57 {
  padding-left: 3.5625rem;
}

.pdg-58 {
  padding: 3.625rem;
}

.pdg-top58 {
  padding-top: 3.625rem;
}

.pdg-rgt58 {
  padding-right: 3.625rem;
}

.pdg-btm58 {
  padding-bottom: 3.625rem;
}

.pdg-lft58 {
  padding-left: 3.625rem;
}

.pdg-59 {
  padding: 3.6875rem;
}

.pdg-top59 {
  padding-top: 3.6875rem;
}

.pdg-rgt59 {
  padding-right: 3.6875rem;
}

.pdg-btm59 {
  padding-bottom: 3.6875rem;
}

.pdg-lft59 {
  padding-left: 3.6875rem;
}

.pdg-60 {
  padding: 3.75rem;
}

.pdg-top60 {
  padding-top: 3.75rem;
}

.pdg-rgt60 {
  padding-right: 3.75rem;
}

.pdg-btm60 {
  padding-bottom: 3.75rem;
}

.pdg-lft60 {
  padding-left: 3.75rem;
}

.pdg-61 {
  padding: 3.8125rem;
}

.pdg-top61 {
  padding-top: 3.8125rem;
}

.pdg-rgt61 {
  padding-right: 3.8125rem;
}

.pdg-btm61 {
  padding-bottom: 3.8125rem;
}

.pdg-lft61 {
  padding-left: 3.8125rem;
}

.pdg-62 {
  padding: 3.875rem;
}

.pdg-top62 {
  padding-top: 3.875rem;
}

.pdg-rgt62 {
  padding-right: 3.875rem;
}

.pdg-btm62 {
  padding-bottom: 3.875rem;
}

.pdg-lft62 {
  padding-left: 3.875rem;
}

.pdg-63 {
  padding: 3.9375rem;
}

.pdg-top63 {
  padding-top: 3.9375rem;
}

.pdg-rgt63 {
  padding-right: 3.9375rem;
}

.pdg-btm63 {
  padding-bottom: 3.9375rem;
}

.pdg-lft63 {
  padding-left: 3.9375rem;
}

.pdg-64 {
  padding: 4rem;
}

.pdg-top64 {
  padding-top: 4rem;
}

.pdg-rgt64 {
  padding-right: 4rem;
}

.pdg-btm64 {
  padding-bottom: 4rem;
}

.pdg-lft64 {
  padding-left: 4rem;
}

.pdg-65 {
  padding: 4.0625rem;
}

.pdg-top65 {
  padding-top: 4.0625rem;
}

.pdg-rgt65 {
  padding-right: 4.0625rem;
}

.pdg-btm65 {
  padding-bottom: 4.0625rem;
}

.pdg-lft65 {
  padding-left: 4.0625rem;
}

.pdg-66 {
  padding: 4.125rem;
}

.pdg-top66 {
  padding-top: 4.125rem;
}

.pdg-rgt66 {
  padding-right: 4.125rem;
}

.pdg-btm66 {
  padding-bottom: 4.125rem;
}

.pdg-lft66 {
  padding-left: 4.125rem;
}

.pdg-67 {
  padding: 4.1875rem;
}

.pdg-top67 {
  padding-top: 4.1875rem;
}

.pdg-rgt67 {
  padding-right: 4.1875rem;
}

.pdg-btm67 {
  padding-bottom: 4.1875rem;
}

.pdg-lft67 {
  padding-left: 4.1875rem;
}

.pdg-68 {
  padding: 4.25rem;
}

.pdg-top68 {
  padding-top: 4.25rem;
}

.pdg-rgt68 {
  padding-right: 4.25rem;
}

.pdg-btm68 {
  padding-bottom: 4.25rem;
}

.pdg-lft68 {
  padding-left: 4.25rem;
}

.pdg-69 {
  padding: 4.3125rem;
}

.pdg-top69 {
  padding-top: 4.3125rem;
}

.pdg-rgt69 {
  padding-right: 4.3125rem;
}

.pdg-btm69 {
  padding-bottom: 4.3125rem;
}

.pdg-lft69 {
  padding-left: 4.3125rem;
}

.pdg-70 {
  padding: 4.375rem;
}

.pdg-top70 {
  padding-top: 4.375rem;
}

.pdg-rgt70 {
  padding-right: 4.375rem;
}

.pdg-btm70 {
  padding-bottom: 4.375rem;
}

.pdg-lft70 {
  padding-left: 4.375rem;
}

.pdg-71 {
  padding: 4.4375rem;
}

.pdg-top71 {
  padding-top: 4.4375rem;
}

.pdg-rgt71 {
  padding-right: 4.4375rem;
}

.pdg-btm71 {
  padding-bottom: 4.4375rem;
}

.pdg-lft71 {
  padding-left: 4.4375rem;
}

.pdg-72 {
  padding: 4.5rem;
}

.pdg-top72 {
  padding-top: 4.5rem;
}

.pdg-rgt72 {
  padding-right: 4.5rem;
}

.pdg-btm72 {
  padding-bottom: 4.5rem;
}

.pdg-lft72 {
  padding-left: 4.5rem;
}

.pdg-73 {
  padding: 4.5625rem;
}

.pdg-top73 {
  padding-top: 4.5625rem;
}

.pdg-rgt73 {
  padding-right: 4.5625rem;
}

.pdg-btm73 {
  padding-bottom: 4.5625rem;
}

.pdg-lft73 {
  padding-left: 4.5625rem;
}

.pdg-74 {
  padding: 4.625rem;
}

.pdg-top74 {
  padding-top: 4.625rem;
}

.pdg-rgt74 {
  padding-right: 4.625rem;
}

.pdg-btm74 {
  padding-bottom: 4.625rem;
}

.pdg-lft74 {
  padding-left: 4.625rem;
}

.pdg-75 {
  padding: 4.6875rem;
}

.pdg-top75 {
  padding-top: 4.6875rem;
}

.pdg-rgt75 {
  padding-right: 4.6875rem;
}

.pdg-btm75 {
  padding-bottom: 4.6875rem;
}

.pdg-lft75 {
  padding-left: 4.6875rem;
}

.pdg-76 {
  padding: 4.75rem;
}

.pdg-top76 {
  padding-top: 4.75rem;
}

.pdg-rgt76 {
  padding-right: 4.75rem;
}

.pdg-btm76 {
  padding-bottom: 4.75rem;
}

.pdg-lft76 {
  padding-left: 4.75rem;
}

.pdg-77 {
  padding: 4.8125rem;
}

.pdg-top77 {
  padding-top: 4.8125rem;
}

.pdg-rgt77 {
  padding-right: 4.8125rem;
}

.pdg-btm77 {
  padding-bottom: 4.8125rem;
}

.pdg-lft77 {
  padding-left: 4.8125rem;
}

.pdg-78 {
  padding: 4.875rem;
}

.pdg-top78 {
  padding-top: 4.875rem;
}

.pdg-rgt78 {
  padding-right: 4.875rem;
}

.pdg-btm78 {
  padding-bottom: 4.875rem;
}

.pdg-lft78 {
  padding-left: 4.875rem;
}

.pdg-79 {
  padding: 4.9375rem;
}

.pdg-top79 {
  padding-top: 4.9375rem;
}

.pdg-rgt79 {
  padding-right: 4.9375rem;
}

.pdg-btm79 {
  padding-bottom: 4.9375rem;
}

.pdg-lft79 {
  padding-left: 4.9375rem;
}

.pdg-80 {
  padding: 5rem;
}

.pdg-top80 {
  padding-top: 5rem;
}

.pdg-rgt80 {
  padding-right: 5rem;
}

.pdg-btm80 {
  padding-bottom: 5rem;
}

.pdg-lft80 {
  padding-left: 5rem;
}

.pdg-81 {
  padding: 5.0625rem;
}

.pdg-top81 {
  padding-top: 5.0625rem;
}

.pdg-rgt81 {
  padding-right: 5.0625rem;
}

.pdg-btm81 {
  padding-bottom: 5.0625rem;
}

.pdg-lft81 {
  padding-left: 5.0625rem;
}

.pdg-82 {
  padding: 5.125rem;
}

.pdg-top82 {
  padding-top: 5.125rem;
}

.pdg-rgt82 {
  padding-right: 5.125rem;
}

.pdg-btm82 {
  padding-bottom: 5.125rem;
}

.pdg-lft82 {
  padding-left: 5.125rem;
}

.pdg-83 {
  padding: 5.1875rem;
}

.pdg-top83 {
  padding-top: 5.1875rem;
}

.pdg-rgt83 {
  padding-right: 5.1875rem;
}

.pdg-btm83 {
  padding-bottom: 5.1875rem;
}

.pdg-lft83 {
  padding-left: 5.1875rem;
}

.pdg-84 {
  padding: 5.25rem;
}

.pdg-top84 {
  padding-top: 5.25rem;
}

.pdg-rgt84 {
  padding-right: 5.25rem;
}

.pdg-btm84 {
  padding-bottom: 5.25rem;
}

.pdg-lft84 {
  padding-left: 5.25rem;
}

.pdg-85 {
  padding: 5.3125rem;
}

.pdg-top85 {
  padding-top: 5.3125rem;
}

.pdg-rgt85 {
  padding-right: 5.3125rem;
}

.pdg-btm85 {
  padding-bottom: 5.3125rem;
}

.pdg-lft85 {
  padding-left: 5.3125rem;
}

.pdg-86 {
  padding: 5.375rem;
}

.pdg-top86 {
  padding-top: 5.375rem;
}

.pdg-rgt86 {
  padding-right: 5.375rem;
}

.pdg-btm86 {
  padding-bottom: 5.375rem;
}

.pdg-lft86 {
  padding-left: 5.375rem;
}

.pdg-87 {
  padding: 5.4375rem;
}

.pdg-top87 {
  padding-top: 5.4375rem;
}

.pdg-rgt87 {
  padding-right: 5.4375rem;
}

.pdg-btm87 {
  padding-bottom: 5.4375rem;
}

.pdg-lft87 {
  padding-left: 5.4375rem;
}

.pdg-88 {
  padding: 5.5rem;
}

.pdg-top88 {
  padding-top: 5.5rem;
}

.pdg-rgt88 {
  padding-right: 5.5rem;
}

.pdg-btm88 {
  padding-bottom: 5.5rem;
}

.pdg-lft88 {
  padding-left: 5.5rem;
}

.pdg-89 {
  padding: 5.5625rem;
}

.pdg-top89 {
  padding-top: 5.5625rem;
}

.pdg-rgt89 {
  padding-right: 5.5625rem;
}

.pdg-btm89 {
  padding-bottom: 5.5625rem;
}

.pdg-lft89 {
  padding-left: 5.5625rem;
}

.pdg-90 {
  padding: 5.625rem;
}

.pdg-top90 {
  padding-top: 5.625rem;
}

.pdg-rgt90 {
  padding-right: 5.625rem;
}

.pdg-btm90 {
  padding-bottom: 5.625rem;
}

.pdg-lft90 {
  padding-left: 5.625rem;
}

.pdg-91 {
  padding: 5.6875rem;
}

.pdg-top91 {
  padding-top: 5.6875rem;
}

.pdg-rgt91 {
  padding-right: 5.6875rem;
}

.pdg-btm91 {
  padding-bottom: 5.6875rem;
}

.pdg-lft91 {
  padding-left: 5.6875rem;
}

.pdg-92 {
  padding: 5.75rem;
}

.pdg-top92 {
  padding-top: 5.75rem;
}

.pdg-rgt92 {
  padding-right: 5.75rem;
}

.pdg-btm92 {
  padding-bottom: 5.75rem;
}

.pdg-lft92 {
  padding-left: 5.75rem;
}

.pdg-93 {
  padding: 5.8125rem;
}

.pdg-top93 {
  padding-top: 5.8125rem;
}

.pdg-rgt93 {
  padding-right: 5.8125rem;
}

.pdg-btm93 {
  padding-bottom: 5.8125rem;
}

.pdg-lft93 {
  padding-left: 5.8125rem;
}

.pdg-94 {
  padding: 5.875rem;
}

.pdg-top94 {
  padding-top: 5.875rem;
}

.pdg-rgt94 {
  padding-right: 5.875rem;
}

.pdg-btm94 {
  padding-bottom: 5.875rem;
}

.pdg-lft94 {
  padding-left: 5.875rem;
}

.pdg-95 {
  padding: 5.9375rem;
}

.pdg-top95 {
  padding-top: 5.9375rem;
}

.pdg-rgt95 {
  padding-right: 5.9375rem;
}

.pdg-btm95 {
  padding-bottom: 5.9375rem;
}

.pdg-lft95 {
  padding-left: 5.9375rem;
}

.pdg-96 {
  padding: 6rem;
}

.pdg-top96 {
  padding-top: 6rem;
}

.pdg-rgt96 {
  padding-right: 6rem;
}

.pdg-btm96 {
  padding-bottom: 6rem;
}

.pdg-lft96 {
  padding-left: 6rem;
}

.pdg-97 {
  padding: 6.0625rem;
}

.pdg-top97 {
  padding-top: 6.0625rem;
}

.pdg-rgt97 {
  padding-right: 6.0625rem;
}

.pdg-btm97 {
  padding-bottom: 6.0625rem;
}

.pdg-lft97 {
  padding-left: 6.0625rem;
}

.pdg-98 {
  padding: 6.125rem;
}

.pdg-top98 {
  padding-top: 6.125rem;
}

.pdg-rgt98 {
  padding-right: 6.125rem;
}

.pdg-btm98 {
  padding-bottom: 6.125rem;
}

.pdg-lft98 {
  padding-left: 6.125rem;
}

.pdg-99 {
  padding: 6.1875rem;
}

.pdg-top99 {
  padding-top: 6.1875rem;
}

.pdg-rgt99 {
  padding-right: 6.1875rem;
}

.pdg-btm99 {
  padding-bottom: 6.1875rem;
}

.pdg-lft99 {
  padding-left: 6.1875rem;
}

.pdg-100 {
  padding: 6.25rem;
}

.pdg-top100 {
  padding-top: 6.25rem;
}

.pdg-rgt100 {
  padding-right: 6.25rem;
}

.pdg-btm100 {
  padding-bottom: 6.25rem;
}

.pdg-lft100 {
  padding-left: 6.25rem;
}

.pdg-101 {
  padding: 6.3125rem;
}

.pdg-top101 {
  padding-top: 6.3125rem;
}

.pdg-rgt101 {
  padding-right: 6.3125rem;
}

.pdg-btm101 {
  padding-bottom: 6.3125rem;
}

.pdg-lft101 {
  padding-left: 6.3125rem;
}

.pdg-102 {
  padding: 6.375rem;
}

.pdg-top102 {
  padding-top: 6.375rem;
}

.pdg-rgt102 {
  padding-right: 6.375rem;
}

.pdg-btm102 {
  padding-bottom: 6.375rem;
}

.pdg-lft102 {
  padding-left: 6.375rem;
}

.pdg-103 {
  padding: 6.4375rem;
}

.pdg-top103 {
  padding-top: 6.4375rem;
}

.pdg-rgt103 {
  padding-right: 6.4375rem;
}

.pdg-btm103 {
  padding-bottom: 6.4375rem;
}

.pdg-lft103 {
  padding-left: 6.4375rem;
}

.pdg-104 {
  padding: 6.5rem;
}

.pdg-top104 {
  padding-top: 6.5rem;
}

.pdg-rgt104 {
  padding-right: 6.5rem;
}

.pdg-btm104 {
  padding-bottom: 6.5rem;
}

.pdg-lft104 {
  padding-left: 6.5rem;
}

.pdg-105 {
  padding: 6.5625rem;
}

.pdg-top105 {
  padding-top: 6.5625rem;
}

.pdg-rgt105 {
  padding-right: 6.5625rem;
}

.pdg-btm105 {
  padding-bottom: 6.5625rem;
}

.pdg-lft105 {
  padding-left: 6.5625rem;
}

.pdg-106 {
  padding: 6.625rem;
}

.pdg-top106 {
  padding-top: 6.625rem;
}

.pdg-rgt106 {
  padding-right: 6.625rem;
}

.pdg-btm106 {
  padding-bottom: 6.625rem;
}

.pdg-lft106 {
  padding-left: 6.625rem;
}

.pdg-107 {
  padding: 6.6875rem;
}

.pdg-top107 {
  padding-top: 6.6875rem;
}

.pdg-rgt107 {
  padding-right: 6.6875rem;
}

.pdg-btm107 {
  padding-bottom: 6.6875rem;
}

.pdg-lft107 {
  padding-left: 6.6875rem;
}

.pdg-108 {
  padding: 6.75rem;
}

.pdg-top108 {
  padding-top: 6.75rem;
}

.pdg-rgt108 {
  padding-right: 6.75rem;
}

.pdg-btm108 {
  padding-bottom: 6.75rem;
}

.pdg-lft108 {
  padding-left: 6.75rem;
}

.pdg-109 {
  padding: 6.8125rem;
}

.pdg-top109 {
  padding-top: 6.8125rem;
}

.pdg-rgt109 {
  padding-right: 6.8125rem;
}

.pdg-btm109 {
  padding-bottom: 6.8125rem;
}

.pdg-lft109 {
  padding-left: 6.8125rem;
}

.pdg-110 {
  padding: 6.875rem;
}

.pdg-top110 {
  padding-top: 6.875rem;
}

.pdg-rgt110 {
  padding-right: 6.875rem;
}

.pdg-btm110 {
  padding-bottom: 6.875rem;
}

.pdg-lft110 {
  padding-left: 6.875rem;
}

.pdg-111 {
  padding: 6.9375rem;
}

.pdg-top111 {
  padding-top: 6.9375rem;
}

.pdg-rgt111 {
  padding-right: 6.9375rem;
}

.pdg-btm111 {
  padding-bottom: 6.9375rem;
}

.pdg-lft111 {
  padding-left: 6.9375rem;
}

.pdg-112 {
  padding: 7rem;
}

.pdg-top112 {
  padding-top: 7rem;
}

.pdg-rgt112 {
  padding-right: 7rem;
}

.pdg-btm112 {
  padding-bottom: 7rem;
}

.pdg-lft112 {
  padding-left: 7rem;
}

.pdg-113 {
  padding: 7.0625rem;
}

.pdg-top113 {
  padding-top: 7.0625rem;
}

.pdg-rgt113 {
  padding-right: 7.0625rem;
}

.pdg-btm113 {
  padding-bottom: 7.0625rem;
}

.pdg-lft113 {
  padding-left: 7.0625rem;
}

.pdg-114 {
  padding: 7.125rem;
}

.pdg-top114 {
  padding-top: 7.125rem;
}

.pdg-rgt114 {
  padding-right: 7.125rem;
}

.pdg-btm114 {
  padding-bottom: 7.125rem;
}

.pdg-lft114 {
  padding-left: 7.125rem;
}

.pdg-115 {
  padding: 7.1875rem;
}

.pdg-top115 {
  padding-top: 7.1875rem;
}

.pdg-rgt115 {
  padding-right: 7.1875rem;
}

.pdg-btm115 {
  padding-bottom: 7.1875rem;
}

.pdg-lft115 {
  padding-left: 7.1875rem;
}

.pdg-116 {
  padding: 7.25rem;
}

.pdg-top116 {
  padding-top: 7.25rem;
}

.pdg-rgt116 {
  padding-right: 7.25rem;
}

.pdg-btm116 {
  padding-bottom: 7.25rem;
}

.pdg-lft116 {
  padding-left: 7.25rem;
}

.pdg-117 {
  padding: 7.3125rem;
}

.pdg-top117 {
  padding-top: 7.3125rem;
}

.pdg-rgt117 {
  padding-right: 7.3125rem;
}

.pdg-btm117 {
  padding-bottom: 7.3125rem;
}

.pdg-lft117 {
  padding-left: 7.3125rem;
}

.pdg-118 {
  padding: 7.375rem;
}

.pdg-top118 {
  padding-top: 7.375rem;
}

.pdg-rgt118 {
  padding-right: 7.375rem;
}

.pdg-btm118 {
  padding-bottom: 7.375rem;
}

.pdg-lft118 {
  padding-left: 7.375rem;
}

.pdg-119 {
  padding: 7.4375rem;
}

.pdg-top119 {
  padding-top: 7.4375rem;
}

.pdg-rgt119 {
  padding-right: 7.4375rem;
}

.pdg-btm119 {
  padding-bottom: 7.4375rem;
}

.pdg-lft119 {
  padding-left: 7.4375rem;
}

.pdg-120 {
  padding: 7.5rem;
}

.pdg-top120 {
  padding-top: 7.5rem;
}

.pdg-rgt120 {
  padding-right: 7.5rem;
}

.pdg-btm120 {
  padding-bottom: 7.5rem;
}

.pdg-lft120 {
  padding-left: 7.5rem;
}

.pdg-121 {
  padding: 7.5625rem;
}

.pdg-top121 {
  padding-top: 7.5625rem;
}

.pdg-rgt121 {
  padding-right: 7.5625rem;
}

.pdg-btm121 {
  padding-bottom: 7.5625rem;
}

.pdg-lft121 {
  padding-left: 7.5625rem;
}

.pdg-122 {
  padding: 7.625rem;
}

.pdg-top122 {
  padding-top: 7.625rem;
}

.pdg-rgt122 {
  padding-right: 7.625rem;
}

.pdg-btm122 {
  padding-bottom: 7.625rem;
}

.pdg-lft122 {
  padding-left: 7.625rem;
}

.pdg-123 {
  padding: 7.6875rem;
}

.pdg-top123 {
  padding-top: 7.6875rem;
}

.pdg-rgt123 {
  padding-right: 7.6875rem;
}

.pdg-btm123 {
  padding-bottom: 7.6875rem;
}

.pdg-lft123 {
  padding-left: 7.6875rem;
}

.pdg-124 {
  padding: 7.75rem;
}

.pdg-top124 {
  padding-top: 7.75rem;
}

.pdg-rgt124 {
  padding-right: 7.75rem;
}

.pdg-btm124 {
  padding-bottom: 7.75rem;
}

.pdg-lft124 {
  padding-left: 7.75rem;
}

.pdg-125 {
  padding: 7.8125rem;
}

.pdg-top125 {
  padding-top: 7.8125rem;
}

.pdg-rgt125 {
  padding-right: 7.8125rem;
}

.pdg-btm125 {
  padding-bottom: 7.8125rem;
}

.pdg-lft125 {
  padding-left: 7.8125rem;
}

.pdg-126 {
  padding: 7.875rem;
}

.pdg-top126 {
  padding-top: 7.875rem;
}

.pdg-rgt126 {
  padding-right: 7.875rem;
}

.pdg-btm126 {
  padding-bottom: 7.875rem;
}

.pdg-lft126 {
  padding-left: 7.875rem;
}

.pdg-127 {
  padding: 7.9375rem;
}

.pdg-top127 {
  padding-top: 7.9375rem;
}

.pdg-rgt127 {
  padding-right: 7.9375rem;
}

.pdg-btm127 {
  padding-bottom: 7.9375rem;
}

.pdg-lft127 {
  padding-left: 7.9375rem;
}

.pdg-128 {
  padding: 8rem;
}

.pdg-top128 {
  padding-top: 8rem;
}

.pdg-rgt128 {
  padding-right: 8rem;
}

.pdg-btm128 {
  padding-bottom: 8rem;
}

.pdg-lft128 {
  padding-left: 8rem;
}

.pdg-129 {
  padding: 8.0625rem;
}

.pdg-top129 {
  padding-top: 8.0625rem;
}

.pdg-rgt129 {
  padding-right: 8.0625rem;
}

.pdg-btm129 {
  padding-bottom: 8.0625rem;
}

.pdg-lft129 {
  padding-left: 8.0625rem;
}

.pdg-130 {
  padding: 8.125rem;
}

.pdg-top130 {
  padding-top: 8.125rem;
}

.pdg-rgt130 {
  padding-right: 8.125rem;
}

.pdg-btm130 {
  padding-bottom: 8.125rem;
}

.pdg-lft130 {
  padding-left: 8.125rem;
}

.pdg-131 {
  padding: 8.1875rem;
}

.pdg-top131 {
  padding-top: 8.1875rem;
}

.pdg-rgt131 {
  padding-right: 8.1875rem;
}

.pdg-btm131 {
  padding-bottom: 8.1875rem;
}

.pdg-lft131 {
  padding-left: 8.1875rem;
}

.pdg-132 {
  padding: 8.25rem;
}

.pdg-top132 {
  padding-top: 8.25rem;
}

.pdg-rgt132 {
  padding-right: 8.25rem;
}

.pdg-btm132 {
  padding-bottom: 8.25rem;
}

.pdg-lft132 {
  padding-left: 8.25rem;
}

.pdg-133 {
  padding: 8.3125rem;
}

.pdg-top133 {
  padding-top: 8.3125rem;
}

.pdg-rgt133 {
  padding-right: 8.3125rem;
}

.pdg-btm133 {
  padding-bottom: 8.3125rem;
}

.pdg-lft133 {
  padding-left: 8.3125rem;
}

.pdg-134 {
  padding: 8.375rem;
}

.pdg-top134 {
  padding-top: 8.375rem;
}

.pdg-rgt134 {
  padding-right: 8.375rem;
}

.pdg-btm134 {
  padding-bottom: 8.375rem;
}

.pdg-lft134 {
  padding-left: 8.375rem;
}

.pdg-135 {
  padding: 8.4375rem;
}

.pdg-top135 {
  padding-top: 8.4375rem;
}

.pdg-rgt135 {
  padding-right: 8.4375rem;
}

.pdg-btm135 {
  padding-bottom: 8.4375rem;
}

.pdg-lft135 {
  padding-left: 8.4375rem;
}

.pdg-136 {
  padding: 8.5rem;
}

.pdg-top136 {
  padding-top: 8.5rem;
}

.pdg-rgt136 {
  padding-right: 8.5rem;
}

.pdg-btm136 {
  padding-bottom: 8.5rem;
}

.pdg-lft136 {
  padding-left: 8.5rem;
}

.pdg-137 {
  padding: 8.5625rem;
}

.pdg-top137 {
  padding-top: 8.5625rem;
}

.pdg-rgt137 {
  padding-right: 8.5625rem;
}

.pdg-btm137 {
  padding-bottom: 8.5625rem;
}

.pdg-lft137 {
  padding-left: 8.5625rem;
}

.pdg-138 {
  padding: 8.625rem;
}

.pdg-top138 {
  padding-top: 8.625rem;
}

.pdg-rgt138 {
  padding-right: 8.625rem;
}

.pdg-btm138 {
  padding-bottom: 8.625rem;
}

.pdg-lft138 {
  padding-left: 8.625rem;
}

.pdg-139 {
  padding: 8.6875rem;
}

.pdg-top139 {
  padding-top: 8.6875rem;
}

.pdg-rgt139 {
  padding-right: 8.6875rem;
}

.pdg-btm139 {
  padding-bottom: 8.6875rem;
}

.pdg-lft139 {
  padding-left: 8.6875rem;
}

.pdg-140 {
  padding: 8.75rem;
}

.pdg-top140 {
  padding-top: 8.75rem;
}

.pdg-rgt140 {
  padding-right: 8.75rem;
}

.pdg-btm140 {
  padding-bottom: 8.75rem;
}

.pdg-lft140 {
  padding-left: 8.75rem;
}

.pdg-141 {
  padding: 8.8125rem;
}

.pdg-top141 {
  padding-top: 8.8125rem;
}

.pdg-rgt141 {
  padding-right: 8.8125rem;
}

.pdg-btm141 {
  padding-bottom: 8.8125rem;
}

.pdg-lft141 {
  padding-left: 8.8125rem;
}

.pdg-142 {
  padding: 8.875rem;
}

.pdg-top142 {
  padding-top: 8.875rem;
}

.pdg-rgt142 {
  padding-right: 8.875rem;
}

.pdg-btm142 {
  padding-bottom: 8.875rem;
}

.pdg-lft142 {
  padding-left: 8.875rem;
}

.pdg-143 {
  padding: 8.9375rem;
}

.pdg-top143 {
  padding-top: 8.9375rem;
}

.pdg-rgt143 {
  padding-right: 8.9375rem;
}

.pdg-btm143 {
  padding-bottom: 8.9375rem;
}

.pdg-lft143 {
  padding-left: 8.9375rem;
}

.pdg-144 {
  padding: 9rem;
}

.pdg-top144 {
  padding-top: 9rem;
}

.pdg-rgt144 {
  padding-right: 9rem;
}

.pdg-btm144 {
  padding-bottom: 9rem;
}

.pdg-lft144 {
  padding-left: 9rem;
}

.pdg-145 {
  padding: 9.0625rem;
}

.pdg-top145 {
  padding-top: 9.0625rem;
}

.pdg-rgt145 {
  padding-right: 9.0625rem;
}

.pdg-btm145 {
  padding-bottom: 9.0625rem;
}

.pdg-lft145 {
  padding-left: 9.0625rem;
}

.pdg-146 {
  padding: 9.125rem;
}

.pdg-top146 {
  padding-top: 9.125rem;
}

.pdg-rgt146 {
  padding-right: 9.125rem;
}

.pdg-btm146 {
  padding-bottom: 9.125rem;
}

.pdg-lft146 {
  padding-left: 9.125rem;
}

.pdg-147 {
  padding: 9.1875rem;
}

.pdg-top147 {
  padding-top: 9.1875rem;
}

.pdg-rgt147 {
  padding-right: 9.1875rem;
}

.pdg-btm147 {
  padding-bottom: 9.1875rem;
}

.pdg-lft147 {
  padding-left: 9.1875rem;
}

.pdg-148 {
  padding: 9.25rem;
}

.pdg-top148 {
  padding-top: 9.25rem;
}

.pdg-rgt148 {
  padding-right: 9.25rem;
}

.pdg-btm148 {
  padding-bottom: 9.25rem;
}

.pdg-lft148 {
  padding-left: 9.25rem;
}

.pdg-149 {
  padding: 9.3125rem;
}

.pdg-top149 {
  padding-top: 9.3125rem;
}

.pdg-rgt149 {
  padding-right: 9.3125rem;
}

.pdg-btm149 {
  padding-bottom: 9.3125rem;
}

.pdg-lft149 {
  padding-left: 9.3125rem;
}

.pdg-150 {
  padding: 9.375rem;
}

.pdg-top150 {
  padding-top: 9.375rem;
}

.pdg-rgt150 {
  padding-right: 9.375rem;
}

.pdg-btm150 {
  padding-bottom: 9.375rem;
}

.pdg-lft150 {
  padding-left: 9.375rem;
}

/* --- position --- */
.pos_rel {
  position: relative;
}

.pos_ab {
  position: absolute;
}

/* --- float --- */
.flt-rgt {
  float: right;
}

.flt-lft {
  float: left;
}

/* breadcrumbs
**************************************** */
.breadcrumbs .inner {
  padding: 1rem 0;
  white-space: nowrap;
}
@media print, screen and (min-width: 48em) {
  .breadcrumbs .inner {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
@media screen and (max-width: 47.9375em) {
  .breadcrumbs .inner {
    width: 100%;
    padding-left: 7vw;
    padding-right: 7vw;
    overflow: auto;
  }
}

.breadcrumbs a {
  color: #fff;
}
.breadcrumbs a:hover {
  color: var(--clr-main);
}

.breadcrumbs span span:not(:last-child)::after {
  content: "";
  display: inline-block;
  width: 0.3125rem;
  height: 0.4375rem;
  clip-path: polygon(0 100%, 0 0, 100% 50%);
  background-color: #ccc;
  margin: 0.15em 0.5em 0.15em 1em;
}

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 0.5rem;
}

.btn a,
.btn > span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  column-gap: 1.5rem;
  width: fit-content;
  text-align: left;
  cursor: pointer;
}
@media screen and (max-width: 47.9375em) {
  .btn a,
  .btn > span {
    min-width: 100%;
  }
}
.btn a svg,
.btn > span svg {
  right: 1.5rem;
  fill: var(--clr-body);
}

a .txt--wrap {
  position: relative;
  display: inline-block;
  flex: 1;
  font-size: 16px;
  line-height: 2;
}
@media screen and (max-width: 47.9375em) {
  a .txt--wrap {
    flex: inherit;
  }
}
a .txt--wrap::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  height: 1px;
  width: 100%;
  background: transparent;
  transition: var(--transit-default);
}

a:hover .txt--wrap::before {
  background: var(--clr-body);
  animation: btn-line 0.5s ease-out 0s 1 normal forwards;
}

a .btn_circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  background-color: var(--clr-main);
  border-radius: 50%;
  transition: var(--transit-default, all 0.4s ease-out);
  z-index: 1;
}

a:hover .btn_circle {
  background-color: var(--bg-main);
}

a .btn_arrow {
  position: relative;
  width: 0.5625rem;
  height: 0.8125rem;
  overflow: hidden;
}
a .btn_arrow::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/top/button_arrow.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transition: var(--transit-default, all 0.4s ease-out);
}
a .btn_arrow::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-120%);
  width: 100%;
  height: 100%;
  background-image: url(../images/top/button_arrow.svg);
  background-size: cover;
  background-repeat: no-repeat;
  transition: var(--transit-default, all 0.4s ease-out);
}

a:hover .btn_arrow::before {
  transform: translateX(100%);
}
a:hover .btn_arrow::after {
  transform: translateX(0);
}

/* --- btn-wh--- */
.btn-wh.btn a {
  color: #fff;
}
.btn-wh.btn a svg {
  fill: #fff;
}

.btn-wh.btn a .txt--wrap {
  color: #fff;
}

.btn-wh.btn a:hover .txt--wrap::before {
  background: #fff;
}

.btn-wh.btn a:hover .btn_circle {
  background-color: var(--clr-main);
}

.btn-wh.btn a .btn_arrow::before {
  background-image: url(../images/top/button_arrow.svg);
}
.btn-wh.btn a .btn_arrow::after {
  background-image: url(../images/top/button_arrow.svg);
}

/* --- lps_sec[style*="background-color"] --- */
.lps_sec[style*=background-color] .btn a {
  color: #fff;
}
.lps_sec[style*=background-color] .btn a svg {
  fill: #fff;
}

.lps_sec[style*=background-color] a .txt--wrap {
  color: #fff;
}

.lps_sec[style*=background-color] a:hover .txt--wrap::before {
  background: #fff;
}

.lps_sec[style*=background-color] a:hover .btn_circle {
  background-color: var(--clr-main);
}

.lps_sec[style*=background-color] a .btn_arrow::before {
  background-image: url(../images/top/button_arrow.svg);
}
.lps_sec[style*=background-color] a .btn_arrow::after {
  background-image: url(../images/top/button_arrow.svg);
}

/* --- lps_sec[style*="background-color"] bg-wh --- */
.lps_sec[style*=background-color] .bg-wh .btn a {
  color: var(--clr-body);
}
.lps_sec[style*=background-color] .bg-wh .btn a svg {
  fill: var(--clr-body);
}

.lps_sec[style*=background-color] .bg-wh a .txt--wrap {
  color: var(--clr-body);
}

.lps_sec[style*=background-color] .bg-wh a:hover .txt--wrap::before {
  background: var(--clr-body);
}

.lps_sec[style*=background-color] .bg-wh a:hover .btn_circle {
  background-color: var(--bg-main);
}

.lps_sec[style*=background-color] .bg-wh a .btn_arrow::before {
  background-image: url(../images/top/button_arrow.svg);
}
.lps_sec[style*=background-color] .bg-wh a .btn_arrow::after {
  background-image: url(../images/top/button_arrow.svg);
}

/* --- icon --- */
.btn a[target=_blank] {
  padding: 14px 0;
}

.btn a[href$=".pdf"] {
  padding: 14px 0 14px 38px;
  display: inline-block;
}

.btn .icon-pdf {
  left: 0.25rem;
}

.flex > .btn a {
  min-width: 100%;
}

.btn.btn-ctr a {
  justify-content: center;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 47.9375em) {
  .btn.btn-ctr-sp a {
    justify-content: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}

/* --- btn-wh --- */
/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--bg-cta);
}
.btn-cta a:hover {
  background-color: var(--bg-cta-hvr);
}

@media screen and (max-width: 47.9375em) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 0.5714285714rem !important;
  }
}
/* ##############################################################################

  FORMY

############################################################################## */
#formy_form table {
  width: 100%;
}

#formy_form th,
#formy_form td {
  padding: 1rem;
  vertical-align: middle;
  border-bottom: solid 1px #eee;
}
@media screen and (max-width: 47.9375em) {
  #formy_form th,
  #formy_form td {
    display: block;
    width: auto !important;
    padding-left: 0;
    padding-right: 0;
  }
}

#formy_form th {
  font-weight: 500;
  white-space: nowrap;
  text-align: left;
}
@media print, screen and (min-width: 48em) {
  #formy_form th {
    width: 34%;
  }
}
@media screen and (max-width: 47.9375em) {
  #formy_form th {
    width: 100%;
    border-bottom: none;
    padding-bottom: 0;
    white-space: normal;
    font-weight: bold;
  }
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form table textarea {
  width: 100%;
  border-radius: 0.1875rem;
  vertical-align: bottom;
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form select,
#formy_form textarea {
  margin: 0;
  padding: 0.3125rem 0.9375rem;
  border: 1px solid #ccc;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  font-size: max(14px, 1rem);
  transition: var(--transit-default);
}

#formy_form textarea {
  height: 6.25rem;
}

#formy_form select {
  height: 2.5rem;
}

#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#formy_form input:hover {
  opacity: 0.7;
}

#formy_form textarea:hover {
  opacity: 0.7;
}

#formy_form input:focus {
  outline: none;
}

#formy_form .parsley-validated {
  background-color: #eee;
}

#formy_form .parsley-error {
  background-color: #fee;
}

#formy_form .parsley-success {
  background-color: var(--color-wht);
}

.help_text {
  font-size: max(12px, 0.875rem);
  color: #999;
}

.hidden_help {
  display: none;
}

.formy_privacy div {
  overflow-y: scroll;
  height: 8.75rem;
  border: solid 1px #ccc;
  font-size: max(12px, 0.875rem);
  padding: 0.5rem 1rem;
}

.requiredIcon {
  background-color: #f55;
  color: var(--color-wht);
  margin: 0 0 0 1em;
  font-size: max(10px, 0.75rem);
  padding: 0.125rem 0.3125rem;
  border-radius: 0.1875rem;
  float: right;
}

#formy_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: 2rem;
  text-align: center;
}

#formy_btn input {
  font-size: inherit;
  border: none;
  cursor: pointer;
  color: var(--color-wht);
  border-radius: 0.1875rem;
  padding: 1rem 2rem;
  -webkit-appearance: none;
  appearance: none;
  font-family: var(--font-primary);
  transition: var(--transit-default);
}
#formy_btn input:not(:first-child) {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  #formy_btn input {
    width: 100%;
    background-size: contain;
    background-position: center;
  }
}

#formy_form ul li input[type=radio],
#formy_form ul li input[type=checkbox] {
  display: none !important;
}

#formy_form ul li label {
  position: relative;
  display: inline-block;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
  transition: var(--transit-default);
}

#formy_form ul li label:hover {
  opacity: 0.7;
}

#formy_form ul li label::before {
  content: "";
  position: absolute;
  top: 0.75rem;
  left: 1rem;
  width: 1rem;
  height: 1rem;
  border: 2px solid #788b93;
}

#formy_form ul li input[type=radio] + label::before {
  border-radius: 0.625rem;
}

#formy_form ul li input[type=radio]:checked + label,
#formy_form ul li input[type=checkbox]:checked + label {
  color: #e75f5f;
  font-weight: bold;
}

#formy_form ul li input[type=radio]:checked + label::before,
#formy_form ul li input[type=checkbox]:checked + label::before {
  border-color: #e75f5f;
}

#formy_form ul li input[type=radio]:checked + label::after,
#formy_form ul li input[type=checkbox]:checked + label::after {
  content: "";
  width: 0.625rem;
  height: 1.125rem;
  top: 0.25rem;
  left: 1.25rem;
  border-right: 2px solid #e75f5f;
  border-bottom: 2px solid #e75f5f;
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}

.formy_confirm {
  background-color: #4dbaff;
}

.formy_submit_disabled {
  background-color: #ccc;
}

#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}

#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: 0.5rem 1.6875rem;
  min-width: auto;
  font-size: max(13px, 0.9375rem);
  border: 1px solid #aaa;
  border-radius: 2rem;
}

.formy_send {
  background-color: #ff6600;
}

#total_required {
  padding: 1rem;
  color: #f55555;
  text-align: center;
}

@media print, screen and (min-width: 48em) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@media screen and (max-width: 47.9375em) {
  #formy_form td {
    padding-top: 0;
  }
  .autoConfirmBack {
    margin-bottom: 0.5714285714rem;
  }
}
/* ##############################################################################

  FORM

############################################################################## */
.nf-form-cont .nf-form-content label,
.nf-form-cont .nf-field-label .nf-label-span {
  font-size: max(16px, 1.125rem);
}
@media screen and (max-width: 47.9375em) {
  .nf-form-cont .nf-form-content label,
  .nf-form-cont .nf-field-label .nf-label-span {
    font-size: 1.0714285714rem;
    margin-bottom: 6px !important;
  }
}

.nf-form-cont .nf-form-content .nf-label-span {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}

.nf-form-cont .nf-form-fields-required {
  display: none !important;
}

.nf-form-cont .nf-field-element {
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}

#ninja_forms_required_items,
.nf-form-cont .nf-field-container {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  #ninja_forms_required_items,
  .nf-form-cont .nf-field-container {
    margin-bottom: 1.1428571429rem;
  }
}

.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element > div,
.nf-form-cont .nf-form-content input:not([type=button]),
.nf-form-cont .nf-form-content textarea {
  background: var(--color-wht);
  color: var(--color-text-primary);
  border-radius: 0.1875rem;
  vertical-align: bottom;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  font-size: max(14px, 1rem);
  transition: var(--transit-default, all 0.4s ease-out);
  font-weight: 500;
}

.nf-form-cont .nf-form-content .list-select-wrap .nf-field-element > div:focus,
.nf-form-cont .nf-form-content input:not([type=button]):focus,
.nf-form-cont .nf-form-content textarea:focus {
  color: var(--color-text-primary);
}

.nf-form-content .list-checkbox-wrap .nf-field-element li label,
.nf-form-content .list-image-wrap .nf-field-element li label,
.nf-form-content .list-radio-wrap .nf-field-element li label {
  cursor: pointer;
}

.nf-form-content input[type=checkbox]:focus + label:after,
.nf-form-content input[type=radio]:focus + label:after {
  box-shadow: none;
}

.checkbox-wrap .nf-field-element label.nf-checked-label:before,
.checkbox-wrap .nf-field-label label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-element label.nf-checked-label:before,
.listcheckbox-wrap .nf-field-label label.nf-checked-label:before {
  color: var(--color-primary-default);
}

.nf-form-cont .ninja-forms-req-symbol {
  font-size: 0 !important;
}
.nf-form-cont .ninja-forms-req-symbol::before {
  content: "必須"; /* 必須 */
  background-color: #f55;
  color: var(--color-wht);
  margin-left: 0.5em;
  font-size: max(10px, 0.75rem);
  padding: 0.125rem 0.3125rem;
  border-radius: 0.1875rem;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
}

.nf-form-cont .nf-form-content .submit-wrap {
  text-align: center;
}

.nf-form-cont .nf-form-content .submit-wrap input:not([type=button]) {
  max-width: 100%;
  width: 20rem;
  height: 4rem;
  font-size: max(16px, 1.125rem);
  line-height: 1;
  letter-spacing: 0.1em;
  color: var(--color-wht);
  background-color: var(--color-primary-default);
  border-radius: 3.125rem;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif !important;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (min-width: 48em) {
  .nf-form-cont .nf-form-content .submit-wrap input:not([type=button]) {
    margin-top: 1.7142857143rem;
  }
}

.nf-form-cont .nf-form-errors {
  margin-top: -1em;
  text-align: center;
}

/* リキャプチャ・キャプション
**************************************** */
.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha_policy {
  padding: 0;
  margin-top: 3rem;
  color: var(--color-text-primary);
  font-size: max(8px, 0.625rem);
  text-align: center;
  letter-spacing: 0;
}
@media screen and (max-width: 47.9375em) {
  .recaptcha_policy {
    text-align: left;
  }
}

.recaptcha_policy a {
  color: #69ABDB;
  font-size: max(10px, 0.75rem);
  text-decoration: underline;
}

/* --- section_pdg --- */
.section_pdg {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg {
    padding-top: 2.8571428571rem;
    padding-bottom: 2.8571428571rem;
  }
}

.section_pdg-sm {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg-sm {
    padding-top: 1.7142857143rem;
    padding-bottom: 1.7142857143rem;
  }
}

/* --- inner --- */
.inner {
  width: 90%;
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .inner {
    width: 86%;
  }
}

.inner-sm {
  max-width: 45rem;
}

.inner-lg {
  max-width: 91rem;
}

.inner-xl {
  max-width: 105rem;
}

/* --- anchor --- */
.anchor {
  position: absolute;
  top: -4.5rem;
  left: 0;
  pointer-events: none;
}
@media screen and (max-width: 67.5em) {
  .anchor .anchor {
    top: -4rem;
  }
}

/* section-contact
********************************************** */
.section-contact {
  padding-top: 0;
}
.section-contact .cta_tel {
  width: 100%;
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
}
.section-contact .cta_tel a {
  padding: 1.5rem 2.5rem;
  color: #fff;
  background-color: var(--clr-main);
}
@media screen and (max-width: 47.9375em) {
  .section-contact .cta_tel a {
    display: block;
    padding: 1.1428571429rem;
  }
}

/* flex
**************************************** */
/* --- ブロック要素 --- */
.flex {
  display: flex;
}

/* --- インライン要素 --- */
.flex-inline {
  display: inline-flex;
}

/* --- 逆向き --- */
.flex-reverse {
  flex-direction: row-reverse;
}

/* --- 縦並び --- */
.flex-column {
  flex-direction: column;
}

/* --- 水平方向揃え --- */
.flex-j-start {
  justify-content: flex-start;
}

.flex-j-end {
  justify-content: flex-end;
}

.flex-j-ctr {
  justify-content: center;
}

.flex-j-between {
  justify-content: space-between;
}

.flex-j-around {
  justify-content: space-around;
}

/* --- 垂直方向揃え --- */
.flex-a-start {
  align-items: flex-start;
}

.flex-a-end {
  align-items: flex-end;
}

.flex-a-ctr {
  align-items: center;
}

.flex-a-baseline {
  align-items: baseline;
}

.flex-a-stretch {
  align-items: stretch;
}

/* --- 子要素の折り返し設定 --- */
.flex-c-nowrap {
  flex-wrap: nowrap;
}

.flex-c-wrap {
  flex-wrap: wrap;
}

/* --- 子要素の複数行設定 --- */
.flex-c-reverse {
  flex-wrap: wrap-reverse;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-end {
  align-content: flex-end;
}

.flex-c-ctr {
  align-content: center;
}

.flex-c-baseline {
  align-content: baseline;
}

.flex-c-stretch {
  align-content: stretch;
}

@media screen and (max-width: 47.9375em) {
  /* --- 縦並び - sp --- */
  .flex-sp-block {
    flex-direction: column;
  }
}
.flex-col2,
.flex-col3,
.flex-col4,
.flex-col5 {
  flex-wrap: wrap;
}

.flex-col1 > * {
  width: 100%;
}

.flex-col2 > * {
  width: 50%;
}

.flex-col3 > * {
  width: 33.3333333333%;
}

.flex-col4 > * {
  width: 25%;
}

.flex-col5 > * {
  width: 20%;
}

.flex-col2.gap {
  gap: 4%;
}

.flex-col3.gap {
  gap: 3%;
}

.flex-col4.gap {
  gap: 2%;
}

.flex-col5.gap {
  gap: 1%;
}

.flex-col2.gap > * {
  width: 48%;
  margin-bottom: 4%;
}

.flex-col3.gap > * {
  width: 31.33%;
  margin-bottom: 3%;
}

.flex-col4.gap > * {
  width: 23.5%;
  margin-bottom: 2%;
}

.flex-col5.gap > * {
  width: 19.2%;
  margin-bottom: 1%;
}

@media screen and (min-width: 48em) {
  .flex-col2.gap-wide {
    gap: 6%;
  }
  .flex-col3.gap-wide {
    gap: 5%;
  }
  .flex-col4.gap-wide {
    gap: 4%;
  }
  .flex-col5.gap-wide {
    gap: 3%;
  }
  .flex-col2.gap-wide > * {
    width: 47%;
    margin-bottom: 6%;
  }
  .flex-col3.gap-wide > * {
    width: 30%;
    margin-bottom: 5%;
  }
  .flex-col4.gap-wide > * {
    width: 22%;
    margin-bottom: 4%;
  }
  .flex-col5.gap-wide > * {
    width: 17.6%;
    margin-bottom: 3%;
  }
  .flex-col2.column-gap > *,
  .flex-col3.column-gap > *,
  .flex-col4.column-gap > *,
  .flex-col5.column-gap > * {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 47.9375em) {
  .flex-col2.flex-sp-block > *,
  .flex-col3.flex-sp-block > *,
  .flex-col4.flex-sp-block > *,
  .flex-col5.flex-sp-block > * {
    width: 100%;
  }
  .flex-col2.gap.flex-sp-block > *,
  .flex-col3.gap.flex-sp-block > *,
  .flex-col4.gap.flex-sp-block > *,
  .flex-col5.gap.flex-sp-block > * {
    margin-bottom: 1.7142857143rem;
  }
  .flex-col2.gap.flex-sp-block > *:last-child,
  .flex-col3.gap.flex-sp-block > *:last-child,
  .flex-col4.gap.flex-sp-block > *:last-child,
  .flex-col5.gap.flex-sp-block > *:last-child {
    margin-bottom: 0;
  }
  .flex-sp-col3 > * {
    width: 33.3333333333%;
  }
  .flex-sp-col2 > * {
    width: 50%;
  }
  .flex-sp-col2.gap {
    gap: 4%;
  }
  .flex-sp-col3.gap {
    gap: 3%;
  }
  .flex-sp-col2.gap > * {
    width: 48%;
    margin-bottom: 4%;
  }
  .flex-sp-col3.gap > * {
    width: 31.33%;
    margin-bottom: 3%;
  }
}
/* ##############################################################################

    FOOTER

############################################################################## */
.footer {
  background-color: var(--bg-main);
}
.footer > .inner {
  max-width: 1424px;
  padding-top: 7.5rem;
  padding-bottom: 8.125rem;
}
@media screen and (max-width: 47.9375em) {
  .footer > .inner {
    align-items: inherit;
    padding-top: 3.75rem;
    padding-bottom: 1.875rem;
  }
}

.works_fixed {
  position: fixed;
  bottom: 3.5rem;
  right: 2.5rem;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10.5rem;
  height: 10.5rem;
  transition: var(--transit-default);
  opacity: 0;
  pointer-events: none;
}
@media screen and (max-width: 47.9375em) {
  .works_fixed {
    bottom: 1.7142857143rem;
    right: 1.4285714286rem;
    width: 7.1428571429rem;
    height: 7.1428571429rem;
  }
}
.works_fixed.active {
  opacity: 1;
  pointer-events: auto;
}
.works_fixed a {
  width: 100%;
  height: 100%;
  background-color: #293440;
  border-radius: 50%;
  transition: var(--transit-default);
}
.works_fixed a:hover {
  opacity: 0.7;
}

.works_fixed .ttl .en {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  letter-spacing: 0.14em;
  line-height: 1;
  opacity: 0.2;
}
@media screen and (max-width: 47.9375em) {
  .works_fixed .ttl .en {
    margin-bottom: 0.8571428571rem;
    font-size: 0.7142857143rem;
  }
}
.works_fixed .ttl .jp {
  font-size: 1.25rem;
  line-height: 1;
  letter-spacing: 0.18em;
}
@media screen and (max-width: 47.9375em) {
  .works_fixed .ttl .jp {
    font-size: 1rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .footer--lft {
    margin-bottom: 2.2857142857rem;
  }
}

.footer--logo {
  margin-bottom: 2.375rem;
}
@media screen and (max-width: 47.9375em) {
  .footer--logo {
    text-align: center;
  }
}
.footer--logo img {
  width: 15.5rem;
}

.footer--info {
  margin-bottom: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .footer--info {
    text-align: center;
  }
}

.footer--info p {
  font-size: 14px;
  line-height: 2;
  letter-spacing: 0.05em;
}
.footer--info p .postcord {
  margin-right: 1rem;
}
@media screen and (max-width: 47.9375em) {
  .footer--info p .postcord {
    display: block;
  }
}
.footer--info p .tel {
  display: inline-block;
  margin-right: 1rem;
}

/* ---fnav --- */
.fnav {
  column-gap: 1.5rem;
}

.fnav--menu .menu-item:not(:last-child) {
  margin-right: 1.75rem;
}
@media screen and (max-width: 47.9375em) {
  .fnav--menu .menu-item:not(:last-child) {
    margin-right: 0;
  }
}

.fnav--menu a {
  display: block;
  font-size: 14px;
  line-height: 2.5;
}
@media screen and (max-width: 47.9375em) {
  .fnav--menu a {
    font-size: 1rem;
  }
}
.fnav--menu a[href="javascript:void(0);"] {
  pointer-events: none;
}
.fnav--menu a:hover {
  opacity: 0.7;
}

.fnav--menu .sub-menu a {
  position: relative;
  padding-left: 1.5rem;
  color: rgba(255, 255, 255, 0.5019607843);
  line-height: 2;
}
.fnav--menu .sub-menu a::before {
  content: "";
  position: absolute;
  top: 0.875rem;
  left: 0;
  width: 1rem;
  height: 1px;
  border: 1px solid var(--clr-main);
}
@media screen and (max-width: 47.9375em) {
  .fnav--menu .sub-menu a::before {
    top: 0.8571428571rem;
    width: 0.8571428571rem;
  }
}

.footer--rgt {
  padding-bottom: 0.375rem;
}

/* ---sns_area --- */
.sns_area {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .sns_area {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.sns--list {
  height: 32px;
}
@media screen and (max-width: 47.9375em) {
  .sns--list {
    height: 24px;
  }
}
@media screen and (max-width: 47.9375em) {
  .sns--list {
    justify-content: center;
  }
}

.sns--list li:not(:first-child) {
  margin-left: 1rem;
}

.sns--list a {
  display: block;
  width: 32px;
  height: 32px;
}
@media screen and (max-width: 47.9375em) {
  .sns--list a {
    width: 24px;
    height: 24px;
  }
}
.sns--list a:hover {
  opacity: 0.7;
}

.sns--list svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}

@media screen and (max-width: 47.9375em) {
  .privacy {
    text-align: center;
    margin-bottom: 0.5em;
  }
}

.privacy a:hover {
  opacity: 0.7;
}

.copyright p:not(:last-child) {
  font-size: 0.75rem;
  line-height: 1.8333333333;
  color: rgba(190, 190, 190, 0.5);
}
@media print, screen and (min-width: 48em) {
  .copyright p:not(:last-child) {
    margin-right: 1.75rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .copyright p:not(:last-child) {
    margin-bottom: 0.5em;
  }
}

.pbl a,
.pbl img {
  display: block;
}

.pbl a {
  opacity: 0.3;
}
.pbl a:hover {
  opacity: 1;
}

.footer_btm {
  padding-block: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .footer_btm {
    padding-block: 1.4285714286rem;
  }
}
.footer_btm .copyright {
  justify-content: center;
}

/* ##############################################################################

    HEADER

############################################################################## */
/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 7.1875rem;
  z-index: 9999;
  transition: var(--transit-default);
}
.header > .inner {
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding-left: 2.9166666667vw;
}
@media screen and (max-width: 67.5em) {
  .header {
    height: 4.5714285714rem;
    background-color: #fff;
  }
  .header > .inner {
    padding-left: 1rem;
  }
}

.page-contact-simple .header {
  position: absolute;
}

.header-sm {
  background-color: #fff;
  border-bottom: inherit;
  box-shadow: 0px 0 0.625rem 0px rgba(39, 47, 61, 0.2);
}
@media print, screen and (min-width: 67.5625em) {
  .header-sm {
    height: 4.5rem;
  }
}

.ipad .header > .inner {
  padding-left: 0.5rem;
}

/* --- logo -- */
.header--logo a,
.footer--logo a {
  display: block;
}
.header--logo a:hover,
.footer--logo a:hover {
  opacity: 0.7;
}

.header--logo {
  position: relative;
  z-index: 999;
}

.header--logo img {
  width: 14.625rem;
}
@media screen and (max-width: 67.5em) {
  .header--logo img {
    width: auto;
    max-height: 2.5rem;
  }
}

.ipad .header--logo img {
  width: 11.25rem;
}

/* gnav
********************************************** */
@media print, screen and (min-width: 67.5625em) {
  .gnav,
  .gnav ul,
  .gnav li,
  .gnav a {
    height: 100%;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav {
    display: flex;
    color: #fff;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav {
    position: fixed;
    top: 4.5714285714rem;
    right: 0;
    z-index: 300;
    width: 100%;
    height: calc(100% - 4.5714285714rem);
    margin: 0;
    padding: 6.25rem 7%;
    opacity: 0;
    pointer-events: none;
    overflow: scroll;
    background-color: var(--bg-main);
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
    transition: var(--transit-default);
  }
  .gnav.active {
    opacity: 1;
    pointer-events: auto;
    transform: translateX(0);
  }
}

.gnav a {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* --- menu --- */
@media screen and (max-width: 67.5em) {
  .gnav--menu,
  .gnav--cta {
    display: block;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav--menu {
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav--menu > li {
    position: relative;
    display: block;
    width: 100%;
    border-bottom: 1px solid #fff;
  }
}

.gnav--link {
  position: relative;
  padding: 0 1.25vw;
  font-size: 0.9375rem;
  letter-spacing: 0.1em;
  color: #fff;
}
@media screen and (max-width: 67.5em) {
  .gnav--link[href="javascript:void(0);"] {
    color: #fff;
  }
}
.gnav--link::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: var(--clr-main);
  opacity: 0;
  transition: var(--transit-default);
}
@media screen and (max-width: 67.5em) {
  .gnav--link {
    display: block;
    justify-content: inherit !important;
    font-size: 1rem;
    padding: 1.125rem 1rem;
  }
  .gnav--link::before {
    background-color: #fff;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--link {
    text-align: center;
  }
}

.ipad .gnav--link {
  padding: 0.25rem 0.8333333333vw;
}

.header-sm .gnav--link {
  color: var(--clr-body);
}
@media screen and (max-width: 75em) {
  .header-sm .gnav--link {
    padding: 0 0.625vw;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav--menu li:not(.menu-item-has-children) a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: auto;
    right: 0.7142857143rem;
    margin: auto;
    width: 0.5rem;
    height: 0.5rem;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    background-color: inherit;
    opacity: 1;
    transform: rotate(45deg);
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav--menu > li:hover .gnav--link[href="javascript:void(0);"]::before,
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover::before,
  .gnav--menu > li.current-menu-item > .gnav--link::before {
    opacity: 1;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover {
    color: var(--clr-main);
  }
}

/* --- sub_nav --- */
.menu-item-has-children {
  position: relative;
}

.head_sub_nav--wrap {
  position: absolute;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 18.75rem;
  transition: var(--transit-default);
  opacity: 0;
  pointer-events: none;
}
@media screen and (max-width: 67.5em) {
  .head_sub_nav--wrap {
    position: relative;
    width: 100%;
    border-top: 1px solid #fff;
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
}

@media screen and (max-width: 67.5em) {
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
}

@media screen and (max-width: 67.5em) {
  .head_sub_nav {
    position: relative;
  }
}

.head_sub_nav li:not(:last-child) {
  border-bottom: 1px solid #fff;
}

.head_sub_nav a {
  display: block;
  padding: 0.75rem 1.25rem;
  color: var(--clr-body);
  border-bottom: 2px solid transparent;
}
@media print, screen and (min-width: 67.5625em) {
  .head_sub_nav a {
    background-color: var(--bg-wht);
  }
}
@media screen and (max-width: 67.5em) {
  .head_sub_nav a {
    padding-left: 2rem !important;
    color: #fff;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .head_sub_nav a:hover {
    border-bottom: 2px solid var(--clr-main);
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav_sub_toggle {
    display: none;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    right: -1.25rem;
    width: 4rem;
    height: 4rem;
    transition: var(--transit-default);
    color: #fff;
    cursor: pointer;
  }
  .gnav_sub_toggle::before, .gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0.6875rem;
    height: 1px;
    border-bottom: 1px solid;
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle.active {
    transform: rotate(180deg);
  }
}

/* ---btn --- */
@media print, screen and (min-width: 67.5625em) {
  .gnav_btn,
  .tel_btn {
    display: none;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav_btn,
  .tel_btn {
    position: fixed;
    top: 0;
    width: 4.5714285714rem;
    height: 4.5714285714rem;
    cursor: pointer;
    z-index: 9999;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav_btn {
    right: 0;
    transition: var(--transit-default);
  }
}

@media screen and (max-width: 67.5em) {
  .gnav_btn--lines {
    position: relative;
    width: 1.7142857143rem;
    height: 1.1428571429rem;
  }
}

@media screen and (max-width: 67.5em) {
  .gnav_btn--lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--clr-main);
    transition: var(--transit-default);
  }
  .gnav_btn--lines span:nth-of-type(1) {
    top: 0;
  }
  .gnav_btn--lines span:nth-of-type(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  .gnav_btn--lines span:nth-of-type(3) {
    bottom: 0;
  }
}

@media screen and (max-width: 67.5em) {
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(0.5rem) rotate(-45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    opacity: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-0.5rem) rotate(45deg);
  }
}

@media screen and (max-width: 67.5em) {
  .tel_btn {
    right: 4.5714285714rem;
  }
}
@media screen and (max-width: 67.5em) {
  .tel_btn a {
    display: flex !important;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 67.5em) {
  .tel_btn svg {
    width: 1.7142857143rem;
    height: 1.7142857143rem;
    fill: var(--clr-main);
  }
}

/* ---CTA --- */
.cta_tel a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
}

.cta_tel a > span {
  display: block;
}

.cta_tel .tel {
  font-size: 1.5rem;
}

.cta_tel .num {
  font-size: 1.875rem;
}

@media screen and (max-width: 67.5em) {
  .gnav--cta li:not(:last-child) {
    margin-bottom: 1.1428571429rem;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .gnav--tel {
    margin-left: 1rem;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav--tel {
    margin-left: 0;
    text-align: center;
  }
}

.gnav--tel a {
  display: flex !important;
  flex-direction: column;
  padding-bottom: 0.625rem;
}

.header-sm .gnav--tel a {
  padding-bottom: 0.25rem;
}

.gnav--tel .tel {
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: -0.02em;
  color: var(--clr-main);
  text-shadow: 0.125rem 0.25rem 1.875rem rgba(217, 231, 255, 0.6);
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 67.5em) {
  .gnav--tel .tel {
    font-size: 1.2857142857rem;
    color: #fff;
    text-shadow: inherit;
  }
}

.header-sm .gnav--tel .tel {
  font-size: 0.875rem;
}

.gnav--tel .num {
  font-size: 1.5rem;
  letter-spacing: -0.02em;
}
@media screen and (max-width: 90em) {
  .gnav--tel .num {
    font-size: max(1.25vw, 1.125rem);
  }
}
@media screen and (max-width: 67.5em) {
  .gnav--tel .num {
    font-size: 2rem;
  }
}

.header-sm .gnav--tel .num {
  font-size: 1.1458333333vw;
}

.ipad .gnav--tel .tel {
  font-size: 0.75rem;
}
.ipad .gnav--tel .num {
  font-size: 1rem;
}

.gnav--contact {
  margin-left: 3.75vw;
}
@media screen and (max-width: 90em) {
  .gnav--contact {
    margin-left: 1.5rem;
  }
}
@media screen and (max-width: 67.5em) {
  .gnav--contact {
    margin-left: 0;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .header-sm .gnav--contact {
    margin-left: 1.75rem;
  }
}

.gnav--contact a {
  font-size: 15px;
  line-height: 1;
  letter-spacing: 0.04em;
  color: #fff;
  background-color: var(--clr-main);
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--contact a {
    flex-direction: column;
    gap: 1rem;
    width: 120px;
    padding: 1rem 0;
    border-radius: 0;
  }
  .gnav--contact a:hover {
    background-color: var(--bg-main);
  }
}
@media screen and (max-width: 67.5em) {
  .gnav--contact a {
    padding: 1.4285714286rem !important;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.2857142857rem;
  }
}
.gnav--contact a svg {
  width: 1.375rem;
  height: 1rem;
  fill: #fff;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 67.5em) {
  .gnav--contact a svg {
    width: 1.5rem;
    height: 1.25rem;
  }
}

@media print, screen and (min-width: 67.5625em) {
  .header-sm .gnav--contact a {
    flex-direction: inherit;
    width: 12.375rem;
  }
}

/* common
********************************************** */
.home--ttl {
  position: relative;
}
.home--ttl::after {
  content: "";
  display: block;
  width: 7.5rem;
  height: 2px;
  margin-top: 2.25rem;
  background: linear-gradient(90deg, var(--clr-main) 0%, var(--clr-main) 50%, #c9c9c9 50%, #c9c9c9 100%);
}
@media screen and (max-width: 47.9375em) {
  .home--ttl::after {
    width: 4.2857142857rem;
    margin-top: 1.2857142857rem;
  }
}
.home--ttl .en {
  display: block;
  margin-bottom: 1.75rem;
  font-size: 5.625rem;
  letter-spacing: 0.14em;
  line-height: 0.8;
}
@media screen and (max-width: 47.9375em) {
  .home--ttl .en {
    margin-bottom: 1rem;
    font-size: 3.4285714286rem;
  }
}
.home--ttl .jp {
  display: block;
  letter-spacing: 0.2em;
}
.home--ttl .jp.jp-40 {
  font-size: 2.5rem;
  line-height: 1.6;
}
@media screen and (max-width: 47.9375em) {
  .home--ttl .jp.jp-40 {
    font-size: 1.7142857143rem;
  }
}
.home--ttl .jp.jp-48 {
  font-size: 3rem;
  line-height: 1.625;
}
@media screen and (max-width: 47.9375em) {
  .home--ttl .jp.jp-48 {
    font-size: 2rem;
  }
}

.home--sub_ttl {
  letter-spacing: 0.14em;
}
.home--sub_ttl.jp-28 {
  font-size: 1.75rem;
  line-height: 1.6428571429;
}
@media screen and (max-width: 47.9375em) {
  .home--sub_ttl.jp-28 {
    font-size: 1.4285714286rem;
  }
}
.home--sub_ttl.jp-32 {
  font-size: 2rem;
  line-height: 1.5625;
}
@media screen and (max-width: 47.9375em) {
  .home--sub_ttl.jp-32 {
    font-size: 1.5714285714rem;
  }
}
.home--sub_ttl .en {
  line-height: 1;
}
.home--sub_ttl .jp.jp-28 {
  font-size: 1.75rem;
  line-height: 1.6428571429;
}
@media screen and (max-width: 47.9375em) {
  .home--sub_ttl .jp.jp-28 {
    font-size: 1.4285714286rem;
  }
}

/* hero
********************************************** */
.hero {
  height: 59.375rem;
  overflow: hidden;
}
@media screen and (max-width: 67.5em) {
  .hero {
    overflow: hidden;
  }
}
@media screen and (max-width: 47.9375em) {
  .hero {
    height: 42.8571428571rem;
  }
}

.hero .deco {
  position: absolute;
}
.hero .deco.deco-sm {
  top: 18.375rem;
  left: 0.375rem;
  width: 7.5rem;
  height: 7.5rem;
}
.hero .deco.deco-lg {
  top: 7.875rem;
  left: 33.125rem;
  width: 16.875rem;
  height: 16.875rem;
}

.hero--img {
  position: relative;
  width: 81.25rem;
  max-width: 100%;
  height: 100%;
  margin-left: auto;
}
@media screen and (max-width: 47.9375em) {
  .hero--img {
    height: 100%;
    background-color: #eee;
  }
}

.ipad .hero--img {
  width: 100%;
}

.hero--img .hero--slider {
  position: relative;
  width: 100%;
  height: 100%;
}

.hero--slider .slick-list,
.hero--slider .slick-track {
  height: 100% !important;
  overflow: inherit !important;
}

.hero--slider .slider,
.hero--slider .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
}

.hero--slider .img {
  overflow: hidden;
}
.hero--slider .img .img-on {
  position: relative;
  z-index: 1;
}

.hero--slider .slider.add-ani img {
  animation: scale 6s 0s forwards;
}

.hero--slider .hero--deco {
  position: absolute;
  left: calc(-100vw + 81.25rem);
  transform: translate3d(0, 0, 0);
}
@media screen and (max-width: 67.5em) {
  .hero--slider .hero--deco {
    left: 0;
  }
}
@media screen and (max-width: 47.9375em) {
  .hero--slider .hero--deco {
    clip-path: inset(0 100% 0 0);
  }
  .hero--slider .hero--deco.active {
    animation: brush 0.5s cubic-bezier(0.08, 0.41, 0.19, 0.95) forwards;
  }
}
.hero--slider .hero--deco svg {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 47.9375em) {
  .hero--slider .hero--deco .hero_wreath {
    display: none;
  }
}
.hero--slider .hero--deco.hero--deco01 {
  top: -13.375rem;
  width: 86.6666666667vw;
  height: 33.90625vw;
}
@media screen and (max-width: 47.9375em) {
  .hero--slider .hero--deco.hero--deco01 {
    top: 0;
    width: 100vw;
    height: 39.1304347826vw;
  }
}
.hero--slider .hero--deco.hero--deco02 {
  top: -8.75rem;
  width: 119.8958333333vw;
  height: 52.65625vw;
}
@media screen and (max-width: 47.9375em) {
  .hero--slider .hero--deco.hero--deco02 {
    top: -5.7971014493vw;
    left: -15.4589371981vw;
    width: 112.077294686vw;
    height: 50.2415458937vw;
  }
}

.ipad .hero--slider .hero--deco {
  left: 0;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.hero--catch_weapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 81.25rem;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .hero--catch_weapper {
    width: 100%;
  }
}
@media screen and (max-width: 47.9375em) {
  .hero--catch_weapper .hero--catch {
    left: 0;
    bottom: 2.8571428571rem;
    height: 3.2857142857rem;
    z-index: 1;
    display: block;
  }
}

.ipad .hero--catch_weapper {
  width: 100%;
}

.hero--catch_weapper .hero--catch {
  left: calc(-100vw + 81.25rem);
}
@media screen and (max-width: 67.5em) {
  .hero--catch_weapper .hero--catch {
    left: 0;
  }
}

.ipad .hero--catch_weapper .hero--catch {
  left: 0;
}

.hero--ttl {
  position: absolute;
  top: 50%;
  left: 6.25vw;
  transform: translateY(-50%);
  white-space: nowrap;
  line-height: 1.375;
  letter-spacing: 0.2em;
  text-shadow: 0 0 1.25rem #fff;
}
@media screen and (max-width: 47.9375em) {
  .hero--ttl {
    top: auto;
    bottom: 9.7142857143rem;
    transform: inherit;
    font-size: 2.2857142857rem;
    color: #fff;
    text-shadow: 0 0 1.25rem var(--clr-main);
  }
}
.hero--ttl .sub {
  margin-bottom: 2.25rem;
}
@media screen and (max-width: 47.9375em) {
  .hero--ttl .sub {
    margin-bottom: 1.1428571429rem;
    font-size: 1.1428571429rem;
  }
}

.hero--catch {
  position: absolute;
  left: 0;
  bottom: 7.5rem;
  width: 100vw;
  height: 7.25rem;
  overflow: hidden;
}
@media screen and (max-width: 67.5em) {
  .hero--catch {
    width: 100%;
  }
}
@media screen and (max-width: 47.9375em) {
  .hero--catch {
    display: none;
  }
}
.hero--catch .catch_wrap {
  position: absolute;
  top: 0;
  left: 0;
  column-gap: 6.25rem;
  white-space: nowrap;
  animation: slider 100s linear infinite;
}
@media screen and (max-width: 47.9375em) {
  .hero--catch .catch_wrap {
    column-gap: 2.8571428571rem;
  }
}
.hero--catch .catch_wrap .catch {
  font-size: 9rem;
  line-height: 0.7;
  letter-spacing: normal;
  mix-blend-mode: difference;
}
@media screen and (max-width: 47.9375em) {
  .hero--catch .catch_wrap .catch {
    font-size: 4rem;
  }
}

@media screen and (max-width: 67.5em) {
  .home #wrapper {
    overflow: hidden;
  }
}
@media screen and (max-width: 47.9375em) {
  .home #wrapper {
    margin-top: -1.7142857143rem;
  }
}

/* home_news
********************************************** */
.home_news {
  position: relative;
  z-index: 1;
}

.home_news .news_box {
  position: absolute;
  top: 2.5rem;
  right: 0;
  width: 57.5rem;
  height: 3.5rem;
  margin-left: auto;
  background-color: #eee;
  border-radius: 0.5rem;
  box-shadow: 0px 0 0.625rem 0px rgba(39, 47, 61, 0.2);
}
@media screen and (max-width: 47.9375em) {
  .home_news .news_box {
    position: static;
    width: 93%;
    height: 3.4285714286rem;
    border-radius: 0.2857142857rem;
  }
}
.home_news .news_box .box_inner {
  column-gap: 1.25rem;
  height: 100%;
  padding-left: 8.4782608696%;
  padding-right: 13.9130434783%;
}
@media screen and (max-width: 47.9375em) {
  .home_news .news_box .box_inner {
    column-gap: 0.8571428571rem;
    padding-left: 1rem;
    padding-right: 5.7142857143rem;
  }
}

.news_box .box_inner svg {
  width: 1.25rem;
  height: 1.25rem;
}

.news_box .post {
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 47.9375em) {
  .news_box .post {
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
}

.news_box .post--date {
  font-size: 0.875rem;
}
@media screen and (max-width: 47.9375em) {
  .news_box .post--date {
    font-size: 0.7142857143rem;
  }
}

.news_box .post--ttl {
  flex: 1;
}
@media screen and (max-width: 47.9375em) {
  .news_box .post--ttl {
    flex: inherit;
    font-size: 0.8571428571rem;
  }
}

.news_box .post:hover .post--date,
.news_box .post:hover .post--ttl a {
  color: var(--clr-main);
}

.news_btn {
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
  font-size: 1rem;
  color: #959595;
}
@media screen and (max-width: 47.9375em) {
  .news_btn {
    right: 1rem;
    font-size: 0.7142857143rem;
  }
}
.news_btn:hover {
  opacity: 0.7;
}

/* home_about
********************************************** */
.home_about--imgarea {
  position: relative;
  max-width: 80.75rem;
  margin-left: auto;
}
@media screen and (max-width: 47.9375em) {
  .home_about--imgarea {
    z-index: -1;
  }
}
.home_about--imgarea .logo {
  position: absolute;
  top: 4rem;
  right: 20rem;
  z-index: -1;
  width: 52.75rem;
}
@media screen and (max-width: 47.9375em) {
  .home_about--imgarea .logo {
    top: 0;
    left: 7vw;
    right: auto;
    width: 21.4285714286rem;
  }
}
.home_about--imgarea .dot {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mask-image: url(../images/top/map-dot-mask.svg);
  mask-position: 72% 60%;
  mask-size: 0 0;
  mask-repeat: no-repeat;
  mask-clip: border-box;
  transition: mask-size 8s ease-out 1s;
}
.home_about--imgarea.active .dot {
  mask-size: 120rem 120rem;
}

.home_about--txtarea {
  position: absolute;
  top: 42%;
  left: 10.4166666667vw;
  transform: translateY(-50%);
}
@media screen and (max-width: 87.5em) {
  .home_about--txtarea {
    left: 5vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_about--txtarea {
    position: static;
    transform: inherit;
    width: 86%;
    margin: 1.7142857143rem auto 0;
  }
}
.home_about--txtarea .home--ttl {
  margin-bottom: 4.5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_about--txtarea .home--ttl {
    margin-bottom: 2.5714285714rem;
  }
}
.home_about--txtarea .home--ttl::after {
  margin-top: 3rem;
}
@media screen and (max-width: 47.9375em) {
  .home_about--txtarea .home--ttl::after {
    margin-top: 1.7142857143rem;
  }
}
.home_about--txtarea .home--sub_ttl {
  margin-bottom: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .home_about--txtarea .home--sub_ttl {
    margin-bottom: 1.1428571429rem;
  }
}
.home_about--txtarea .txt {
  margin-bottom: 3.25rem;
}
@media screen and (max-width: 47.9375em) {
  .home_about--txtarea .txt {
    margin-bottom: 1.8571428571rem;
  }
}

/* home_service
********************************************** */
.home_service {
  padding-top: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_service {
    padding-top: 4.2857142857rem;
  }
}

.service_top .service_top--imgarea::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 0;
  height: 100%;
  background-color: var(--clr-main);
}
.service_top .service_top--imgarea .img_slider {
  opacity: 0;
  transition-delay: 0.8s;
}
.service_top.active .service_top--imgarea::before {
  animation-name: block_appear;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.77, 0, 0.6, 1);
  animation-direction: normal;
  animation-fill-mode: forwards;
}
.service_top.active .service_top--imgarea .img_slider {
  opacity: 1;
}
.service_top > .inner {
  max-width: 92.5rem;
}

.service_top--imgarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 43.75vw;
  height: 100%;
}
@media screen and (max-width: 47.9375em) {
  .service_top--imgarea {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    height: auto;
    margin-bottom: 1.7142857143rem;
  }
}

.service_top--imgarea .img_slider {
  width: 100%;
  height: 100%;
}
.service_top--imgarea .img_slider .slick-list,
.service_top--imgarea .img_slider .slick-track {
  height: 100% !important;
}

.service_top--imgarea .slick-dots {
  position: absolute;
  right: -1.25rem;
  bottom: 1.25rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
  width: 3px;
}

.service_top--imgarea .slick-dots li {
  position: relative;
  width: 100%;
  height: 1.875rem;
  background-color: #aaaaaa;
}
.service_top--imgarea .slick-dots li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: var(--clr-main);
}
.service_top--imgarea .slick-dots li.slick-active::before {
  animation: dot_ani 5s linear forwards;
}
.service_top--imgarea .slick-dots li button {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  color: transparent;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.service_top--txtarea {
  width: 47.972972973%;
  min-height: 41.6666666667vw;
  padding-bottom: 8rem;
  margin-left: auto;
}
@media screen and (max-width: 47.9375em) {
  .service_top--txtarea {
    width: 100%;
    min-height: inherit;
    margin-left: 0;
    padding-bottom: 5.7142857143rem;
  }
}
.service_top--txtarea .home--ttl {
  margin-bottom: 3.375rem;
}
@media screen and (max-width: 47.9375em) {
  .service_top--txtarea .home--ttl {
    margin-bottom: 2.2857142857rem;
  }
}
.service_top--txtarea .home--sub_ttl {
  margin-bottom: 2.25rem;
}
@media screen and (max-width: 47.9375em) {
  .service_top--txtarea .home--sub_ttl {
    margin-bottom: 1.4285714286rem;
  }
}
.service_top--txtarea .txt {
  margin-bottom: 2.625rem;
}
@media screen and (max-width: 47.9375em) {
  .service_top--txtarea .txt {
    margin-bottom: 1.7142857143rem;
  }
}

.service_btm {
  margin-top: -2.25rem;
  background-color: #0a4cbf;
}
@media screen and (max-width: 47.9375em) {
  .service_btm {
    margin-top: 0;
  }
}
.service_btm > .inner {
  width: 96%;
  max-width: 104.375rem;
  margin-right: 0;
}
@media screen and (max-width: 47.9375em) {
  .service_btm > .inner {
    width: 100%;
  }
}
.service_btm > .inner .deco {
  position: absolute;
  bottom: -4.5rem;
  right: 9.125rem;
  width: 21.5rem;
  opacity: 0.5;
}
@media screen and (max-width: 47.9375em) {
  .service_btm > .inner .deco {
    bottom: -1.7142857143rem;
    right: 1.1428571429rem;
    width: 12.2857142857rem;
  }
}

.service_btm--imgarea {
  width: 35rem;
  margin-top: -4.0625vw;
  margin-left: 8.3333333333vw;
}
.service_btm--imgarea .img {
  position: relative;
}
.service_btm--imgarea .img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 0;
  height: 100%;
  background-color: var(--clr-main);
}
.service_btm--imgarea .img img {
  opacity: 0;
  transition-property: opacity;
  transition-delay: 0.35s;
}
.service_btm--imgarea.active .img::before {
  animation-name: block_appear;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.77, 0, 0.6, 1);
  animation-direction: normal;
  animation-fill-mode: forwards;
}
.service_btm--imgarea.active .img img {
  opacity: 1;
}
@media screen and (max-width: 87.5em) {
  .service_btm--imgarea {
    width: 30rem;
    margin-left: 3rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .service_btm--imgarea {
    width: 93%;
    margin-top: -2.2857142857rem;
    margin-left: auto;
  }
}

.service_btm--txtarea {
  flex: 1;
  padding-block: 8.625rem 6.5rem;
}
@media screen and (max-width: 47.9375em) {
  .service_btm--txtarea {
    width: 86%;
    padding-block: 3.4285714286rem 4rem;
    margin: 0 auto;
  }
}
.service_btm--txtarea .ttl {
  margin-bottom: 4.25rem;
  font-size: 1.875rem;
  letter-spacing: 0.14em;
}
@media screen and (max-width: 47.9375em) {
  .service_btm--txtarea .ttl {
    margin-bottom: 3rem;
    font-size: 1.5rem;
  }
}
.service_btm--txtarea .txt {
  padding-left: 82px;
  line-height: 2;
}
@media screen and (max-width: 47.9375em) {
  .service_btm--txtarea .txt {
    padding-left: 0;
  }
}

.service--list {
  padding-bottom: 4.1666666667vw;
  margin-bottom: 2.25rem;
  border-bottom: 1px solid rgba(210, 210, 210, 0.3019607843);
}
@media screen and (max-width: 47.9375em) {
  .service--list {
    row-gap: 3.4285714286rem;
    padding-bottom: 2.8571428571rem;
  }
}

.service--item {
  position: relative;
}
.service--item .num {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 6.25rem;
  line-height: 0.7;
  letter-spacing: -0.1em;
  color: rgba(185, 235, 243, 0.15);
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .service--item .num {
    font-size: 5.7142857143rem;
  }
}
.service--item .item--txt {
  position: absolute;
  top: 0.75rem;
  left: 8.5rem;
  font-size: 0.875rem;
  line-height: 0.7;
  letter-spacing: 0.14em;
  color: rgba(185, 235, 243, 0.2);
  white-space: nowrap;
  text-transform: uppercase;
}
@media screen and (max-width: 47.9375em) {
  .service--item .item--txt {
    left: 9.7142857143rem;
    font-size: 0.8571428571rem;
  }
}
.service--item .item--ttl {
  padding-top: 3.375rem;
  padding-left: 3rem;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: 0.14em;
}
@media screen and (max-width: 47.9375em) {
  .service--item .item--ttl {
    padding-top: 2.8571428571rem;
    padding-left: 9rem;
    font-size: 1.2857142857rem;
  }
}

/* home_new_works
********************************************** */
.home_new_works {
  position: relative;
  padding-block: 9.25rem;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .home_new_works {
    padding-block: 4.2857142857rem;
  }
}

.home_new_works--ttlarea {
  position: relative;
  max-width: 93.75rem;
  margin-bottom: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .home_new_works--ttlarea {
    margin-bottom: 2.2857142857rem;
  }
}
.home_new_works--ttlarea .deco {
  position: absolute;
  top: 3rem;
  left: 1.75rem;
  width: 12.75rem;
  opacity: 0.5;
}
@media screen and (max-width: 47.9375em) {
  .home_new_works--ttlarea .deco {
    width: 7.2857142857rem;
  }
}

.home_new_works--ttlarea .home--ttl {
  margin-bottom: 2.375rem;
}
@media screen and (max-width: 47.9375em) {
  .home_new_works--ttlarea .home--ttl {
    margin-bottom: 1.4285714286rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_new_works--ttlarea .home--ttl .en {
    font-size: 2.5714285714rem;
    white-space: nowrap;
  }
}

.home_new_works--content {
  position: relative;
  width: 100vw;
  height: 33.3333333333vw;
  transform: translateY(1.25rem);
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.home_new_works--content.active {
  transform: translateY(0);
  opacity: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_new_works--content {
    height: 77.2946859903vw;
  }
}

.home_new_works--slider {
  position: absolute;
  top: 0;
  left: 0;
  column-gap: 0.8333333333vw;
  white-space: nowrap;
  animation: slider 40s linear infinite;
}
@media screen and (max-width: 47.9375em) {
  .home_new_works--slider {
    column-gap: 1.9323671498vw;
    animation: slider 30s linear infinite;
  }
}
.home_new_works--slider:hover {
  animation-play-state: paused;
}

.home_new_works--list {
  display: grid;
  grid-template-rows: 16.25vw 16.25vw;
  grid-row-gap: 0.8333333333vw;
  grid-column-gap: 0.8333333333vw;
  width: fit-content;
  margin-left: auto;
}
@media screen and (max-width: 47.9375em) {
  .home_new_works--list {
    grid-template-rows: 37.6811594203vw 37.6811594203vw;
    grid-row-gap: 1.9323671498vw;
    grid-column-gap: 1.9323671498vw;
  }
}

.home_new_works--item {
  overflow: hidden;
}
.home_new_works--item:nth-of-type(1) {
  grid-column: 1/3;
  grid-row: 1/3;
}
.home_new_works--item:nth-of-type(2) {
  grid-column: 3/4;
  grid-row: 1/2;
}
.home_new_works--item:nth-of-type(3) {
  grid-column: 3/4;
  grid-row: 2/3;
}
.home_new_works--item:nth-of-type(4) {
  grid-column: 4/5;
  grid-row: 1/2;
}
.home_new_works--item:nth-of-type(5) {
  grid-column: 4/5;
  grid-row: 2/3;
}
.home_new_works--item:nth-of-type(6) {
  grid-column: 5/6;
  grid-row: 1/2;
}
.home_new_works--item:nth-of-type(7) {
  grid-column: 5/6;
  grid-row: 2/3;
}
.home_new_works--item:nth-of-type(8) {
  grid-column: 6/7;
  grid-row: 1/2;
}
.home_new_works--item:nth-of-type(9) {
  grid-column: 6/7;
  grid-row: 2/3;
}
.home_new_works--item:nth-of-type(10) {
  grid-column: 7/8;
  grid-row: 1/2;
}
.home_new_works--item:nth-of-type(11) {
  grid-column: 7/8;
  grid-row: 2/3;
}
.home_new_works--item:nth-of-type(12) {
  grid-column: 8/9;
  grid-row: 1/2;
}
.home_new_works--item:nth-of-type(13) {
  grid-column: 8/9;
  grid-row: 2/3;
}
.home_new_works--item:nth-of-type(14) {
  grid-column: 9/10;
  grid-row: 1/2;
}
.home_new_works--item:nth-of-type(15) {
  grid-column: 9/10;
  grid-row: 2/3;
}
.home_new_works--item:nth-of-type(16) {
  grid-column: 10/11;
  grid-row: 1/2;
}
.home_new_works--item:nth-of-type(17) {
  grid-column: 10/11;
  grid-row: 2/3;
}
.home_new_works--item:nth-of-type(18) {
  grid-column: 11/12;
  grid-row: 1/2;
}
.home_new_works--item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: var(--clr-body);
  opacity: 0;
  transition: var(--transit-default);
}
@media screen and (max-width: 47.9375em) {
  .home_new_works--item::before {
    opacity: 0.3;
  }
}

.home_new_works--item .item--img {
  transform: scale(1.1);
  transform-origin: center;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: var(--transit-default);
}
@media screen and (max-width: 47.9375em) {
  .home_new_works--item .item--img {
    transform: scale(1);
  }
}

.home_new_works--item .item--txtarea {
  position: absolute;
  bottom: 1.5rem;
  left: 2rem;
  z-index: 1;
  width: calc(100% - 4rem);
  opacity: 0;
  transition: var(--transit-default);
}
@media screen and (max-width: 47.9375em) {
  .home_new_works--item .item--txtarea {
    opacity: 1;
  }
}
.home_new_works--item .item--txtarea p {
  line-height: 1.4;
  white-space: normal;
}
@media screen and (max-width: 47.9375em) {
  .home_new_works--item .item--txtarea {
    bottom: 0.5714285714rem;
    left: 0.8571428571rem;
    width: calc(100% - 1.7142857143rem);
  }
  .home_new_works--item .item--txtarea p {
    font-size: 0.8571428571rem;
  }
}

.home_new_works--item:hover::before {
  opacity: 0.3;
}
.home_new_works--item:hover .item--img {
  transform: scale(1);
}
.home_new_works--item:hover .item--txtarea {
  opacity: 1;
}

/* home_works
********************************************** */
.home_works {
  padding-bottom: 8.625rem;
}
@media screen and (max-width: 47.9375em) {
  .home_works {
    padding-bottom: 2.2857142857rem;
  }
}

.home_works--wrapper {
  background-image: url(../images/top/work_background.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.home_works--ttlarea {
  position: relative;
  z-index: 1;
  max-width: 102.5rem;
  padding-block: 6.125rem;
}
@media screen and (max-width: 47.9375em) {
  .home_works--ttlarea {
    padding-block: 4.2857142857rem;
  }
}

.home_works--ttlarea .home--ttl {
  position: relative;
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_works--ttlarea .home--ttl {
    margin-bottom: 1.7142857143rem;
  }
}
.home_works--ttlarea .home--ttl .en {
  color: #fff;
}
.home_works--ttlarea .home--ttl .jp {
  color: #fff;
}

.home_works--ttlarea .txt {
  margin-bottom: 2.5rem;
  color: #fff;
}

/* home_company
********************************************** */
.home_company {
  position: relative;
  padding-bottom: 6.625rem;
}
.home_company .img {
  position: relative;
}
.home_company .img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 0;
  height: 100%;
  background-color: var(--clr-main);
}
.home_company .img img {
  opacity: 0;
  transition-property: opacity;
  transition-delay: 0.35s;
}
.home_company.active .img::before {
  animation-name: block_appear;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.77, 0, 0.6, 1);
  animation-direction: normal;
  animation-fill-mode: forwards;
}
.home_company.active .img img {
  opacity: 1;
}
@media screen and (max-width: 47.9375em) {
  .home_company {
    padding-top: 2.2857142857rem;
    padding-bottom: 4.2857142857rem;
  }
}

.home_company--deco {
  position: absolute;
  top: -16.3541666667vw;
  left: 0;
  width: 94.2708333333vw;
  height: 28.90625vw;
}
@media screen and (max-width: 47.9375em) {
  .home_company--deco {
    clip-path: inset(0 100% 0 0);
  }
  .home_company--deco.active {
    animation: brush 0.5s cubic-bezier(0.08, 0.41, 0.19, 0.95) forwards;
  }
}
.home_company--deco svg {
  width: 100%;
  height: 100%;
}

.company_inner {
  column-gap: 5.7291666667vw;
  max-width: 105rem;
}

.home_company--imgarea {
  position: relative;
  width: 44.88%;
}
@media screen and (max-width: 47.9375em) {
  .home_company--imgarea {
    width: 80%;
    margin-bottom: 1.7142857143rem;
  }
}
.home_company--imgarea .deco {
  position: absolute;
  bottom: -1.5rem;
  right: -3.75rem;
  z-index: -1;
  width: 20.25rem;
  height: 20.25rem;
}
@media screen and (max-width: 47.9375em) {
  .home_company--imgarea .deco {
    width: 8.5714285714rem;
    height: 8.5714285714rem;
  }
}
.home_company--imgarea .img-logo {
  width: 100%;
}

.home_company--txtarea {
  flex: 1;
  max-width: 44.375rem;
}
.home_company--txtarea .home--ttl {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_company--txtarea .home--ttl {
    margin-bottom: 1.5rem;
  }
}
.home_company--txtarea .home--sub_ttl {
  margin-bottom: 1em;
  font-size: max(1.6666666667vw, 1.5rem);
  line-height: 1.5625;
}
@media screen and (max-width: 47.9375em) {
  .home_company--txtarea .home--sub_ttl {
    font-size: 1.4285714286rem;
  }
}
.home_company--txtarea .txt {
  margin-bottom: 2.5rem;
}

/* home_blc
********************************************** */
.home_blc_sec {
  position: relative;
  min-height: 35rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform-origin: center;
  transition: var(--transit-default);
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .home_blc_sec {
    min-height: inherit;
  }
}
.home_blc_sec:hover {
  transform: scale(0.95);
}
.home_blc_sec .deco {
  position: absolute;
  opacity: 0.5;
}

.home_blc_sec > a {
  width: 100%;
  height: 100%;
  background: url(../images/top/recruit_bk_ptn.png);
}
@media screen and (max-width: 47.9375em) {
  .home_blc_sec > a {
    min-height: 21.4285714286rem;
    background-image: url(../images/top/blc_sec-bg.png);
  }
}
.home_blc_sec > a .ttl {
  position: relative;
  margin-bottom: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .home_blc_sec > a .ttl {
    margin-bottom: 3.8571428571rem;
  }
}
.home_blc_sec > a .ttl .en {
  display: block;
  margin-bottom: 1.5rem;
  letter-spacing: 0.14em;
  font-size: max(3.3333333333vw, 40px);
  line-height: 0.8;
  color: var(--clr-main);
}
@media screen and (max-width: 47.9375em) {
  .home_blc_sec > a .ttl .en {
    font-size: min(6.7632850242vw, 40px);
    color: rgba(255, 255, 255, 0.08);
  }
}
.home_blc_sec > a .ttl .jp {
  font-size: 2rem;
  line-height: 1;
  transition: var(--transit-default);
}
@media screen and (max-width: 47.9375em) {
  .home_blc_sec > a .ttl .jp {
    font-size: 1.7142857143rem;
    color: #fff;
  }
}
@media screen and (max-width: 47.9375em) {
  .home_blc_sec > a .btn span {
    color: #fff;
  }
}

.home_blc_sec.home_recruit {
  background-image: url(../images/top/recruit_button.jpg);
}
.home_blc_sec.home_recruit .deco-sm {
  top: -5.5rem;
  left: -6.125rem;
  width: 12.5rem;
  height: 12.5rem;
}
.home_blc_sec.home_recruit .deco-lg {
  bottom: -7.75rem;
  right: -8.375rem;
  width: 18.75rem;
  height: 18.75rem;
}
.home_blc_sec.home_recruit .ttl .catch {
  font-size: 5.2083333333vw;
}
.home_blc_sec.home_sustainability {
  background-image: url(../images/top/sustainability_button.jpg);
}
.home_blc_sec.home_sustainability .deco-sm {
  bottom: 2.875rem;
  left: 3.625rem;
  width: 8.125rem;
  height: 8.125rem;
}
.home_blc_sec.home_sustainability .deco-lg {
  top: -9.25rem;
  right: -7.5rem;
  width: 20.25rem;
  height: 20.25rem;
}
.home_blc_sec.home_sustainability .ttl .catch {
  font-size: 4.1666666667vw;
}

.home_blc_sec:hover > a {
  background-image: url(../images/top/blc_sec-bg.png);
}
.home_blc_sec:hover > a .ttl .en {
  color: rgba(255, 255, 255, 0.08);
}
.home_blc_sec:hover > a .ttl .jp {
  color: #fff;
}
.home_blc_sec:hover > a .btn span {
  color: #fff;
}

/* cta_blc
********************************************** */
.cta_blc {
  position: relative;
  padding-top: 5rem;
}
@media screen and (max-width: 67.5em) {
  .cta_blc {
    overflow: hidden;
  }
}

.cta_bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 23rem);
  background-image: url(../images/common/cta_img01.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media screen and (max-width: 47.9375em) {
  .cta_bg {
    height: calc(100% - 13rem);
    background-image: url(../images/common/cta_img01_sp.jpg);
  }
}

.cta_box {
  position: relative;
  z-index: 1;
  max-width: 111.125rem;
  padding: 4.5rem;
  background-color: #0a4cbf;
  box-shadow: 0 0 4.375rem 0 rgba(33, 71, 140, 0.3);
}
@media screen and (max-width: 47.9375em) {
  .cta_box {
    padding: 3.4285714286rem 1.5rem 1.7142857143rem;
  }
}

.cta_box--ttlarea {
  margin-bottom: 3rem;
}
@media screen and (max-width: 47.9375em) {
  .cta_box--ttlarea {
    margin-bottom: 1.7142857143rem;
  }
}
.cta_box--ttlarea .ttl {
  position: relative;
  margin-bottom: 2rem;
}
.cta_box--ttlarea .ttl .en {
  display: block;
  margin-bottom: 1.75rem;
  font-size: 5.625rem;
  letter-spacing: 0.14em;
  line-height: 0.8;
  color: #fff;
}
@media screen and (max-width: 47.9375em) {
  .cta_box--ttlarea .ttl .en {
    margin-bottom: 1.2857142857rem;
    font-size: 2.5714285714rem;
  }
}
.cta_box--ttlarea .ttl .jp {
  font-size: 1.625rem;
  letter-spacing: 0.16em;
  line-height: 1;
}
@media screen and (max-width: 47.9375em) {
  .cta_box--ttlarea .ttl .jp {
    font-size: 1.4285714286rem;
  }
}

.cta_box--content {
  max-width: 66.5rem;
  margin: 0 auto;
}
@media screen and (max-width: 47.9375em) {
  .cta_box--content {
    row-gap: 1.1428571429rem;
  }
}

.cta_box--content .info_box {
  width: calc((100% - 1.5rem) / 2);
  min-height: 11.875rem;
  padding: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .cta_box--content .info_box {
    width: 100%;
    min-height: inherit;
    padding: 1.7142857143rem 1.1428571429rem;
  }
}

.cta_box--content .box--ttl {
  position: relative;
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  line-height: 1;
  letter-spacing: 0.16em;
}
@media screen and (max-width: 47.9375em) {
  .cta_box--content .box--ttl {
    margin-bottom: 1.1428571429rem;
  }
}
.cta_box--content .box--ttl::after {
  content: "";
  display: block;
  width: 2rem;
  height: 1px;
  margin-top: 1.25rem;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid var(--clr-body);
}

.cta_box--content .tel {
  margin-bottom: 0.75rem;
  line-height: 1;
  letter-spacing: -0.02em;
  white-space: nowrap;
}
@media screen and (max-width: 47.9375em) {
  .cta_box--content .tel {
    font-size: 1.1428571429rem;
  }
}
.cta_box--content .tel .num {
  letter-spacing: -0.02em;
}
@media screen and (max-width: 47.9375em) {
  .cta_box--content .tel .num {
    font-size: 1.7142857143rem;
  }
}

.cta_box--content .open {
  color: #a0a0a0;
}

.cta_box--content .cta_btn {
  width: 100%;
}
.cta_box--content .cta_btn a {
  column-gap: 1.25rem;
  max-width: 20rem;
  width: 100%;
  padding-block: 1.25rem;
  margin: 0 auto;
  line-height: 1;
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
}
.cta_box--content .cta_btn a svg {
  fill: #fff;
}
.cta_box--content .cta_btn a:hover {
  color: #000;
  background-color: #fff;
}
.cta_box--content .cta_btn a:hover svg {
  fill: #000;
}

.cta_blc .cta_btm {
  position: relative;
  z-index: 1;
  padding-block: 10.625rem 8.75rem;
}
@media screen and (max-width: 47.9375em) {
  .cta_blc .cta_btm {
    padding-block: 5.7142857143rem;
  }
}
.cta_blc .cta_btm .cta_catch {
  font-size: 2.5rem;
  line-height: 1.6;
  letter-spacing: 0.2em;
  transform: translateY(1.25rem);
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition-delay: 0.4s;
}
.cta_blc .cta_btm .cta_catch.active {
  transform: translateY(0);
  opacity: 1;
}
@media screen and (max-width: 47.9375em) {
  .cta_blc .cta_btm .cta_catch {
    font-size: 2rem;
  }
}

.cta_btm--deco {
  position: absolute;
  top: calc(50% + 2.5vw);
  left: calc(50% + 18.2291666667vw);
  transform: translate(-50%, -50%);
  width: 46.0416666667vw;
  height: 7.8645833333vw;
}
@media screen and (max-width: 67.5em) {
  .cta_btm--deco {
    left: calc(50% + 9.375vw);
  }
}
@media screen and (max-width: 47.9375em) {
  .cta_btm--deco {
    top: calc(50% + 11.5942028986vw);
    width: 106.7632850242vw;
    height: 18.8405797101vw;
    clip-path: inset(0 100% 0 0);
  }
  .cta_btm--deco.active {
    animation: brush 0.8s cubic-bezier(0.08, 0.41, 0.19, 0.95) forwards;
  }
}
.cta_btm--deco svg {
  width: 100%;
  height: 100%;
}

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  height: 30rem;
  color: #fff;
  background-color: #758eaa;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl {
    height: 14.2857142857rem;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .page_ttl::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6.25rem;
    background: linear-gradient(to top, transparent, var(--clr-body) 100%);
    opacity: 0.4;
  }
}
.page_ttl > .inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page_ttl .breadcrumbs {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.page_ttl .breadcrumbs span span:not(:last-child)::after {
  background-color: var(--bg-wht);
}

.page_ttl--bg {
  position: absolute;
  inset: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}

.page_ttl-jp {
  font-size: 3rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-jp {
    font-size: 1.7142857143rem;
  }
}

.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: 1rem;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-en {
    font-size: 1rem;
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04 {
  position: relative;
  letter-spacing: 0.1em;
}
.ttl-01:not([class*=mgn-btm]):not(:last-child),
.ttl-02:not([class*=mgn-btm]):not(:last-child),
.ttl-03:not([class*=mgn-btm]):not(:last-child),
.ttl-04:not([class*=mgn-btm]):not(:last-child) {
  margin-bottom: 1em;
}

.ttl-01 {
  font-size: 3rem;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01 {
    font-size: 2.1428571429rem;
  }
}
.ttl-01::after {
  content: "";
  display: block;
  width: 7.5rem;
  height: 2px;
  margin-top: 2.5rem;
  background: linear-gradient(90deg, var(--clr-main) 0%, var(--clr-main) 50%, #c9c9c9 50%, #c9c9c9 100%);
}
@media screen and (max-width: 47.9375em) {
  .ttl-01::after {
    width: 4.2857142857rem;
    margin-top: 1.4285714286rem;
  }
}
.ttl-01.txt-ctr::after {
  margin-left: auto;
  margin-right: auto;
}
.ttl-01.txt-wh + .ttl-01-sub {
  color: var(--clr-wht);
}
.ttl-01.txt-wh + .ttl-01-sub::after {
  background-color: var(--bg-wht);
}

.ttl-01-sub {
  margin-bottom: 1.75rem;
  font-size: 0.875rem;
  font-family: var(--font-en);
  color: var(--clr-main);
}

.ttl-02 {
  font-size: 2.5rem;
  color: var(--clr-main);
}
@media screen and (max-width: 47.9375em) {
  .ttl-02 {
    font-size: 2rem;
  }
}

.ttl-03 {
  font-size: 1.5rem;
}
@media screen and (max-width: 47.9375em) {
  .ttl-03 {
    font-size: 1.7142857143rem;
  }
}

.ttl-04 {
  font-size: 1.5rem;
}

/* anchor_link
********************************************** */
.anchor_link--list_wrap {
  background-image: url(../images/common/bk-ptn.jpg);
}

@media screen and (max-width: 47.9375em) {
  .anchor_link--list {
    flex-wrap: inherit;
    white-space: nowrap;
    overflow: auto;
  }
}

.anchor_link--list li {
  min-height: 6rem;
}
@media screen and (max-width: 47.9375em) {
  .anchor_link--list li {
    width: auto;
    min-height: 4.5714285714rem;
  }
  .anchor_link--list li:first-child {
    margin-left: auto;
  }
  .anchor_link--list li:last-child {
    margin-right: auto;
  }
  .anchor_link--list li:first-child a {
    padding-left: 0;
  }
}

.anchor_link--list a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0.5em 2.5em;
  line-height: var(--line-height-hdr);
}
@media screen and (max-width: 47.9375em) {
  .anchor_link--list a {
    padding: 1em 1.5em;
  }
}
.anchor_link--list a::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
  width: 0.75rem;
  height: 0.375rem;
  background-color: var(--clr-body);
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  transition: var(--transit-default);
}
@media screen and (max-width: 47.9375em) {
  .anchor_link--list a::before {
    right: 0;
  }
}
.anchor_link--list a:hover {
  color: var(--clr-main);
}
.anchor_link--list a:hover::before {
  background-color: var(--clr-main);
}

/* ##############################################################################

    ARCHIVE

############################################################################## */
/* main_column
**************************************** */
.main_column {
  width: calc(100% - (16.5rem + 3.75rem));
  margin-right: 3.75rem;
}

/* --- post --- */
.post {
  position: relative;
  transition: var(--transit-default, all 0.4s ease-out);
}

.post--img .img-contain {
  max-width: 80%;
}

.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.post--date {
  font-size: 0.75rem;
  font-family: var(--font-en);
  transition: var(--transit-default);
}

.post--ttl {
  transition: var(--transit-default);
}

.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}

.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.8125rem;
  background-color: var(--clr-main);
  color: var(--clr-wht);
  padding: 0.2em 1em;
  float: left;
  white-space: nowrap;
  margin: 0.125rem;
}
@media screen and (max-width: 47.9375em) {
  .cat_list a {
    padding: 0 1em;
  }
}

.cat_list a:hover {
  opacity: 0.7;
}

@media screen and (max-width: 68.75em) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 2.5rem;
    margin-right: 0;
  }
}
/* side_column
**************************************** */
.side_column {
  width: 16.5rem;
}
@media screen and (max-width: 68.75em) {
  .side_column {
    width: 100%;
  }
}

.side_section:not(:last-child) {
  margin-bottom: 4rem;
}

.side--ttl {
  letter-spacing: 0.2em;
  line-height: 1;
  margin-bottom: 1rem;
}

.side--ttl small {
  opacity: 0.5;
  letter-spacing: 0.15em;
  display: block;
  line-height: 1;
  margin-top: 0.5rem;
}

/* --- list --- */
.side--list a {
  display: block;
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: 1rem;
}

.posts-side .post--img {
  margin-right: 1rem;
  width: 4rem;
}

.posts-side .txtarea {
  flex: 1;
}

.posts-side .post--date {
  margin-bottom: 0;
}

.posts-side .post--ttl {
  line-height: 1.4;
}

/* --- archive --- */
.archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
}
.archive_list--ttl::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: var(--transit-default);
}
.archive_list--ttl.active::after {
  transform: rotate(315deg);
}

.archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}

.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 1.5rem;
}

.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.archive-pulldown .archive_list a:hover {
  background-color: #eee;
}

.archive-pulldown .archive_list--label {
  margin-right: 0.5rem;
}

.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  color: inherit;
  font-family: var(--font-primary);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.4em;
  right: 0.8em;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}

.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}

.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: var(--bg-wht);
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* blog
**************************************** */
.posts-blog .post {
  padding-bottom: 3rem;
  padding-top: 3rem;
  border-bottom: 1px solid #e5e5e5;
}
.posts-blog .post:first-child {
  border-top: 1px solid #e5e5e5;
}

.posts-blog .post--txtarea {
  flex: 1;
  word-break: break-word;
}

.posts-blog .post--img {
  width: 17.5rem;
  margin-right: 2.5rem;
}

@media screen and (max-width: 47.9375em) {
  .posts-blog .post {
    display: block;
    padding-top: 1.7142857143rem;
    padding-bottom: 1.7142857143rem;
  }
  .posts-blog .post--img {
    padding-left: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.1428571429rem;
    text-align: center;
  }
  .posts-blog .post--img img {
    width: auto;
    max-width: 100%;
  }
}
/* news
**************************************** */
/* --- news--archive --- */
.news--archive {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  background-color: #111;
}

.news--archive li {
  margin-left: 8px;
  margin-right: 8px;
}

.news--archive a {
  color: var(--clr-wht);
}

/* --- news_list --- */
.news_list .post {
  padding: 1rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0 20px rgba(41, 52, 64, 0.1);
}
.news_list .post:not(:last-child) {
  margin-bottom: 0.5rem;
}
.news_list .post:hover {
  box-shadow: inherit;
}
@media screen and (max-width: 47.9375em) {
  .news_list .post {
    display: block;
  }
}

@media print, screen and (min-width: 48em) {
  .news_list .post--info {
    margin-right: 1.5rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .news_list .post--info {
    margin-bottom: 0.5714285714rem;
  }
}

@media print, screen and (min-width: 48em) {
  .news_box .post--info {
    margin-right: 1.5rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .news_box .post--info {
    margin-bottom: 0.1428571429rem;
  }
}

.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main);
}

.news_list .post--link:hover svg {
  fill: var(--clr-main);
}

.news_list .post--date {
  min-width: 7.5rem;
}

.news_list .post--info.flex .post--date + .cat_list {
  margin-left: 1rem;
}

/* works
**************************************** */
.map-ttl {
  position: relative;
  padding-left: 1.5rem;
  font-size: 2rem;
  line-height: 1.6;
}
.map-ttl::before {
  content: "";
  position: absolute;
  top: 0.1em;
  bottom: 0.1em;
  left: 0;
  display: block;
  width: 0.375rem;
  background-color: var(--clr-main);
}

#map {
  position: relative;
  width: 100%;
  aspect-ratio: 3/2;
  background-color: #eee;
}
@media screen and (max-width: 47.9375em) {
  #map {
    aspect-ratio: 1/1;
  }
}

#map iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.works_list {
  width: 100%;
}

.works_list table {
  width: 100%;
}

.works_list table th {
  text-align: left;
}

.works_list thead th {
  padding: 0.3em 0.7em;
  font-family: var(--font-jp);
  color: #fff;
  background-color: var(--bg-main);
}

.works_list tbody tr {
  border-bottom: 1px solid #d3d3d3;
}

.works_list tbody th,
.works_list tbody td {
  padding: 0.7em;
  line-height: 1.5;
}

.works_list--btn {
  width: 6.25rem;
}

.works_list--btn a {
  display: block;
  padding: 0.5em;
  color: var(--clr-body);
  text-align: center;
  font-weight: 500;
  line-height: 1;
  border: 1px solid;
}

.works_list--name a:hover {
  opacity: 0.5;
}

.works_list--btn a:hover {
  color: #fff;
  background-color: var(--clr-main);
  border-color: var(--clr-main);
}

/* sidebar */
.search_item {
  background-color: #eee;
  padding: 0.7em 1em;
}
.search_item:not(:last-child) {
  margin-bottom: 1em;
}

.search_item--ttl {
  padding-bottom: 0.5em;
  border-bottom: 1px solid;
}

.search_item:not(.search_item--category) .check_list {
  display: none;
}

.check_list li input[type=checkbox] {
  display: none !important;
}

.search_item .check_list li label {
  position: relative;
  display: block;
  padding: 0.5em 0.5rem 0.5em 1.375rem;
  font-size: 0.875rem;
  line-height: 1.4;
}
.search_item .check_list li label::before {
  content: "";
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  left: 0;
  top: 0.625rem;
  border: 2px solid #788b93;
}
@media screen and (max-width: 47.9375em) {
  .search_item .check_list li label::before {
    top: 0.7142857143rem;
  }
}

.search_item .check_list li input[type=checkbox]:checked + label {
  color: #e75f5f;
  font-weight: bold;
}
.search_item .check_list li input[type=checkbox]:checked + label::before {
  border: 2px solid #e75f5f;
}
.search_item .check_list li input[type=checkbox]:checked + label::after {
  content: "";
  position: absolute;
  top: 0.4375rem;
  left: 0.25rem;
  display: block;
  width: 0.375rem;
  height: 0.75rem;
  border-right: 2px solid #e75f5f;
  border-bottom: 2px solid #e75f5f;
  z-index: 10;
  transform: rotate(45deg);
}
@media screen and (max-width: 47.9375em) {
  .search_item .check_list li input[type=checkbox]:checked + label::after {
    top: 0.5rem;
  }
}

.side_section button {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 1rem 3rem;
  min-width: 15.625rem;
  font-size: 0.875rem;
  text-align: center;
  color: var(--clr-body);
  background: none;
  border: 1px solid;
  border-radius: 62.4375rem;
  outline: none;
  cursor: pointer;
  transition: 0.4s ease-out;
  text-decoration: none;
}
.side_section button:hover {
  color: var(--clr-main);
  border-color: var(--clr-main);
}

.side_section .txt-link {
  display: inline-block;
  padding-bottom: 0.5em;
  border-bottom: 1px solid var(--clr-body);
  line-height: 1.4;
}
.side_section .txt-link:hover {
  color: var(--clr-main);
  border-bottom: 1px solid var(--clr-main);
}

.search_item .child {
  padding-left: 1.1428571429rem;
}

/* toggle
**************************************** */
.toggle {
  position: relative;
  cursor: pointer;
  transition: 0.4s ease-out;
}
.toggle:hover {
  opacity: 0.5;
}
.toggle::after {
  content: "";
  position: absolute;
  top: 25%;
  right: 1.875rem;
  display: block;
  width: 0.875rem;
  height: 0.875rem;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg) translateY(-50%);
}

.toggle.open + .mce-content-body {
  display: block;
}

.toggle-sm::after {
  right: 0.9375rem;
  width: 0.5rem;
  height: 0.5rem;
}

/* ##############################################################################

    PAGE

############################################################################## */
/* related_page
********************************************** */
.related_page--list {
  border-radius: 0.625rem;
  overflow: hidden;
}
@media print, screen and (min-width: 48em) {
  .related_page--list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(33.3333333333%, 1fr));
  }
}

.related_page--list li:nth-child(9n-1) a::before, .related_page--list li:nth-child(9n-3) a::before, .related_page--list li:nth-child(9n-8) a::before {
  filter: brightness(1.1);
}
.related_page--list li:nth-child(9n-2) a::before, .related_page--list li:nth-child(9n-4) a::before, .related_page--list li:nth-child(9n-6) a::before {
  filter: brightness(0.9);
}

.related_page--list a {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 5.5rem 4rem 4rem;
  line-height: var(--line-height-hdr);
  color: var(--clr-wht);
}
@media print, screen and (min-width: 48em) {
  .related_page--list a {
    height: 17.5rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .related_page--list a {
    padding: 1.7142857143rem 2.2857142857rem 1.7142857143rem 1.7142857143rem;
  }
}
.related_page--list a::before, .related_page--list a::after {
  content: "";
  position: absolute;
}
.related_page--list a::before {
  inset: 0;
  pointer-events: none;
  z-index: -1;
  background-color: var(--bg-main);
}
.related_page--list a::after {
  top: 0;
  bottom: 0;
  right: 4rem;
  margin: auto;
  width: 1.5rem;
  height: 1.5rem;
  border-top: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
}
@media screen and (max-width: 47.9375em) {
  .related_page--list a::after {
    right: 1.7142857143rem;
    width: 0.8571428571rem;
    height: 0.8571428571rem;
  }
}
.related_page--list a:hover {
  opacity: 0.7;
}

.related_page--list .font-jp {
  margin-bottom: 0.25em;
}
@media screen and (max-width: 47.9375em) {
  .related_page--list .font-jp {
    font-size: 1.2857142857rem;
  }
}

.related_page--list .font-en {
  opacity: 0.5;
  text-transform: uppercase;
  font-weight: 600;
}
@media screen and (max-width: 47.9375em) {
  .related_page--list .font-en {
    font-size: 0.7142857143rem;
  }
}

.related_page--bg {
  position: absolute;
  inset: 0;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  opacity: 0.2;
  z-index: -1;
}
@media screen and (max-width: 47.9375em) {
  .related_page--bg {
    background-position: center;
  }
}

/* ##############################################################################

    SEARCH

############################################################################## */
#search-list .section_pdg {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

#search-list .ttl-01 {
  margin: 4.375rem 0 0;
}

/* ##############################################################################

    SINGLE

############################################################################## */
/* --- アイキャッチ --- */
.eyecatch {
  margin-bottom: 2rem;
}

/* --- 目次 --- */
.ez-toc-debug-messages {
  display: none;
}

div#ez-toc-container {
  padding: 1.5rem;
  background-color: #fafafa;
}

.ez-toc-title-container {
  margin-bottom: 0.625rem;
}

div#ez-toc-container p.ez-toc-title {
  color: var(--clr-body);
  font-weight: bold;
  font-size: 1.25rem;
}

#ez-toc-container.counter-hierarchy ul {
  font-size: 1.0625rem;
}

#ez-toc-container.counter-hierarchy ul li {
  margin-top: 0.5rem;
}

#ez-toc-container.counter-hierarchy ul ul {
  margin-left: 1rem;
}

#ez-toc-container.counter-hierarchy ul ul a {
  position: relative;
  display: inline-block;
  padding-left: 1rem;
}
#ez-toc-container.counter-hierarchy ul ul a::before {
  content: "";
  position: absolute;
  top: 0.625rem;
  left: 0;
  width: 0.5rem;
  height: 0;
  border-bottom: 1px solid;
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}

#pass input[name=post_password] {
  padding: 0.3125rem;
  border: solid 1px #aaa;
  outline: none;
}

#pass input[type=submit] {
  padding: 0.25rem 0.375rem;
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: 0.25rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default);
}

#pass input[type=submit]:hover {
  opacity: 0.7;
}

/* blog--share
**************************************** */
.blog--share {
  margin-top: 2rem;
}

.blog--share_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.375rem;
}
@media screen and (max-width: 47.9375em) {
  .blog--share_list {
    grid-template-columns: repeat(2, 1fr);
  }
}

.blog--share_list a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  padding: 0.5em;
  line-height: 1;
  font-size: 0.75rem;
  font-weight: bold;
  border: 1px solid #ddd;
}

.blog--share_list li.twitter a {
  color: #2BA1F2;
}
.blog--share_list li.facebook a {
  color: #2477F2;
}
.blog--share_list li.line a {
  color: #27C754;
}
.blog--share_list li.pocket a {
  color: #EF4056;
}
.blog--share_list li.linkedin a {
  color: #2867B2;
}
.blog--share_list li.hatena a {
  color: #29A4DE;
}

.blog--share_list img {
  margin-right: 0.625rem;
}

.blog--share_list a:hover {
  opacity: 0.5;
}

/* blog--related
**************************************** */
.posts_scroll {
  overflow: auto;
  padding-bottom: 2rem;
}

.posts_scroll .post {
  display: block;
  width: 46%;
  flex-shrink: 0;
}
@media screen and (max-width: 47.9375em) {
  .posts_scroll .post {
    width: 70%;
  }
}
.posts_scroll .post:not(:last-child) {
  margin-right: 1rem;
}

.posts_scroll .post--img {
  margin-bottom: 1rem;
}

.posts_scroll .post--txt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.posts_scroll::-webkit-scrollbar {
  height: 0.375rem;
}

.posts_scroll::-webkit-scrollbar-thumb {
  background-color: #999;
}

.posts_scroll::-webkit-scrollbar-track-piece {
  background-color: #eee;
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi {
    margin-top: 1.7142857143rem;
  }
}

.wp-pagenavi .pages {
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
}

.wp-pagenavi a {
  position: relative;
  padding: 0.5em 1em;
  background-color: var(--bg-sub);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--clr-wht);
}
.wp-pagenavi a:hover {
  background-color: var(--bg-main);
}

.archive .wp-pagenavi > *:not(.pages) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .archive .wp-pagenavi > *:not(.pages) {
    width: 2rem;
    height: 2rem;
  }
}

.wp-pagenavi-single a[rel=prev],
.wp-pagenavi-single a[rel=next] {
  width: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .wp-pagenavi-single a[rel=prev],
  .wp-pagenavi-single a[rel=next] {
    width: 2.2857142857rem;
  }
}

.wp-pagenavi > *:not(.pages) {
  margin-bottom: 0.25rem;
}

.wp-pagenavi > *:not(.pages):not(:last-child) {
  margin-right: 0.25rem;
}

.wp-pagenavi .extend {
  width: 1.5rem !important;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-top: 2px solid;
  border-right: 2px solid;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  width: 0.5rem;
  height: 0.5rem;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .first::after {
  transform: rotate(-135deg);
}

.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .last::before,
.wp-pagenavi .last::after {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=prev]::before {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=next]::before {
  transform: rotate(-135deg);
}

.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: -0.375rem;
}

.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: -0.375rem;
}

/* works
**************************************** */
@media screen and (max-width: 47.9375em) {
  .works--info .ttl-01 {
    font-size: 1.7142857143rem;
    margin-bottom: 2.8571428571rem;
  }
}

/* --- dlリスト --- */
.list-dl {
  position: relative;
  width: 100%;
  border-top: 1px solid #bfbfbf;
}

.list-dl dt {
  position: absolute;
  padding-top: 1em;
}
@media screen and (max-width: 47.9375em) {
  .list-dl dt {
    padding-top: 0.7em;
  }
}

.list-dl dd {
  padding-left: 8.75rem;
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #bfbfbf;
}
@media screen and (max-width: 47.9375em) {
  .list-dl dd {
    padding-left: 5.5rem;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
  }
}

/* sitemap
*************************************************** */
@media print, screen and (min-width: 48em) {
  .sitemap--menu {
    column-count: 2;
    gap: 5%;
  }
}

.sitemap--menu > li {
  break-inside: avoid;
}

.sitemap--menu > li > a {
  border-bottom: 1px solid #c9c9c9;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  font-size: 1.125rem;
}

.sitemap--menu a,
.sitemap--menu span {
  display: block;
}

.sitemap--menu a {
  position: relative;
}
.sitemap--menu a:hover {
  color: var(--clr-main);
}

.sitemap_sub_nav--blc {
  padding-left: 1em;
}
.sitemap_sub_nav--blc:not(:last-child) {
  margin-bottom: 1em;
}

.sitemap--tax {
  border-bottom: 1px solid #eee;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
}

.sitemap_sub_nav a {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 1.25em;
}
.sitemap_sub_nav a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 0.375rem;
  height: 0.375rem;
  color: var(--clr-main);
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
}

/* lps_parts--button
********************************************** */
.lps_parts--button .btn:first-child {
  margin-left: auto;
}

.lps_parts--button .btn:last-child {
  margin-right: auto;
}

@media print, screen and (min-width: 48em) {
  .lps_parts--button.flex,
  .lps_parts--button .inner {
    width: fit-content;
    min-width: min(60rem, 90%);
  }
  .lps_parts--button.flex {
    margin-left: auto;
    margin-right: auto;
  }
  .lps_parts--button.flex > .btn {
    width: fit-content;
  }
  .lps_parts--button .btn:only-child {
    width: fit-content;
    min-width: min(17.5rem, 100%);
  }
  .lps_parts--column.column-2 + .lps_parts--button,
  .lps_parts--column.column-3 + .lps_parts--button,
  .lps_parts--column.column-4 + .lps_parts--button {
    margin-top: 0;
  }
}
/* lps_parts--column
********************************************** */
.lps_parts--column:not(.column-1) + .lps_parts--column {
  margin-top: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column:not(.column-1) + .lps_parts--column {
    margin-top: 1.7142857143rem;
  }
}

.lps_parts--column .column_item,
.lps_parts--column .text {
  display: flex;
  flex-direction: column;
}

.lps_parts--column:not(.column-1) .text {
  height: 100%;
}

.lps_parts--column .img {
  flex-shrink: 0;
}

.lps_parts--column .img + .text {
  padding-top: 1.5rem;
}

.lps_parts--column .bg-wh .text {
  padding: 6%;
}

.lps_parts--column:not(.column-1) table {
  width: 100%;
}

.lps_parts--column.column-1 .box {
  padding: 2.5rem;
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column,
  .lps_parts--column:not(.column-1) + .lps_parts--column {
    margin-top: 1.7142857143rem;
  }
  .lps_parts--column {
    width: 100%;
    max-width: 100%;
  }
  .lps_parts--column .flex-sp-block .column_item:not(:last-child) {
    margin-bottom: 2.5rem;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
  .lps_parts--column .text.bg-wh,
  .lps_parts--column.column-1 .box {
    padding: 1.7142857143rem;
  }
}
/* post-edit-link
********************************************** */
.post-edit-link {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 10;
  padding: 1rem;
  border-radius: 50%;
  border: 1px solid #999;
}
@media screen and (max-width: 47.9375em) {
  .post-edit-link {
    display: none;
  }
}
.post-edit-link:hover {
  background-color: #ddd;
}
.post-edit-link::after {
  content: "";
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuODrOOCpOODpOODvF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDAxIDQwMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAxIDQwMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM5OTk5OTk7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzcwLjEsMjUxLjljLTUuNSwwLTEwLDQuNS0xMCwxMHY4OC43YzAsMTYuNS0xMy40LDI5LjktMzAsMzBINDkuOWMtMTYuNSwwLTI5LjktMTMuNC0zMC0zMFY5MC4zCgljMC0xNi41LDEzLjQtMjkuOSwzMC0zMGg4OC43YzUuNSwwLDEwLTQuNSwxMC0xMGMwLTUuNS00LjUtMTAtMTAtMTBINDkuOUMyMi40LDQwLjQsMCw2Mi43LDAsOTAuM3YyNjAuMwoJYzAsMjcuNiwyMi40LDQ5LjksNDkuOSw0OS45aDI4MC4yYzI3LjYsMCw0OS45LTIyLjQsNDkuOS00OS45di04OC43QzM4MC4xLDI1Ni40LDM3NS42LDI1MS45LDM3MC4xLDI1MS45eiIvPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzc2LjEsMTQuN2MtMTcuNi0xNy42LTQ2LTE3LjYtNjMuNiwwTDEzNC40LDE5Mi45Yy0xLjIsMS4yLTIuMSwyLjctMi42LDQuNGwtMjMuNCw4NC42Yy0xLDMuNSwwLDcuMiwyLjYsOS43CgljMi41LDIuNSw2LjMsMy41LDkuNywyLjZsODQuNi0yMy40YzEuNy0wLjUsMy4yLTEuMyw0LjQtMi42TDM4Ny45LDkwYzE3LjUtMTcuNiwxNy41LTQ2LDAtNjMuNkwzNzYuMSwxNC43eiBNMTU2LjIsMTk5LjNMMzAyLDUzLjUKCWw0Nyw0N0wyMDMuMiwyNDYuNEwxNTYuMiwxOTkuM3ogTTE0Ni44LDIxOC4ybDM3LjYsMzcuNmwtNTIsMTQuNEwxNDYuOCwyMTguMnogTTM3My43LDc1LjhsLTEwLjYsMTAuNmwtNDctNDdsMTAuNi0xMC42CgljOS43LTkuNywyNS42LTkuNywzNS4zLDBsMTEuNywxMS43QzM4My41LDUwLjMsMzgzLjUsNjYuMSwzNzMuNyw3NS44eiIvPgo8L3N2Zz4K");
}

/* lps_parts--faq
********************************************** */
/* --- 共通 --- */
.lps_parts--faq {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}
.lps_parts--faq .ttl-03 {
  font-size: 1.25rem;
  margin-bottom: 0 !important;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--faq .text {
    display: flex;
    gap: 4.1666666667%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--faq .img {
    width: 39.0625%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--faq .mce-content-body {
    margin-bottom: 0 !important;
    flex: 1;
  }
}

/* --- 通常 --- */
.lps_parts--faq:not(.faq-toggle) .faq_item {
  border: 1px solid var(--clr-main);
  background-color: var(--bg-wht);
}
.lps_parts--faq:not(.faq-toggle) .faq_item:not(:last-child) {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq:not(.faq-toggle) .faq_item:not(:last-child) {
    margin-bottom: 1.1428571429rem;
  }
}
.lps_parts--faq:not(.faq-toggle) .ttl-03,
.lps_parts--faq:not(.faq-toggle) .text {
  padding: 1.75rem 2rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq:not(.faq-toggle) .ttl-03,
  .lps_parts--faq:not(.faq-toggle) .text {
    padding: 1.7142857143rem;
  }
}
.lps_parts--faq:not(.faq-toggle) .ttl-03 {
  background-color: var(--clr-main);
  color: var(--clr-wht);
}

/* --- 開閉式 --- */
.lps_parts--faq.faq-toggle .faq_item {
  border-top: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  padding: 2.5rem 1.875rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq.faq-toggle .faq_item {
    padding: 1.7142857143rem;
  }
}
.lps_parts--faq.faq-toggle .faq_item:not(:first-child) {
  border-top: 0;
}
.lps_parts--faq.faq-toggle .ttl-03 {
  cursor: pointer;
  padding-right: 3.5rem;
}
.lps_parts--faq.faq-toggle .ttl-03:not(:last-child) {
  margin-bottom: 1.5em;
}
.lps_parts--faq.faq-toggle .ttl-03::before {
  content: "";
  position: absolute;
  inset: -2.5rem -1.875rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq.faq-toggle .ttl-03::before {
    inset: -1.7142857143rem;
  }
}
.lps_parts--faq.faq-toggle .ttl-03 .toggle {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  width: 3rem;
  height: 3rem;
  color: var(--clr-wht);
  background-color: var(--bg-main);
  border-radius: 50%;
  transition: var(--transit-default);
}
.lps_parts--faq.faq-toggle .ttl-03 .toggle::before, .lps_parts--faq.faq-toggle .ttl-03 .toggle::after {
  content: "";
  position: absolute;
  inset: 0;
  margin: auto;
  width: 0.75rem;
  height: 2px;
  border-bottom: 2px solid;
  transition: var(--transit-default);
}
.lps_parts--faq.faq-toggle .ttl-03 .toggle::after {
  transform: rotate(90deg);
}
.lps_parts--faq.faq-toggle .ttl-03:hover .toggle {
  opacity: 0.5;
}
.lps_parts--faq.faq-toggle .ttl-03.active .toggle {
  transform: rotate(180deg);
}
.lps_parts--faq.faq-toggle .ttl-03.active .toggle::after {
  opacity: 0;
}
.lps_parts--faq.faq-toggle .text {
  padding-top: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq.faq-toggle .text {
    padding-top: 1.4285714286rem;
  }
}

/* --- lps_sec[style*="background-color"] --- */
.lps_sec[style*=background-color] .lps_parts--faq:not(.faq-toggle) .text {
  color: var(--clr-body);
}

.lps_sec[style*=background-color] .lps_parts--faq.faq-toggle .ttl-03 .toggle {
  background-color: #fff;
}
.lps_sec[style*=background-color] .lps_parts--faq.faq-toggle .ttl-03 .toggle::before, .lps_sec[style*=background-color] .lps_parts--faq.faq-toggle .ttl-03 .toggle::after {
  border-color: var(--bg-main);
}

/* lps_parts--flow
********************************************** */
/* --- 共通 --- */
.lps_parts--flow {
  max-width: 60rem;
  margin-left: auto;
  margin-right: auto;
}
.lps_parts--flow .flow_item {
  position: relative;
  padding-left: 6rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item {
    padding-left: 4rem;
  }
}
.lps_parts--flow .flow_item:not(:last-child) {
  padding-bottom: 4rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child) {
    padding-bottom: 2.2857142857rem;
  }
}
.lps_parts--flow .flow_item:not(:last-child)::before {
  content: "";
  position: absolute;
  left: 1.9375rem;
  width: 1px;
  border-right: 1px solid var(--clr-main);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child)::before {
    left: 1.2857142857rem;
  }
}
.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: var(--clr-wht);
}
.lps_parts--flow .ttl-03::before {
  position: absolute;
  border-radius: 50%;
}
.lps_parts--flow .txt-wh .ttl-03 {
  color: var(--clr-wht);
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow .text {
    display: flex;
    gap: 4.1666666667%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow .img {
    width: 39.0625%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .img:not(:last-child) {
    margin-bottom: 1.7142857143rem;
  }
}
.lps_parts--flow .mce-content-body {
  margin-bottom: 0 !important;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow .mce-content-body {
    flex: 1;
  }
}

/* --- 通常 --- */
.lps_parts--flow:not(.flow-num) .flow_item:not(:last-child)::before {
  top: 2.5rem;
  bottom: 0.25rem;
}
.lps_parts--flow:not(.flow-num) .ttl-03::before {
  content: "";
  top: 0.375rem;
  left: -4.75rem;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.25rem solid var(--clr-main);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow:not(.flow-num) .ttl-03::before {
    left: -3.2857142857rem;
  }
}

/* --- 連番 --- */
.lps_parts--flow.flow-num .flow_item {
  counter-increment: number;
}
.lps_parts--flow.flow-num .flow_item:not(:last-child)::before {
  top: 4rem;
  bottom: 1.25rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.flow-num .flow_item:not(:last-child)::before {
    top: 3.2857142857rem;
    bottom: 1.1428571429rem;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--flow.flow-num .ttl-03 {
    font-size: 2rem;
  }
}
.lps_parts--flow.flow-num .ttl-03::before {
  content: counter(number);
  top: -0.625rem;
  left: -6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  text-align: center;
  color: var(--clr-wht);
  background-color: var(--clr-main);
  font-family: var(--font-en);
  font-size: 1.5rem;
  letter-spacing: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow.flow-num .ttl-03::before {
    top: -0.2857142857rem;
    left: -4rem;
    width: 2.8571428571rem;
    height: 2.8571428571rem;
    font-size: 1.2857142857rem;
  }
}
.lps_parts--flow.flow-num .txt-wh .ttl-03::after {
  color: var(--clr-main);
  background-color: var(--bg-wht);
}

/* ##############################################################################

    Loops モジュール

############################################################################## */
.icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transit-default);
}

.icon-new_tab {
  position: relative;
  right: auto;
  top: auto;
  transform: translateY(0);
  width: 0.875rem;
  height: 0.875rem;
}

.btn .icon-new_tab {
  right: auto;
}

.icon-pdf {
  width: 1.6875rem;
  height: 0.75rem;
}

.lps_sec {
  position: relative;
}
.lps_sec:nth-child(odd) .bg-wh {
  background-color: var(--bg-off_wht);
}
.lps_sec[style*=background-color] {
  background-color: var(--bg-main);
  color: #fff;
}
.lps_sec[style*=background-color] a {
  color: #fff;
}
.lps_sec[style*=background-color] .bg-wh,
.lps_sec[style*=background-color] .table2 {
  color: var(--clr-body);
}
.lps_sec[style*=background-color] .bg-wh a,
.lps_sec[style*=background-color] .table2 a {
  color: var(--clr-body);
}
.lps_sec:last-of-type {
  border-bottom: 1px solid #fff;
}

.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}

.lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
  margin-top: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts + .ttl-02,
  .lps_parts--button + .ttl-02,
  .lps_parts--button + .lps_parts,
  .lps_parts + .lps_parts {
    margin-top: 2.8571428571rem;
  }
}

.lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
  margin-top: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column,
  .lps_parts + .lps_parts--button {
    margin-top: 1.1428571429rem;
  }
}

.lps_parts--button + .lps_parts--button {
  margin-top: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--button + .lps_parts--button {
    margin-top: 0.5714285714rem;
  }
}

.lps_parts iframe {
  width: 100%;
}

.lps_parts .text:not(:last-child) {
  margin-bottom: 1.5rem;
}

.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}

.lps_parts--column .btn a {
  margin-left: auto;
  margin-right: auto;
}

.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts .mce-content-body:not(:last-child) {
    margin-bottom: 1.7142857143rem;
  }
}

@media screen and (max-width: 47.9375em) {
  .lps_parts .btn {
    text-align: center;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
}
/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .bg-wh .img_text--child:only-child,
.lps_parts--img_text .text,
.lps_parts--img_text .img_text--txt table {
  width: 100%;
}

.lps_parts--img_text .img_text--child .img {
  height: 100%;
}

.lps_parts--img_text .img_text--img:only-child .img {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .img_text--txt {
    padding-top: 1.5rem;
  }
}

.lps_parts--img_text .bg-wh .img_text--txt {
  padding: 5.3333333333%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .bg-wh .img_text--txt {
    padding: 1.7142857143rem;
  }
}

@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner:not(.flex-reverse) .img_text--txt {
    padding-left: 5.3333333333%;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner.flex-reverse .img_text--txt {
    padding-right: 5.3333333333%;
  }
}

/* --- L --- */
.lps_parts--img_text .inner-lg .img_text--img {
  position: absolute;
  top: 0;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg .img_text--img {
    height: 100%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .img_text--img {
    position: relative;
  }
}
.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--img {
  left: 0;
}
.lps_parts--img_text .inner-lg.flex-reverse .img_text--img {
  right: 0;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg .img_text--txt {
    min-height: 37.9775rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .img_text--txt {
    margin-right: auto;
  }
}
.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
  margin-left: auto;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
    padding-left: 10.989010989%;
    padding-right: 0;
  }
}
.lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
  margin-right: auto;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
    padding-left: 0;
    padding-right: 10.989010989%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2.8571428571rem;
  }
}
.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .ttl-03 {
    font-size: 1.7142857143rem;
  }
}

@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .img_text--txt {
    display: flex;
    align-items: center;
  }
  .lps_parts--img_text .inner-lg .img_text--img:only-child {
    position: relative;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .btn {
    text-align: center;
  }
  .lps_parts--img_text .bg-wh + .bg-wh {
    margin-top: 0;
  }
  .lps_parts--img_text .inner-lg .img_text--child .img {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}
/* lps_parts--slide
********************************************** */
.lps_parts--slide {
  /* --- textのみ --- */
  /* --- 1枚だけ --- */
}
.lps_parts--slide .slick-list {
  margin-bottom: 2rem;
}
.lps_parts--slide .slick-arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 100%;
  text-decoration: none;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow {
    width: 2.2857142857rem;
  }
}
.lps_parts--slide .slick-arrow::after {
  content: "";
  display: block;
  width: 1.875rem;
  height: 1.875rem;
  transform: rotate(45deg);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow::after {
    width: 1.1428571429rem;
    height: 1.1428571429rem;
  }
}
.lps_parts--slide .slick-prev {
  left: -3.75rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-prev {
    left: 0;
  }
}
.lps_parts--slide .slick-prev::after {
  margin-right: -20%;
  border-bottom: 2px solid;
  border-left: 2px solid;
}
.lps_parts--slide .slick-next {
  right: -3.75rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-next {
    right: 0;
  }
}
.lps_parts--slide .slick-next::after {
  margin-left: -20%;
  border-top: 2px solid;
  border-right: 2px solid;
}
.lps_parts--slide .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-dots {
    position: relative;
    bottom: 0.25rem;
  }
}
.lps_parts--slide .slick-dots li {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transit-default);
  margin: 0.25rem;
}
.lps_parts--slide .slick-dots li.slick-active {
  background-color: var(--clr-body);
}
.lps_parts--slide .slick-dots li:hover {
  opacity: 0.7;
}
.lps_parts--slide .img + .text {
  padding-top: 2rem;
}
@media print, screen and (min-width: 48em) {
  .lps_parts--slide .ttl-03 {
    font-size: 2rem;
  }
}
.lps_parts--slide .text:only-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 5rem;
  background-color: var(--bg-off_wht);
  height: 100%;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .text:only-child {
    padding: 1.7142857143rem 2.8571428571rem;
  }
}
.lps_parts--slide:has(.slick-slide:only-child) .slick-dots {
  display: none;
}

.ipad .lps_parts--slide .slick-prev {
  left: 0 !important;
}
.ipad .lps_parts--slide .slick-next {
  right: 0 !important;
}

/* slide-center
********************************************** */
.lps_parts--slide.slide-center {
  /* --- textのみ --- */
}
.lps_parts--slide.slide-center .slick-slide {
  width: 75rem;
  margin: 0 1.875rem;
}
@media screen and (max-width: 70em) {
  .lps_parts--slide.slide-center .slick-slide {
    width: 62.5rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-center .slick-slide {
    width: 22.8571428571rem;
    margin: 0 1.1428571429rem;
  }
}
.lps_parts--slide.slide-center .slick-prev {
  left: calc(50% - 41.25rem);
}
@media screen and (max-width: 70em) {
  .lps_parts--slide.slide-center .slick-prev {
    left: calc(50% - 35rem);
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-center .slick-prev {
    left: calc(50% - 13.7142857143rem);
  }
}
.lps_parts--slide.slide-center .slick-next {
  right: calc(50% - 41.25rem);
}
@media screen and (max-width: 70em) {
  .lps_parts--slide.slide-center .slick-next {
    right: calc(50% - 35rem);
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-center .slick-next {
    right: calc(50% - 13.7142857143rem);
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-center .text:only-child {
    padding: 1.7142857143rem;
  }
}

/* slide-fit
********************************************** */
.lps_parts--slide.slide-fit {
  /* --- textのみ --- */
}
.lps_parts--slide.slide-fit .slick-track {
  background-color: var(--bg-off_wht);
}
.lps_parts--slide.slide-fit .slick-slide {
  position: relative;
}
.lps_parts--slide.slide-fit .slick-prev {
  left: 3rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit .slick-prev {
    left: 1.1428571429rem;
  }
}
.lps_parts--slide.slide-fit .slick-next {
  right: 3rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit .slick-next {
    right: 1.1428571429rem;
  }
}
@media print, screen and (min-width: 48em) {
  .lps_parts--slide.slide-fit .img {
    aspect-ratio: 1920/800;
  }
}
.lps_parts--slide.slide-fit .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: calc(100% - 15rem);
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit .text {
    width: calc(100% - 8.5714285714rem);
  }
}
.lps_parts--slide.slide-fit .img + .text,
.lps_parts--slide.slide-fit .slick-arrow {
  color: var(--clr-wht);
}
.lps_parts--slide.slide-fit .img + .text {
  position: absolute;
  inset: 0;
  padding-top: 5rem;
  padding-bottom: 5rem;
  z-index: 1;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit .img + .text {
    padding-top: 2.8571428571rem;
    padding-bottom: 2.8571428571rem;
  }
}
.lps_parts--slide.slide-fit:has(.img + .text) .slick-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5rem;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide.slide-fit:has(.img + .text) .slick-dots {
    bottom: 2.8571428571rem;
  }
}
.lps_parts--slide.slide-fit:has(.text:only-child) .slick-arrow {
  color: var(--clr-body);
}
.lps_parts--slide.slide-fit .text:only-child {
  padding-left: 0;
  padding-right: 0;
}

/* 背景色調整
********************************************** */
.lps_sec[style*=background-color] .lps_parts--slide .text:only-child,
.lps_sec[style*=background-color] .lps_parts--slide.slide-fit .slick-track {
  color: var(--clr-body);
  background-color: var(--bg-wht);
}
.lps_sec[style*=background-color] .lps_parts--slide .slick-arrow::before,
.lps_sec[style*=background-color] .lps_parts--slide .slick-arrow::after {
  border-color: #fff;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}