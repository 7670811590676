@use '../abstracts' as *;

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: rem(8);
}
.btn a,
.btn > span {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  column-gap: rem(24);
  width: fit-content;
  text-align: left;
  cursor: pointer;
  @include mq(sp) {
    min-width: 100%;
  }
  svg {
    right: rem(24);
    fill: var(--clr-body);
  }
}
a .txt--wrap {
  position: relative;
  display: inline-block;
  flex: 1;
  font-size: 16px;
  @include lh(16,32);
  @include mq(sp) {
    flex: inherit;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 1px;
    width: 100%;
    background: transparent;
    transition: var(--transit-default);
  }
}
a:hover .txt--wrap::before {
  background: var(--clr-body);
  animation:  btn-line .5s ease-out 0s 1 normal forwards;
}
a .btn_circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(48);
  height: rem(48);
  background-color: var(--clr-main);
  border-radius: 50%;
  transition: var(--transit-default, all .4s ease-out);
  z-index: 1;
}
a:hover .btn_circle {
  background-color: var(--bg-main);
}
a .btn_arrow {
  position: relative;
  width: rem(9);
  height: rem(13);
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(../images/top/button_arrow.svg);
    background-size: cover;
    background-repeat: no-repeat;
    transition: var(--transit-default, all .4s ease-out);
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-120%);
    width: 100%;
    height: 100%;
    background-image: url(../images/top/button_arrow.svg);
    background-size: cover;
    background-repeat: no-repeat;
    transition: var(--transit-default, all .4s ease-out);
  }
}
a:hover .btn_arrow {
  &::before {
    transform: translateX(100%);
  }
  &::after {
    transform: translateX(0);
  }
}

/* --- btn-wh--- */
.btn-wh.btn a {
  color: #fff;
  svg {
    fill: #fff;
  }
}
.btn-wh.btn a .txt--wrap {
  color: #fff;
}
.btn-wh.btn a:hover .txt--wrap::before {
  background: #fff;
}
.btn-wh.btn a:hover .btn_circle {
  background-color: var(--clr-main);
}
.btn-wh.btn a .btn_arrow {
  &::before {
    background-image: url(../images/top/button_arrow.svg);
  }
  &::after {
    background-image: url(../images/top/button_arrow.svg);
  }
}

/* --- lps_sec[style*="background-color"] --- */
.lps_sec[style*="background-color"] .btn a {
  color: #fff;
  svg {
    fill: #fff;
  }
}
.lps_sec[style*="background-color"] a .txt--wrap {
  color: #fff;
}
.lps_sec[style*="background-color"] a:hover .txt--wrap::before {
  background: #fff;
}
.lps_sec[style*="background-color"] a:hover .btn_circle {
  background-color: var(--clr-main);
}
.lps_sec[style*="background-color"] a .btn_arrow {
  &::before {
    background-image: url(../images/top/button_arrow.svg);
  }
  &::after {
    background-image: url(../images/top/button_arrow.svg);
  }
}

/* --- lps_sec[style*="background-color"] bg-wh --- */
.lps_sec[style*="background-color"] .bg-wh .btn a {
  color: var(--clr-body);
  svg {
    fill: var(--clr-body);
  }
}
.lps_sec[style*="background-color"] .bg-wh a .txt--wrap {
  color: var(--clr-body);
}
.lps_sec[style*="background-color"] .bg-wh a:hover .txt--wrap::before {
  background: var(--clr-body);
}
.lps_sec[style*="background-color"] .bg-wh a:hover .btn_circle {
  background-color: var(--bg-main);
}
.lps_sec[style*="background-color"] .bg-wh a .btn_arrow {
  &::before {
    background-image: url(../images/top/button_arrow.svg);
  }
  &::after {
    background-image: url(../images/top/button_arrow.svg);
  }
}

/* --- icon --- */
.btn a[target="_blank"] {
  padding: 14px 0;
}
.btn a[href$=".pdf"] {
  padding: 14px 0 14px 38px;
  display: inline-block;
}
.btn .icon-pdf {
  left: .25rem;
}
.flex > .btn a { min-width: 100%; }
.btn.btn-ctr a {
  justify-content: center;
  width: fit-content;
  @include auto-margin;
}
.btn.btn-ctr-sp a {
  @include mq(sp) {
    justify-content: center;
    width: fit-content;
    @include auto-margin;
  }
}

/* --- btn-wh --- */
// .btn-wh a {
//   color: var(--clr-main);
//   &:hover {
//     color: var(--clr-wht);
//     background-color: var(--bg-main);
//   }
//   svg {
//     fill: var(--clr-main);
//   }
//   &:hover svg {
//     fill: var(--clr-wht);
//   }
// }

/* --- btn-cta --- */
.btn-cta a {
  background-color: var(--bg-cta);
  &:hover {
    background-color: var(--bg-cta-hvr);
  }
}

@include mq(sp) {
  .flex > .btn:not(:last-child) {
    margin-bottom: sprem(8) !important;
  }
}