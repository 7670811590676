@use '../abstracts/' as *;
@use 'sass:math';

/* common
********************************************** */
.home--ttl {
  position: relative;
  &::after {
    content: '';
    display: block;
    width: rem(120);
    height: 2px;
    margin-top: rem(36);
    background: linear-gradient(90deg, var(--clr-main) 0%, var(--clr-main) 50%, $gry-c9 50%, $gry-c9 100%);
    @include mq(sp) {
      width: sprem(60);
      margin-top: sprem(18);
    }
  }
  .en {
    display: block;
    margin-bottom: rem(28);
    font-size: rem(90);
    letter-spacing: .14em;
    line-height: .8;
    @include mq(sp) {
      margin-bottom: sprem(14);
      font-size: sprem(48);
    }
  }
  .jp {
    display: block;
    letter-spacing: .2em;
    &.jp-40 {
      font-size: rem(40);
      @include lh(40,64);
      @include mq(sp) {
        font-size: sprem(24);
      }
    }
    &.jp-48 {
      font-size: rem(48);
      @include lh(48,78);
      @include mq(sp) {
        font-size: sprem(28);
      }
    }
  }
}
.home--sub_ttl {
  letter-spacing: .14em;
  &.jp-28 {
    font-size: rem(28);
    @include lh(28,46);
    @include mq(sp) {
      font-size: sprem(20);
    }
  }
  &.jp-32 {
    font-size: rem(32);
    @include lh(32,50);
    @include mq(sp) {
      font-size: sprem(22);
    }
  }
  .en {
    line-height: 1;
  }
  .jp {
    &.jp-28 {
      font-size: rem(28);
      @include lh(28,46);
      @include mq(sp) {
        font-size: sprem(20);
      }
    }
  }
}

/* hero
********************************************** */
.hero {
  height: rem(950);
  overflow: hidden;
  @include mq(med) {
    overflow: hidden;
  }
  @include mq(sp) {
    height: sprem(600);
  }
}
.hero .deco {
  position: absolute;
  &.deco-sm {
    top: rem(294);
    left: rem(6);
    @include rect(120);
  }
  &.deco-lg {
    top: rem(126);
    left: rem(530);
    @include rect(270);
  }
}
.hero--img {
  position: relative;
  width: rem(1300);
  max-width: 100%;
  height: 100%;
  margin-left: auto;
  @include mq(sp) {
    height: 100%;
    background-color: #eee;
  }
}
.ipad .hero--img {
  width: 100%;
}
.hero--img .hero--slider {
  position: relative;
  width: 100%;
  height: 100%;
}
.hero--slider .slick-list,
.hero--slider .slick-track {
  height: 100% !important;
  overflow: inherit !important;
}
.hero--slider .slider,
.hero--slider .img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: center;
}
.hero--slider .img {
  overflow: hidden;
  .img-on {
    position: relative;
    z-index: 1;
  }
}
.hero--slider .slider.add-ani img {
  animation: scale 6s 0s forwards;
}
.hero--slider .hero--deco {
  position: absolute;
  left: calc(-100vw + rem(1300));
  transform: translate3d(0,0,0);
  @include mq(med) {
    left: 0;
  }
  @include mq(sp) {
    clip-path: inset(0 100% 0 0);
    &.active {
      animation: brush .5s cubic-bezier(0.08, 0.41, 0.19, 0.95) forwards;
    }
  }
  svg {
    width: 100%;
    height: 100%;
  }
  .hero_wreath {
    @include mq(sp) {
      display: none;
    }
  }
  &.hero--deco01 {
    top: rem(-214);
    width: vw(1664);
    height: vw(651);
    @include mq(sp) {
      top: 0;
      width: spvw(414);
      height: spvw(162);
    }
  }
  &.hero--deco02 {
    top: rem(-140);
    width: vw(2302);
    height: vw(1011);
    @include mq(sp) {
      top: spvw(-24);
      left:  spvw(-64);
      width: spvw(464);
      height: spvw(208);
    }
  }
}
.ipad .hero--slider .hero--deco {
  left: 0;
}
@keyframes scale {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.hero--catch_weapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: rem(1300);
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  @include mq(sp) {
    width: 100%;
  }
  .hero--catch {
    @include mq(sp) {
      left: 0;
      bottom: sprem(40);
      height: sprem(46);
      z-index: 1;
      display: block;
    }
  }
}
.ipad .hero--catch_weapper  {
  width: 100%;
}
.hero--catch_weapper .hero--catch {
  left: calc(-100vw + rem(1300));
  @include mq(med) {
    left: 0;
  }
}
.ipad .hero--catch_weapper .hero--catch {
  left: 0;
}
.hero--ttl {
  position: absolute;
  top: 50%;
  left: vw(120);
  transform: translateY(-50%);
  white-space: nowrap;
  @include lh(64,88);
  letter-spacing: .2em;
  text-shadow: 0 0 rem(20) #fff;
  @include mq(sp) {
    top: auto;
    bottom: sprem(136);
    transform: inherit;
    font-size: sprem(32);
    color: #fff;
    text-shadow: 0 0 rem(20) var(--clr-main);
  }
  .sub {
    margin-bottom: rem(36);
    @include mq(sp) {
      margin-bottom: sprem(16);
      font-size: sprem(16);
    }
  }
}
.hero--catch {
  position: absolute;
  left: 0;
  bottom: rem(120);;
  width: 100vw;
  height: rem(116);
  overflow: hidden;
  @include mq(med) {
    width: 100%;
  }
  @include mq(sp) {
    display: none;
  }
  .catch_wrap {
    position: absolute;
    top: 0;
    left: 0;
    column-gap: rem(100);
    white-space: nowrap;
    animation: slider 100s linear infinite;
    @include mq(sp) {
      column-gap: sprem(40);
    }
    .catch {
      font-size: rem(144);
      line-height: .7;
      letter-spacing: normal;
      mix-blend-mode: difference;
      @include mq(sp) {
        font-size: sprem(56);
      }
    }
  }
}
.home #wrapper {
  @include mq(med) {
    overflow: hidden;
  }
  @include mq(sp) {
    margin-top: sprem(-24);
  }
}
/* home_news
********************************************** */
.home_news {
  position: relative;
  z-index: 1;
}
.home_news .news_box {
  position: absolute;
  top: rem(40);
  right: 0;
  width: rem(920);
  height: rem(56);
  margin-left: auto;
  background-color: #eee;
  border-radius: rem(8);
  box-shadow: 0px 0 rem(10) 0px rgba(39, 47, 61, .2);
  @include mq(sp) {
    position: static;
    width: 93%;
    height: sprem(48);
    border-radius: sprem(4);
  }
  .box_inner {
    column-gap: rem(20);
    height: 100%;
    padding-left: perc(78px, 920px, "%");
    padding-right: perc(128px, 920px, "%");
    @include mq(sp) {
      column-gap: sprem(12);
      padding-left: sprem(14);
      padding-right: sprem(80);
    }
  }
}
.news_box .box_inner svg {
  width: rem(20);
  height: rem(20);
}
.news_box .post {
  width: 100%;
  height: 100%;
  @include mq(sp) {
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
}
.news_box .post--date {
  font-size: rem(14);
  @include mq(sp) {
    font-size: sprem(10);
  }
}
.news_box .post--ttl {
  flex: 1;
  @include mq(sp) {
    flex: inherit;
    font-size: sprem(12);
  }
}
.news_box .post:hover .post--date,
.news_box .post:hover .post--ttl a {
  color: var(--clr-main);
}
.news_btn {
  position: absolute;
  top: 50%;
  right: rem(32);
  transform: translateY(-50%);
  font-size: rem(16);
  color: $gry-95;
  @include mq(sp) {
    right: sprem(14);
    font-size: sprem(10);
  }
  &:hover {
    opacity: .7;
  }
}

/* home_about
********************************************** */
.home_about--imgarea {
  position: relative;
  max-width: rem(1292);
  margin-left: auto;
  @include mq(sp) {
    z-index: -1;
  }
  .logo {
    position: absolute;
    top: rem(64);
    right: rem(320);
    z-index: -1;
    width: rem(844);
    @include mq(sp) {
      top: 0;
      left: 7vw;
      right: auto;
      width: sprem(300);
    }
  }
  .dot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mask-image:  url(../images/top/map-dot-mask.svg);
    mask-position: 72% 60%;
    mask-size: 0 0;
    mask-repeat: no-repeat;
    mask-clip: border-box;
    transition: mask-size 8s ease-out 1s;
  }
  &.active .dot {
    mask-size: rem(1920) rem(1920);
  }
}
.home_about--txtarea {
  position: absolute;
  top: 42%;
  left: vw(200);
  transform: translateY(-50%);
  @include mq(1400) {
    left: 5vw;
  }
  @include mq(sp) {
    position: static;
    transform: inherit;
    width: 86%;
    margin: sprem(24) auto 0;
  }
  .home--ttl {
    margin-bottom: rem(72);
    @include mq(sp) {
      margin-bottom: sprem(36);
    }
    &::after {
      margin-top: rem(48);
      @include mq(sp) {
        margin-top: sprem(24);
      }
    }
  }
  .home--sub_ttl {
    margin-bottom: rem(32);
    @include mq(sp) {
      margin-bottom: sprem(16);
    }
  }
  .txt {
    margin-bottom: rem(52);
    @include mq(sp) {
      margin-bottom: sprem(26);
    }
  }
}

/* home_service
********************************************** */
.home_service {
  padding-top: rem(40);
  @include mq(sp) {
    padding-top: sprem(60);
  }
}
.service_top {
  @include stylish_img02;
  & > .inner {
    max-width: rem(1480);
  }
}
.service_top--imgarea {
  position: absolute;
  top: 0;
  left: 0;
  width: vw(840);
  height: 100%;
  @include mq(sp) {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    height: auto;
    margin-bottom: sprem(24);
  }
}
.service_top--imgarea .img_slider {
  width: 100%;
  height: 100%;
  .slick-list,
  .slick-track {
    height: 100% !important;
  }
}
.service_top--imgarea .slick-dots {
  position: absolute;
  right: rem(-20);
  bottom: rem(20);
  display: flex;
  flex-direction: column;
  row-gap: rem(10);
  width: 3px;
}
.service_top--imgarea .slick-dots li {
  position: relative;
  width: 100%;
  height: rem(30);
  background-color: $gry-aa;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: var(--clr-main);
  }
  &.slick-active {
    &::before {
      animation: dot_ani 5s linear forwards;
    }
  }
  button {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    color: transparent;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}
.service_top--txtarea {
  width: perc(710px, 1480px, "%");
  min-height: vw(800);
  padding-bottom: rem(128);
  margin-left: auto;
  @include mq(sp) {
    width: 100%;
    min-height: inherit;
    margin-left: 0;
    padding-bottom: sprem(80);
  }
  .home--ttl {
    margin-bottom: rem(54);
    @include mq(sp) {
      margin-bottom: sprem(32);
    }
  }
  .home--sub_ttl {
    margin-bottom: rem(36);
    @include mq(sp) {
      margin-bottom: sprem(20);
    }
  }
  .txt {
    margin-bottom: rem(42);
    @include mq(sp) {
      margin-bottom: sprem(24);
    }
  }
}
.service_btm {
  margin-top: rem(-36);
  background-color: $bg-main;
  @include mq(sp) {
    margin-top: 0;
  }
  & > .inner {
    width: 96%;
    max-width: rem(1670);
    margin-right: 0;
    @include mq(sp) {
      width: 100%;
    }
    .deco{
      position: absolute;
      bottom: rem(-72);
      right: rem(146);
      width: rem(344);
      opacity: .5;
      @include mq(sp) {
        bottom: sprem(-24);
        right: sprem(16);
        width: sprem(172);
      }
    }
  }
}
.service_btm--imgarea {
  width: rem(560);
  margin-top: vw(-78);
  margin-left: vw(160);
  @include stylish_img01;
  @include mq(1400) {
    width: rem(480);
    margin-left: rem(48);
  }
  @include mq(sp) {
    width: 93%;
    margin-top: sprem(-32);
    margin-left: auto;
  }
}
.service_btm--txtarea {
  flex: 1;
  padding-block: rem(138) rem(104);
  @include mq(sp) {
    width: 86%;
    padding-block: sprem(48) sprem(56);
    margin: 0 auto;
  }
  .ttl {
    margin-bottom: rem(68);
    font-size: rem(30);
    letter-spacing: .14em;
    @include mq(sp) {
      margin-bottom: rem(48);
      font-size: rem(24);
    }
  }
  .txt {
    padding-left: 82px;
    @include lh(16,32);
    @include mq(sp) {
      padding-left: 0;
    }
  }
}
.service--list {
  padding-bottom: vw(80);
  margin-bottom: rem(36);
  border-bottom: 1px solid #d2d2d24D;
  @include mq(sp) {
    row-gap: sprem(48);
    padding-bottom: sprem(40);
  }
}
.service--item {
  position: relative;
  .num {
    position: absolute;
    top: 0;
    left: 0;
    font-size: rem(100);
    line-height: .7;
    letter-spacing: -.1em;
    color: rgba(185, 235, 243, .15);
    white-space: nowrap;
    @include mq(sp) {
      font-size: sprem(80);
    }
  }
  .item--txt {
    position: absolute;
    top: rem(12);
    left: rem(136);
    font-size: rem(14);
    line-height: .7;
    letter-spacing: .14em;
    color: rgba(185, 235, 243, .2);
    white-space: nowrap;
    text-transform: uppercase;
    @include mq(sp) {
      left: sprem(136);
      font-size: sprem(12);
    }
  }
  .item--ttl {
    padding-top: rem(54);
    padding-left: rem(48);
    font-size: rem(24);
    line-height: 1;
    letter-spacing: .14em;
    @include mq(sp) {
      padding-top: sprem(40);
      padding-left: sprem(126);
      font-size: sprem(18);
    }
  }
}

/* home_new_works
********************************************** */
.home_new_works {
  position: relative;
  padding-block: rem(148);
  overflow: hidden;
  @include mq(sp) {
    padding-block: sprem(60);
  }
}
.home_new_works--ttlarea {
  position: relative;
  max-width: rem(1500);
  margin-bottom: rem(64);
  @include mq(sp) {
    margin-bottom: sprem(32);
  }
  .deco {
    position: absolute;
    top: rem(48);
    left: rem(28);
    width: rem(204);
    opacity: .5;
    @include mq(sp) {
      width: sprem(102);
    }
  }
}
.home_new_works--ttlarea .home--ttl {
  margin-bottom: rem(38);
  @include mq(sp) {
    margin-bottom: sprem(20);
  }
  .en {
    @include mq(sp) {
      font-size: sprem(36);
      white-space: nowrap;
    }
  }
}
.home_new_works--content {
  position: relative;
  width: 100vw;
  height: vw(640);
  @include stylish_base01;
  @include mq(sp) {
    height: spvw(320);
  }
}
.home_new_works--slider {
  position: absolute;
  top: 0;
  left: 0;
  column-gap: vw(16);
  white-space: nowrap;
  animation: slider 40s linear infinite;
  @include mq(sp) {
    column-gap: spvw(8);
    animation: slider 30s linear infinite;
  }
  &:hover {
    animation-play-state: paused;
  }
}
.home_new_works--list {
  display: grid;
  grid-template-rows: vw(312) vw(312);
  grid-row-gap: vw(16);
  grid-column-gap: vw(16);
  width: fit-content;
  margin-left: auto;
  @include mq(sp) {
    grid-template-rows: spvw(156) spvw(156);
    grid-row-gap: spvw(8);
    grid-column-gap: spvw(8);
  }
}
.home_new_works--item {
  overflow: hidden;
  @for $i from 1 through 18 {
    &:nth-of-type(#{$i}) {
      @if $i == 1 {
        grid-column: 1/3;
        grid-row: 1/3;
      } @else if $i % 2 == 0 {
        // 偶数
        grid-column: calc(($i / 2) + 2) / calc(($i / 2) + 3);
        grid-row: 1/2;
      } @else {
        // 奇数
        grid-column: calc(math.floor(math.div($i, 2)) + 2) / calc(math.floor(math.div($i, 2)) + 3);
        grid-row: 2/3;
      }
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: var(--clr-body);
    opacity: 0;
    transition: var(--transit-default);
    @include mq(sp) {
      opacity: .3;
    }
  }
}
.home_new_works--item .item--img {
  transform: scale(1.1);
  transform-origin: center;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: var(--transit-default);
  @include mq(sp) {
    transform: scale(1);
  }
}
.home_new_works--item .item--txtarea {
  position: absolute;
  bottom: rem(24);
  left: rem(32);
  z-index: 1;
  width: calc(100% - rem(64));
  opacity: 0;
  transition: var(--transit-default);
  @include mq(sp) {
    opacity: 1;
  }
  p {
    line-height: 1.4;
    white-space: normal;
  }
  @include mq(sp) {
    bottom: sprem(8);
    left: sprem(12);
    width: calc(100% - sprem(24));
    p {
      font-size: sprem(12);
    }
  }
}
.home_new_works--item:hover {
  &::before {
    opacity: .3;
  }
  .item--img {
    transform: scale(1);
  }
  .item--txtarea {
    opacity: 1;
  }
}

/* home_works
********************************************** */
.home_works {
  padding-bottom: rem(138);
  @include mq(sp) {
    padding-bottom: sprem(32);
  }
}
.home_works--wrapper {
  background-image: url(../images/top/work_background.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.home_works--ttlarea {
  position: relative;
  z-index: 1;
  max-width: rem(1640);
  padding-block: rem(98);
  @include mq(sp) {
    padding-block: sprem(60);
  }
}
.home_works--ttlarea .home--ttl {
  position: relative;
  margin-bottom: rem(40);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
  .en {
    color: #fff;
  }
  .jp {
    color: #fff;
  }
}
.home_works--ttlarea .txt {
  margin-bottom: rem(40);
  color: #fff;
}

/* home_company
********************************************** */
.home_company {
  position: relative;
  padding-bottom: rem(106);
  @include stylish_img01;
  @include mq(sp) {
    padding-top: sprem(32);
    padding-bottom: sprem(60);
  }
}
.home_company--deco {
  position: absolute;
  top: vw(-314);
  left: 0;
  width: vw(1810);
  height: vw(555);
  @include mq(sp) {
    clip-path: inset(0 100% 0 0);
    &.active {
      animation: brush .5s cubic-bezier(0.08, 0.41, 0.19, 0.95) forwards;
    }
  }
  svg {
    width: 100%;
    height: 100%;
  }
}
.company_inner {
  column-gap: vw(110);
  max-width: rem(1680);
}
.home_company--imgarea {
  position: relative;
  width: 44.88%;
  @include mq(sp) {
    width: 80%;
    margin-bottom: sprem(24);
  }
  .deco {
    position: absolute;
    bottom: rem(-24);
    right: rem(-60);
    z-index: -1;
    @include rect(324);
    @include mq(sp) {
      @include sprect(120);
    }
  }
  .img-logo {
    width: 100%;
  }
}
.home_company--txtarea {
  flex: 1;
  max-width: rem(710);
  .home--ttl  {
    margin-bottom: rem(40);
    @include mq(sp) {
      margin-bottom: rem(24);
    }
  }
  .home--sub_ttl {
    margin-bottom: 1em;
    font-size: max(vw(32),rem(24));
    @include lh(32,50);
    @include mq(sp) {
      font-size: sprem(20);
    }
  }
  .txt {
    margin-bottom: rem(40);
  }
}

/* home_blc
********************************************** */
.home_blc {
  // min-height: rem(560);
}
.home_blc_sec {
  position: relative;
  min-height: rem(560);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform-origin: center;
  transition: var(--transit-default);
  overflow: hidden;
  @include mq(sp) {
    min-height: inherit;
  }
  &:hover {
    transform: scale(.95);
  }
  .deco {
    position: absolute;
    opacity: .5;
  }
}
.home_blc_sec > a {
  width: 100%;
  height: 100%;
  background: url(../images/top/recruit_bk_ptn.png);
  @include mq(sp) {
    min-height: sprem(300);
    background-image: url(../images/top/blc_sec-bg.png);
  }
  .ttl {
    position: relative;
    margin-bottom: rem(80);
    @include mq(sp) {
      margin-bottom: sprem(54);
    }
    .en {
      display: block;
      margin-bottom: rem(24);
      letter-spacing: .14em;
      font-size: max(vw(64),40px);
      line-height: .8;
      color: var(--clr-main);
      @include mq(sp) {
        font-size: min(spvw(28),40px);
        color: rgba(255, 255, 255, .08);
      }
    }
    .jp {
      font-size: rem(32);
      line-height: 1;
      transition: var(--transit-default);
      @include mq(sp) {
        font-size: sprem(24);
        color: #fff;
      }
    }
  }
  .btn span {
    @include mq(sp) {
      color: #fff;
    }
  }
}
.home_blc_sec {
  &.home_recruit {
    background-image: url(../images/top/recruit_button.jpg);
    .deco-sm {
      top: rem(-88);
      left: rem(-98);
      @include rect(200);
    }
    .deco-lg {
      bottom: rem(-124);
      right: rem(-134);
      @include rect(300);
    }
    .ttl .catch {
      font-size: vw(100);
    }
  }
  &.home_sustainability {
    background-image: url(../images/top/sustainability_button.jpg);
    .deco-sm {
      bottom: rem(46);
      left: rem(58);
      @include rect(130);
    }
    .deco-lg {
      top: rem(-148);
      right: rem(-120);
      @include rect(324);
    }
    .ttl .catch {
      font-size: vw(80);
    }
  }
}
.home_blc_sec:hover > a {
  background-image: url(../images/top/blc_sec-bg.png);
  .ttl .en {
    color: rgba(255, 255, 255, .08);
  }
  .ttl .jp {
    color: #fff;
  }
  .btn span {
    color: #fff;
  }
}

/* cta_blc
********************************************** */
.cta_blc {
  position: relative;
  padding-top: rem(80);
  @include mq(med) {
    overflow: hidden;
  }
}
.cta_bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100% - rem(368));
  background-image: url(../images/common/cta_img01.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include mq(sp) {
    height: calc(100% - sprem(182));
    background-image: url(../images/common/cta_img01_sp.jpg);
  }
}
.cta_box {
  position: relative;
  z-index: 1;
  max-width: rem(1778);
  padding: rem(72);
  background-color: $bg-main;
  box-shadow: 0 0 rem(70) 0 rgba(33, 71, 140, .3);
  @include mq(sp) {
    padding: sprem(48) rem(24) sprem(24);
  }
}
.cta_box--ttlarea {
  margin-bottom: rem(48);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
  .ttl {
    position: relative;
    margin-bottom: rem(32);
    .en {
      display: block;
      margin-bottom: rem(28);
      font-size: rem(90);
      letter-spacing: .14em;
      line-height: .8;
      color: #fff;
      @include mq(sp) {
        margin-bottom: sprem(18);
        font-size: sprem(36);
      }
    }
    .jp {
      font-size: rem(26);
      letter-spacing: .16em;
      line-height: 1;
      @include mq(sp) {
        font-size: sprem(20);
      }
    }
  }
}
.cta_box--content {
  max-width: rem(1064);
  margin: 0 auto;
  @include mq(sp) {
    row-gap: sprem(16);
  }
}
.cta_box--content .info_box {
  width: calc((100% - rem(24)) / 2);
  min-height: rem(190);
  padding: rem(32);
  @include mq(sp) {
    width: 100%;
    min-height: inherit;
    padding: sprem(24) sprem(16);
  }
}
.cta_box--content .box--ttl {
  position: relative;
  margin-bottom: rem(24);
  font-size: rem(20);
  line-height: 1;
  letter-spacing: .16em;
  @include mq(sp) {
    margin-bottom: sprem(16);
  }
  &::after {
    content: '';
    display: block;
    width: rem(32);
    height: 1px;
    margin-top: rem(20);
    @include auto-margin;
    border-bottom: 1px solid var(--clr-body);
  }
}
.cta_box--content .tel {
  margin-bottom: rem(12);
  line-height: 1;
  letter-spacing: -.02em;
  white-space: nowrap;
  @include mq(sp) {
    font-size: sprem(16);
  }
  .num {
    letter-spacing: -.02em;
    @include mq(sp) {
      font-size: sprem(24);
    }
  }
}
.cta_box--content .open {
  color: $gry-a0;
}
.cta_box--content .cta_btn {
  width: 100%;
  a {
    column-gap: rem(20);
    max-width: rem(320);
    width: 100%;
    padding-block: rem(20);
    margin: 0 auto;
    line-height: 1;
    color: #fff;
    background-color: #000;
    border: 1px solid #000;
    svg {
      fill: #fff;
    }
    &:hover {
      color: #000;
      background-color: #fff;
      svg {
        fill: #000;
      }
    }
  }
}
.cta_blc .cta_btm {
  position: relative;
  z-index: 1;
  padding-block: rem(170) rem(140);
  @include mq(sp) {
    padding-block: sprem(80);
  }
  .cta_catch {
    font-size: rem(40);
    @include lh(40,64);
    letter-spacing: .2em;
    @include stylish_base01;
    transition-delay: .4s;
    @include mq(sp) {
      font-size: sprem(28);
    }
  }
}
.cta_btm--deco {
  position: absolute;
  top: calc(50% + vw(48));
  left: calc(50% + vw(350));
  transform: translate(-50%,-50%);
  width: vw(884);
  height: vw(151);
  @include mq(med) {
    left: calc(50% + vw(180));
  }
  @include mq(sp) {
    top: calc(50% + spvw(48));
    width: spvw(442);
    height: spvw(78);
    clip-path: inset(0 100% 0 0);
    &.active {
      animation: brush .8s cubic-bezier(0.08, 0.41, 0.19, 0.95) forwards;
    }
  }
  svg {
    width: 100%;
    height: 100%;
  }
}