@use "sass:map";

// SASS用の基本設定変数
$fz-pc: 16;
$fz-sp: 14;
$wid-pc: 1920;
$wid-sp: 414;
$header-hgt: 115;
$header-hgt-sp: 64;
$header-hgt-fx: 72;

// 色
$clr-main: #0553dd;
$bg-main: #0a4cbf;

$blk-29: #293440;
$gry-c9: #c9c9c9;
$gry-95: #959595;
$gry-aa: #aaaaaa;
$gry-e5: #e5e5e5;
$gry-a0: #a0a0a0;
$blu-75: #758eaa;
$blu-a8: #a8c7df;
$blu-f2: #f2f6fa;

@font-face {
  font-family: "presicav R";
  src: url(../fonts/presicav_regular.woff2) format("woff2");
}
@font-face {
  font-family: "presicav B";
  src: url(../fonts/presicav_bold.woff2) format("woff2");
}

// CSS変数
:root {
  // typography
  --ltr-space-default: .1em;
  --line-height-default: 2;
  --line-height-hdr: 1.4;
  --line-height-none: 1;

  // font-families
  --font-primary: 'A1 Gothic M', "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
  --font-secondary: 'Outfit', "ヒラギノ角ゴ オールド W6 JIS2004", "Hiragino Sans Old W6 JIS2004";
  --font-jp: 'A1 Gothic M', "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
  --font-en: "presicav R", sans-serif;
  --font-en-700: "presicav B", sans-serif;
  // --font-co:  "Cormorant", serif;

  // font-sizes
  --fz-primary: 1.125rem;
  --fz-half: .5rem;

  // text colors
  --clr-main: #0553dd;
  --clr-sub: #171C60;
  --clr-body: #141414;
  --clr-link: #000;
  --clr-wht: #fff;
  --clr-blk: #000;
  --clr-cta: #E17A40;

  // colors
  --color-primary-default: #0553dd;
  --color-primary-dark: #0F6593;
  --color-primary-light: #2E9AD4;

  // background colors
  --bg-main: #272f3d;
  --bg-main-hvr: #293440;
  --bg-sub: #0553dd;
  --bg-wht: #fff;
  --bg-off_wht: #eff5f5;
  --bg-blk: #000;
  --bg-cta: #E17A40;
  --bg-cta-hvr: #DD6B2A;

  // animation
  --transit-default: all .4s ease-out;

  // formy settings
  --color-wht: #fff;
  --color-blk: #000;
}

// SASS Maps

$z-index: (
  "header" : 9999,
  "top": 9990,
  "aside" : 75,
  "middle" : 50,
  "main" : 1,
  "base" : 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160, // 2560px
  lg: 120, // 1920px
  pc: 85.375, // 1366px
  med: 67.5, // 1080px
  sp: 47.9375, // 767px
  sm: 40, // 640px
  xs: 34.375, // 500px
  se: 21.25, // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  'blue': (
    100: hsl(202, 73%, 82%),
    200: hsl(202, 73%, 72%),
    300: hsl(202, 73%, 62%),
    400: hsl(202, 73%, 52%),
    500: hsl(202, 73%, 42%),
    600: hsl(202, 73%, 32%),
  ),
  'red': (
    100: hsl(0, 100%, 80%),
    200: hsl(0, 100%, 70%),
    300: hsl(0, 100%, 60%),
    400: hsl(0, 100%, 50%),
    500: hsl(0, 100%, 40%),
    600: hsl(0, 100%, 30%),
  ),
  'green': (
    100: hsl(118, 100%, 80%),
    200: hsl(118, 100%, 70%),
    300: hsl(118, 100%, 60%),
    400: hsl(118, 100%, 50%),
    500: hsl(118, 100%, 40%),
    600: hsl(118, 100%, 30%),
  )
);

$aspect-ratios: (
  widescreen: '3/2',
  square: '1/1',
  portrait: '2/3'
);