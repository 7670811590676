@use '../abstracts' as *;

/* ##############################################################################

    Loops モジュール

############################################################################## */
.icon-svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: var(--transit-default);
}
.icon-new_tab {
  position: relative;
  right: auto;
  top: auto;
  transform: translateY(0);
  @include rect(14);
}
.btn .icon-new_tab {
  right: auto;
}
.icon-pdf {
  @include rect(27,12);
}
.lps_sec {
  position: relative;
  &:nth-child(odd) {
    .bg-wh {
      background-color: var(--bg-off_wht);
    }
  }
  &[style*="background-color"] {
    background-color: var(--bg-main);
    color: #fff;
    a {
      color: #fff;
    }
    .bg-wh,
    .table2 {
      color: var(--clr-body);
      a {
        color: var(--clr-body);
      }
    }
  }
  &:last-of-type {
    border-bottom: 1px solid #fff;
  }
}
.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}
.lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
  margin-top: rem(80);
  @include mq(sp) {
    margin-top: sprem(40);
  }
}
.lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
  margin-top: rem(40);
  @include mq(sp) {
    margin-top: sprem(16);
  }
}
.lps_parts--button + .lps_parts--button {
  margin-top: 0;
  @include mq(sp) {
    margin-top: sprem(8);
  }
}
.lps_parts iframe {
  width: 100%;
}
.lps_parts .text:not(:last-child) {
  margin-bottom: rem(24);
}
.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}
.lps_parts--column .btn a {
  @include auto-margin;
}
.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: rem(40);
  @include mq(sp) {
    margin-bottom: sprem(24);
  }
}
@include mq(sp) {
  .lps_parts .btn {
    text-align: center;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
  .lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
}